import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { getCountry } from "../utils/getCountry";

const usConfig = {
	fallbackLng: "en",
	lng: "en",
	preload: ["en", "es"],
	supportedLngs: ["en", "es"],
};

const caConfig = {
	fallbackLng: "en-CA",
	lng: "en-CA",
	preload: ["en-CA", "es-CA", "fr-CA"],
	supportedLngs: ["en-CA", "es-CA", "fr-CA"],
};

const regionConfig = getCountry() === "US" ? usConfig : caConfig;

const options = {
	loadPath: "locales/{{lng}}/{{ns}}.json",
	crossDomain: false,
	withCredentials: false,
	overrideMimeType: false,
};

const config = {
	...regionConfig,
	ns: ["expenses"],
	debug: false,
	backend: options,
	interpolation: {
		escapeValue: false,
	},
	react: {
		transSupportBasicHtmlNodes: true,
		transKeepBasicHtmlNodesFor: [
			"br",
			"strong",
			"i",
			"p",
			"h2",
			"h3",
			"ol",
			"ul",
			"li",
			"u",
		],
	},
};

i18n.use(initReactI18next).use(Backend).use(LanguageDetector).init(config);

export default i18n;
