import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { pathsOrder, REQUEST_CALLBACK_PATH } from "../../utils/routes";
import { useSession } from "../Context/SessionProvider";
import { useStatus } from "../Context/StatusProvider";

export default function Consent() {
	const { t } = useTranslation("consent");
	const { pathname } = useLocation();
	const { isFormEditable, consentNeeded } = useSession();
	const { isAbandoned, isLocked } = useStatus();

	if (
		(!pathsOrder.includes(pathname) && pathname !== REQUEST_CALLBACK_PATH) ||
		!isFormEditable() ||
		isAbandoned ||
		isLocked
	)
		return null;

	return (
		<div className="text-muted small mt-5">
			<Trans
				t={t}
				i18nKey={`${consentNeeded ? "full" : "short"}Language`}
				components={[
					<a
						key={0}
						href={t("termsOfUseUrl")}
						target="_blank"
						rel="noopener noreferrer"
						className="text-primary"
					/>,
					<a
						key={1}
						href={t("privacyPolicyUrl")}
						target="_blank"
						rel="noopener noreferrer"
						className="text-primary"
					/>,
				]}
			/>
		</div>
	);
}
