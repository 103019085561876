import {
	BackButton,
	SubmitButton,
} from "../../../Common/FormNavButtons/FormNavButtons";
import FormTitle from "../../../Common/FormTitle/FormTitle";
import { Row, Col } from "react-bootstrap";
import useNavigateHelpers from "../../../../utils/useNavigateHelpers/useNavigateHelpers";
import { useTranslation } from "react-i18next";
import usePostIntent from "../../../../utils/usePostIntent/usePostIntent";

export default function IncomeIntro() {
	const { navigateNextPrompt } = useNavigateHelpers();
	const { t } = useTranslation("income");
	const { postStatus } = usePostIntent();

	const submitForm = () => {
		postStatus();
		navigateNextPrompt();
	};

	return (
		<div className="text-center justify-content-center" onSubmit={submitForm}>
			<FormTitle className="my-n1">{t("intro.formTitle")}</FormTitle>
			<div className="container-max-sm mx-auto">
				<p>{t("intro.weAreAboutToReview")}</p>
				<p className="mt-4">
					<span className="fw-bold text-primary">
						{t("intro.whyWeNeedThis")}
					</span>
					<br />
					{t("intro.reasonWeNeedThis")}
				</p>
			</div>
			<Row className={"form-nav-buttons align-items-center "}>
				<Col className="text-start">
					<BackButton />
				</Col>
				<Col className="text-end">
					<SubmitButton type="button" onClick={submitForm} />
				</Col>
			</Row>
		</div>
	);
}
