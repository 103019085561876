import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import useIntentHelpers from "../../../../utils/usePostIntent/usePostIntent";
import useNavigateHelpers from "../../../../utils/useNavigateHelpers/useNavigateHelpers";
import TextField from "../../../Common/TextField/TextField";
import FormTitle from "../../../Common/FormTitle/FormTitle";
import FormNavButtons from "../../../Common/FormNavButtons/FormNavButtons";
import type { HouseholdParams } from "../../../../utils/Types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HOUSEHOLD_PROVIDED_INTENT } from "../../../../utils/intents";
import useSessionData from "../../../../utils/useSessionData/useSessionData";

export default function Household() {
	const { t } = useTranslation("overview");

	const { applicant_household_size } = useSessionData();

	const defaultValues = {
		applicant_household_size: applicant_household_size
			? `${applicant_household_size}`
			: "",
	};

	const {
		handleSubmit,
		control,
		watch,
		reset,
		formState: { isDirty },
	} = useForm<HouseholdParams>({
		defaultValues,
	});

	useEffect(() => {
		// is this necessary
		reset(defaultValues);
	}, [applicant_household_size]);

	const isComplete = !!watch("applicant_household_size");

	const { postIntent, postStatus } = useIntentHelpers();
	const { navigateNextPrompt } = useNavigateHelpers();

	const submitForm = (data: HouseholdParams) => {
		if (isDirty) {
			data.applicant_household_size = data.applicant_household_size
				? +data.applicant_household_size
				: undefined;
			postIntent({ parameters: data, text: HOUSEHOLD_PROVIDED_INTENT }).then(
				() => navigateNextPrompt()
			);
		} else {
			postStatus();
			navigateNextPrompt();
		}
	};

	return (
		<>
			<FormTitle className="text-center">{t("household.formTitle")}</FormTitle>
			<Form onSubmit={handleSubmit(submitForm)}>
				<div className="container-max-sm mx-auto">
					<Form.Group>
						<div className="field-xs mx-auto">
							<TextField
								type="number"
								name="applicant_household_size"
								label={t("household.label")}
								required
								control={control}
								rules={{
									pattern: {
										value: /^[0-9]\d*$/,
										message: t("household.rules.required"),
									},
									min: {
										value: 1,
										message: t("household.rules.min"),
									},
									max: {
										value: 25,
										message: t("household.rules.max"),
									},
									required: t("household.rules.required"),
								}}
							/>
						</div>
					</Form.Group>
				</div>
				<FormNavButtons nextLinkDisabled={!isComplete} />
			</Form>
		</>
	);
}
