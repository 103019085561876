import { useController } from "react-hook-form";
import { Form } from "react-bootstrap";
import InvalidFeedback from "../InvalidFeedback/InvalidFeedback";
import "./RadioButtons.scss";

interface RadioButtonProps {
	options: {
		value?: string;
		label?: string;
	}[];
	control: any;
	name: string;
	rules?: any;
	setValue: any;
	checked: string | undefined;
}

export default function RadioButtons({
	options,
	control,
	name,
	rules,
	setValue,
	checked,
}: RadioButtonProps) {
	const {
		field,
		fieldState: { error },
	} = useController({ name, control, rules });

	return (
		<Form.Group className="mx-auto" style={{ maxWidth: "400px" }}>
			{options.map((option) => (
				<div key={option.value}>
					<div
						className="d-inline-block custom-radio d-flex align-content-center"
						onClick={() => setValue(name, option.value)}
					>
						<Form.Check
							type="radio"
							{...field}
							name={name}
							value={option.value}
							className="p-0"
							checked={option.value === checked}
							id={option.value}
						/>
						<label htmlFor={option.value} className="form-check-label">
							{option.label}
						</label>
					</div>
				</div>
			))}
			<InvalidFeedback>{error?.message}</InvalidFeedback>
		</Form.Group>
	);
}
