const MIN_HOUR = 8;

const FRIDAY_MAX_HOUR = 21;
const SATURDAY_MAX_HOUR = 18;
const WEEKDAY_MAX_HOUR = 22;

const SUNDAY = 0;
const FRIDAY = 5;
const SATURDAY = 6;

export const isToday = (date: Date): boolean => {
	const today = new Date();
	return (
		date.getDate() === today.getDate() &&
		date.getMonth() === today.getMonth() &&
		date.getFullYear() === today.getFullYear()
	);
};

/**
 * Returns a boolean indicating whether the current day of the week is Sunday.
 *
 * @param {Date} date - The current date.
 * @returns {boolean} A boolean indicating whether the current day of the week
 * is Sunday.
 */
export const isSunday = (date: Date): boolean => date.getDay() === SUNDAY;

/**
 * Returns a boolean indicating whether the current day of the week is Friday.
 *
 * @param {Date} date - The current date.
 * @returns {boolean} A boolean indicating whether the current day of the week
 * is Friday.
 */
export const isFriday = (date: Date): boolean => date.getDay() === FRIDAY;

/**
 * Returns a boolean indicating whether the current day of the week is Saturday.
 *
 * @param {Date} date - The current date.
 * @returns {boolean} A boolean indicating whether the current day of the week
 * is Saturday.
 */
export const isSaturday = (date: Date): boolean => date.getDay() === SATURDAY;

/**
 * Returns a boolean indicating whether the input date is a valid date.
 *
 * @param {Date} date - The date to be validated.
 * @returns {boolean} A boolean indicating whether the input date is a valid
 * date.
 */
export const isValidDate = (date: Date): boolean => !isNaN(date.getTime());

/**
 * Returns a new Date object with the current day incremented by one.
 *
 * @param {Date | string} date - The current date. Can be a Date object or a
 * string that can be parsed into a Date object using the Date constructor.
 * @returns {Date} A new Date object with the modified day.
 */
export const getNextDate = (date: Date | string): Date => {
	const nextDate = new Date(date);
	nextDate.setDate(nextDate.getDate() + 1);
	return nextDate;
};

/**
 * Returns a new Date object with the current day incremented by one and the
 * time set to 8:00 AM if the current time is after the specified hours for
 * the current day of the week.
 * If the current time is before 8:00 AM, the time is set to 8:00 AM.
 *
 * @param {Date} date - The current date.
 * @returns {Date} A new Date object with the modified day and time.
 */
export const getNextDayIfAfterHours = (date: Date): Date => {
	const currentHour = date.getHours();
	const dateObj = new Date(date);
	if (isSunday(date)) {
		dateObj.setDate(date.getDate() + 1);
		dateObj.setHours(8);
	} else if (isSaturday(date) && currentHour > SATURDAY_MAX_HOUR) {
		dateObj.setDate(date.getDate() + 2);
		dateObj.setHours(8);
	} else if (isFriday(date) && currentHour > FRIDAY_MAX_HOUR) {
		dateObj.setDate(date.getDate() + 1);
		dateObj.setHours(8);
	} else if (currentHour > WEEKDAY_MAX_HOUR) {
		dateObj.setDate(date.getDate() + 1);
		dateObj.setHours(8);
	} else if (currentHour < MIN_HOUR) {
		dateObj.setHours(8);
	}
	dateObj.setMinutes(0);
	dateObj.setSeconds(0);
	dateObj.setMilliseconds(0);

	return dateObj;
};
