import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import FormTitle from "../../../Common/FormTitle/FormTitle";

export default function ThankYou() {
	const { t } = useTranslation("thankYou");

	return (
		<div className="mx-auto container-max-sm m-4 text-center">
			<FormTitle className="text-center">{t("thankYou.title")}</FormTitle>
			<p>
				<FontAwesomeIcon
					icon={["fat", "check-circle"]}
					className="text-success fa-7x my-4"
				/>
			</p>
			<p>{t("thankYou.description")}</p>
		</div>
	);
}
