export interface MaxAmount {
	amount: number;
	message: string;
}

export const maxAmountMonthly: MaxAmount = {
	amount: 10000,
	message: "rules.maxMonthly",
};

export const maxAmountWeekly: MaxAmount = {
	amount: 2300,
	message: "rules.maxWeekly",
};

export const maxAmountAnnually: MaxAmount = {
	amount: 120000,
	message: "rules.maxAnnually",
};

export const maxAmountBiannually: MaxAmount = {
	amount: 60000,
	message: "rules.maxBiannually",
};
