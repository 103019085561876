import { Navigate, Route, Routes } from "react-router-dom";

import { useSession } from "./components/Context/SessionProvider";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import {
	Bundle,
	ChildCare,
	Food,
	Health,
	Housing,
	OtherExpenses,
	Transportation,
	Utilities,
	Vehicle,
	VehicleExpenses,
} from "./components/Prompts/Expenses";
import IncomeIntro from "./components/Prompts/Income/IncomeIntro/IncomeIntro";
import IncomeSources from "./components/Prompts/Income/IncomeSources/IncomeSources";
import NoReEnter from "./components/Prompts/NoReEnter/NoReEnter";
import Applicant from "./components/Prompts/Overview/Applicant/Applicant";
import AssistanceReason from "./components/Prompts/Overview/AssistanceReason/AssistanceReason";
import DebtAmount from "./components/Prompts/Overview/DebtAmount/DebtAmount";
import DebtTypes from "./components/Prompts/Overview/DebtTypes/DebtTypes";
import HasIncome from "./components/Prompts/Overview/HasIncome/HasIncome";
import Household from "./components/Prompts/Overview/Household/Household";
import PaymentStatus from "./components/Prompts/Overview/PaymentStatus/PaymentStatus";
import BudgetSummary from "./components/Prompts/Results/BudgetSummary/BudgetSummary";
import Callback from "./components/Prompts/Results/Callback/Callback";
import ThankYou from "./components/Prompts/Results/ThankYou/ThankYou";
import Version from "./components/Version/Version";
import * as path from "./utils/routes";
import useNavigateHelpers from "./utils/useNavigateHelpers/useNavigateHelpers";
import { useStatus } from "./components/Context/StatusProvider";

export const routes = (): {
	path: string;
	element: JSX.Element;
	pageTitle?: string;
}[] => {
	const { getNextPath, skipRoutes } = useNavigateHelpers();
	const routesToSkip = skipRoutes();

	return [
		{
			path: path.APPLICANT_PATH,
			element: routesToSkip.includes(path.APPLICANT_PATH) ? (
				<Navigate replace to={getNextPath()} />
			) : (
				<Applicant />
			),
			pageTitle: "Applicant",
		},
		{
			path: path.DEBT_TYPES_PATH,
			element: <DebtTypes />,
			pageTitle: "Debt Types",
		},
		{
			path: path.DEBT_AMOUNT_PATH,
			element: routesToSkip.includes(path.DEBT_AMOUNT_PATH) ? (
				<Navigate replace to={getNextPath()} />
			) : (
				<DebtAmount />
			),
			pageTitle: "Debt Amount",
		},
		{
			path: path.PAYMENT_STATUS_PATH,
			element: routesToSkip.includes(path.PAYMENT_STATUS_PATH) ? (
				<Navigate replace to={getNextPath()} />
			) : (
				<PaymentStatus />
			),
			pageTitle: "Payment Status",
		},
		{
			path: path.ASSISTANCE_REASON_PATH,
			element: <AssistanceReason />,
			pageTitle: "Assistance Reason",
		},
		{
			path: path.INCOME_INTRO_PATH,
			element: <IncomeIntro />,
			pageTitle: "Income Introduction",
		},
		{
			path: path.INCOME_SOURCES_PATH,
			element: routesToSkip.includes(path.INCOME_SOURCES_PATH) ? (
				<Navigate replace to={getNextPath()} />
			) : (
				<IncomeSources />
			),
			pageTitle: "Income Sources",
		},
		{
			path: path.HOUSEHOLD_PATH,
			element: <Household />,
			pageTitle: "Household",
		},
		{
			path: path.BUNDLE_PATH,
			element: <Bundle />,
			pageTitle: "Internet, Cable, Phone",
		},
		{
			path: path.UTILITIES_PATH,
			element: <Utilities />,
			pageTitle: "Utilities",
		},
		{
			path: path.FOOD_PATH,
			element: <Food />,
			pageTitle: "Food",
		},
		{
			path: path.HOUSING_PATH,
			element: <Housing />,
			pageTitle: "Housing",
		},
		{
			path: path.HAS_INCOME_PATH,
			element: <HasIncome />,
			pageTitle: "Do You Have Income",
		},
		{
			path: path.VEHICLE_PATH,
			element: <Vehicle />,
			pageTitle: "Vehicle",
		},
		{
			path: path.VEHICLE_EXPENSES_PATH,
			element: <VehicleExpenses />,
			pageTitle: "Vehicle Expenses",
		},
		{
			path: path.TRANSPORTATION_PATH,
			element: <Transportation />,
			pageTitle: "Transportation",
		},
		{
			path: path.HEALTH_PATH,
			element: <Health />,
			pageTitle: "Health",
		},
		{
			path: path.CHILD_CARE_PATH,
			element: <ChildCare />,
			pageTitle: "Child Care",
		},
		{
			path: path.OTHER_EXPENSES_PATH,
			element: <OtherExpenses />,
			pageTitle: "Other Expenses",
		},
		{
			path: path.BUDGET_SUMMARY_PATH,
			element: <BudgetSummary />,
			pageTitle: "Budget Summary",
		},
		{
			path: path.CALLBACK_PATH,
			element: <Callback />,
			pageTitle: "Callback",
		},
		{
			path: path.REQUEST_CALLBACK_PATH,
			element: <Callback />,
			pageTitle: "Request Callback",
		},
		{
			path: path.THANK_YOU_PATH,
			element: <ThankYou />,
			pageTitle: "Thank You",
		},
	];
};

const ProtectedRoute = ({
	route,
	pageTitle,
}: {
	route: JSX.Element;
	pageTitle?: string;
}) => {
	const { isFormEditable } = useSession();
	const { isAbandoned } = useStatus();
	const accessDenied = !isFormEditable() || isAbandoned;

	if (accessDenied) {
		document.title = "No Re-Enter | Consolidated Credit";
		return <NoReEnter />;
	}

	document.title = `${pageTitle} | Consolidated Credit`;
	return route;
};

const AppRouter = () => {
	return (
		<Routes>
			{routes().map((route) => {
				return (
					<Route
						path={route.path}
						element={
							<ProtectedRoute
								route={route.element}
								pageTitle={route.pageTitle}
							/>
						}
						key={route.path}
					/>
				);
			})}

			<Route path={path.VERSION_PATH} element={<Version />} />

			<Route path="*" element={<PageNotFound />} />
		</Routes>
	);
};

export default AppRouter;
