// this function will receive an object of all of the fields
// from react-hook-form watch() function
// it will return a boolean depending on if all of the fields
// have a value

const isFormComplete = (
	fields: { [key: string]: any } | { [key: string]: any }[] | undefined
) => {
	let bool = true;

	if (fields) {
		if (Array.isArray(fields)) {
			fields.forEach((field) => {
				Object.keys(field).forEach((key) => {
					if (!field[key]) {
						bool = false;
					}
				});
			});
		} else {
			Object.keys(fields).forEach((key) => {
				if (!fields[key]) {
					bool = false;
				}
			});
		}
	}

	return bool;
};

export default isFormComplete;
