import React from "react";
import { useSession } from "../../Context/SessionProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Button } from "react-bootstrap";
import { StepLabel } from "../../../utils/Types";
import {
	overview,
	income,
	expenses,
	complete,
	pathsOrder,
} from "../../../utils/routes";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useNavigateHelpers from "../../../utils/useNavigateHelpers/useNavigateHelpers";
import { useUrlParams } from "../../Context";
import { useStatus } from "../../Context/StatusProvider";

interface Step {
	label: StepLabel;
	icon: IconProp;
	routes: string[];
}

export default function Stepper() {
	const { skipRoutes } = useNavigateHelpers();
	const { isAbandoned, isLocked } = useStatus();

	const steps = (): Step[] => {
		return [
			{
				label: "overview",
				icon: "line-chart",
				routes: overview.filter((route) => !skipRoutes().includes(route)),
			},
			{
				label: "income",
				icon: "credit-card",
				routes: income.filter((route) => !skipRoutes().includes(route)),
			},
			{
				label: "expenses",
				icon: "money-bill-transfer",
				routes: expenses.filter((route) => !skipRoutes().includes(route)),
			},
			{
				label: "complete",
				icon: "check",
				routes: complete.filter((route) => !skipRoutes().includes(route)),
			},
		];
	};

	const { activeStep, maxStep, isFormEditable } = useSession();
	const { leadQueryStr, channelQueryStr } = useUrlParams();

	const navigate = useNavigate();
	const { pathname } = useLocation();

	const handleClick = (route: string) => {
		navigate(`${route}${leadQueryStr}${channelQueryStr}`);
	};

	const { t } = useTranslation("global");

	if (
		!pathsOrder.includes(pathname) ||
		!isFormEditable() ||
		isAbandoned ||
		isLocked
	) {
		return null;
	}

	return (
		<div className="bs-stepper">
			<div className="bs-stepper-header" role="tablist">
				{steps().map((step, index) => (
					<React.Fragment key={index}>
						<div
							className={`step ${activeStep === step.label ? "active" : ""}`}
						>
							<Button
								className="step-trigger"
								disabled={index > maxStep}
								onClick={() => handleClick(step.routes[0])}
								role="tab"
							>
								<span className="bs-stepper-circle">
									<FontAwesomeIcon icon={step.icon} />
								</span>
								<div className="bs-stepper-label text-start">
									<span className="step-title">
										{t(`stepper.${step.label}`)}
									</span>
									{step.label !== "complete" ? (
										<>
											<br />
											<span className="text-lowercase step-count">
												{activeStep === step.label ? (
													<>
														{step.routes.findIndex(
															(route) => route === pathname
														) + 1}{" "}
														{t("stepper.of")} {step.routes.length}
													</>
												) : (
													<>
														{step.routes.length} {t("stepper.label")}s
													</>
												)}
											</span>
										</>
									) : (
										""
									)}
								</div>
							</Button>
						</div>
						{index !== steps().length - 1 && <div className="line" />}
					</React.Fragment>
				))}
			</div>
		</div>
	);
}
