export const APPLICANT_PATH = "/";
export const PROFILE_PATH = "/profile";
export const PAYMENT_STATUS_PATH = "/payment-status";
export const DEBT_TYPES_PATH = "/debt-types";
export const ASSISTANCE_REASON_PATH = "/assistance_reason";
export const INCOME_SOURCES_PATH = "/income-sources";
export const HOUSEHOLD_PATH = "/household";
export const BUNDLE_PATH = "/internet-cable";
export const UTILITIES_PATH = "/utilities";
export const FOOD_PATH = "/food";
export const HOUSING_PATH = "/housing";
export const RENT_PATH = "/rent";
export const INCOME_INTRO_PATH = "/income-intro";
export const HAS_INCOME_PATH = "/has-income";
export const DEBT_AMOUNT_PATH = "/debt-amount";
export const VEHICLE_PATH = "/vehicle";
export const VEHICLE_EXPENSES_PATH = "/vehicle-expenses";
export const TRANSPORTATION_PATH = "/transportation";
export const HEALTH_PATH = "/health";
export const CHILD_CARE_PATH = "/child-care";
export const OTHER_EXPENSES_PATH = "/other-expenses";
export const BUDGET_SUMMARY_PATH = "/budget-summary";
export const VERSION_PATH = "/version";
export const CALLBACK_PATH = "/callback";
export const REQUEST_CALLBACK_PATH = "/request-callback";
export const THANK_YOU_PATH = "/thank-you";

// overview step in stepper
export const overview = [
	APPLICANT_PATH,
	DEBT_TYPES_PATH,
	PAYMENT_STATUS_PATH,
	ASSISTANCE_REASON_PATH,
	HAS_INCOME_PATH,
	DEBT_AMOUNT_PATH,
	HOUSEHOLD_PATH,
];

// income step in stepper
export const income = [INCOME_INTRO_PATH, INCOME_SOURCES_PATH];

// expenses step in stepper
export const expenses = [
	HOUSING_PATH,
	BUNDLE_PATH,
	UTILITIES_PATH,
	FOOD_PATH,
	VEHICLE_PATH,
	VEHICLE_EXPENSES_PATH,
	TRANSPORTATION_PATH,
	HEALTH_PATH,
	CHILD_CARE_PATH,
	OTHER_EXPENSES_PATH,
];

// complete step in stepper
export const complete = [BUDGET_SUMMARY_PATH];

// controls the order of the paths as they appear
export const pathsOrder = [...overview, ...income, ...expenses, ...complete];

export const routesWithPageTitles: {
	path: string;
	pageTitle: string;
}[] = [
	{
		path: APPLICANT_PATH,
		pageTitle: "Applicant",
	},
	{
		path: DEBT_TYPES_PATH,
		pageTitle: "Debt Types",
	},
	{
		path: DEBT_AMOUNT_PATH,
		pageTitle: "Debt Amount",
	},
	{
		path: PAYMENT_STATUS_PATH,
		pageTitle: "Payment Status",
	},
	{
		path: ASSISTANCE_REASON_PATH,
		pageTitle: "Assistance Reason",
	},
	{
		path: INCOME_INTRO_PATH,
		pageTitle: "Income Introduction",
	},
	{
		path: INCOME_SOURCES_PATH,
		pageTitle: "Income Sources",
	},
	{
		path: HOUSEHOLD_PATH,
		pageTitle: "Household",
	},
	{
		path: BUNDLE_PATH,
		pageTitle: "Internet, Cable, Phone",
	},
	{
		path: UTILITIES_PATH,
		pageTitle: "Utilities",
	},
	{
		path: FOOD_PATH,
		pageTitle: "Food",
	},
	{
		path: HOUSING_PATH,
		pageTitle: "Housing",
	},
	{
		path: HAS_INCOME_PATH,
		pageTitle: "Do You Have Income",
	},
	{
		path: VEHICLE_PATH,
		pageTitle: "Vehicle",
	},
	{
		path: VEHICLE_EXPENSES_PATH,
		pageTitle: "Vehicle Expenses",
	},
	{
		path: TRANSPORTATION_PATH,
		pageTitle: "Transportation",
	},
	{
		path: HEALTH_PATH,
		pageTitle: "Health",
	},
	{
		path: CHILD_CARE_PATH,
		pageTitle: "Child Care",
	},
	{
		path: OTHER_EXPENSES_PATH,
		pageTitle: "Other Expenses",
	},
	{
		path: BUDGET_SUMMARY_PATH,
		pageTitle: "Budget Summary",
	},
	{
		path: CALLBACK_PATH,
		pageTitle: "Callback",
	},
	{
		path: REQUEST_CALLBACK_PATH,
		pageTitle: "Request Callback",
	},
	{
		path: THANK_YOU_PATH,
		pageTitle: "Thank You",
	},
];
