import { createContext, useContext, useEffect, useState } from "react";
import {
	DebtAmountParams,
	HasIncomeParams,
	StatusParams,
} from "../../utils/Types";
import { useLocation } from "react-router-dom";
import useSessionData from "../../utils/useSessionData/useSessionData";
import useNavigateHelpers from "../../utils/useNavigateHelpers/useNavigateHelpers";
import { useSession } from "./SessionProvider";
import { REQUEST_CALLBACK_PATH } from "../../utils/routes";
import { getQualificationStatus } from "../../utils/getQualificationStatus/getQualificationStatus";
import { getLastPageCollected } from "../../utils/getLastPageCollected/getLastPageCollected";
import { getPercentComplete } from "../../utils/getPercentComplete/getPercentComplete";
import { getFormStatus } from "../../utils/getFormStatus/getFormStatus";
import { getFinalizedStatus } from "../../utils/getFinalizedStatus/getFinalizedStatus";

export const initialStatusValues: StatusParams = {
	applicant_percent_complete: 0,
	applicant_finalized: false,
	applicant_last_page_collected: "",
	applicant_qualified_status: undefined,
	applicant_form_status: undefined,
	applicant_page_position: 0,
};

const initialState: {
	calcStatus: (arg: any) => StatusParams;
	isAbandoned: boolean;
	isLocked: boolean;
	setIsAbandoned: React.Dispatch<React.SetStateAction<boolean>>;
	setIsLocked: React.Dispatch<React.SetStateAction<boolean>>;
	status: StatusParams;
} = {
	calcStatus: () => initialStatusValues,
	isAbandoned: false,
	setIsAbandoned: () => undefined,
	isLocked: false,
	setIsLocked: () => undefined,
	status: initialStatusValues,
};

export const StatusContext = createContext(initialState);

export default function StatusProvider({ children }: any) {
	const { initializing } = useSession();
	const [currentIndex, setCurrentIndex] = useState(0);

	const {
		debt_amount,
		applicant_form_status,
		applicant_finalized,
		applicant_percent_complete,
		applicant_last_page_collected,
		applicant_page_position,
		applicant_qualified_status,
		has_income,
	} = useSessionData();

	// hydrate the values from the session
	// NOTE: these values will be undefined initially
	const [status, setStatus] = useState<StatusParams>({
		applicant_finalized,
		applicant_form_status,
		applicant_last_page_collected,
		applicant_page_position,
		applicant_percent_complete,
		applicant_qualified_status,
	});

	const [isAbandoned, setIsAbandoned] = useState<boolean>(
		applicant_form_status === "Abandoned"
	);

	const [isLocked, setIsLocked] = useState<boolean>(
		applicant_form_status === "Locked"
	);

	const { pathname } = useLocation();
	const { filterSkippedRoutes } = useNavigateHelpers();
	const allRoutes = filterSkippedRoutes(true);
	const skippedRoutes = filterSkippedRoutes();

	const requestCallback = pathname === REQUEST_CALLBACK_PATH;
	const total = allRoutes.length;

	const idx = requestCallback
		? currentIndex
		: (allRoutes.findIndex((route) => route === pathname) || 0) + 1;

	useEffect(() => {
		if (applicant_form_status === "Abandoned") {
			setIsAbandoned(true);
		}
		if (applicant_form_status === "Locked") {
			setIsLocked(true);
		}
	}, [applicant_form_status]);

	const lastPageCollected = getLastPageCollected(pathname);

	const percentComplete = getPercentComplete({
		requestCallback,
		currentIndex,
		allRoutes,
		pathname,
	});

	const formStatus = getFormStatus({
		isAbandoned,
		isLocked,
		percentComplete,
	});

	const finalizedStatus = getFinalizedStatus({
		percentComplete,
		applicant_finalized,
		lastPageCollected,
	});

	const pagePosition = requestCallback
		? currentIndex
		: (skippedRoutes.findIndex((route) => route === pathname) || 0) + 1;

	const calcStatus = ({
		hasIncome = has_income,
		debtAmount = debt_amount,
	}: {
		hasIncome?: HasIncomeParams["has_income"];
		debtAmount?: DebtAmountParams["debt_amount"];
	}) => {
		const values = {
			applicant_percent_complete: percentComplete,
			applicant_finalized: finalizedStatus,
			applicant_form_status: formStatus,
			applicant_last_page_collected: lastPageCollected,
			applicant_page_position: pagePosition,
			applicant_qualified_status: getQualificationStatus({
				hasIncome,
				debtAmount,
			}),
		};

		setStatus(values);
		return values;
	};

	useEffect(() => {
		if (
			applicant_form_status !== "Abandoned" &&
			applicant_form_status !== "Locked" &&
			!initializing
		) {
			calcStatus({
				hasIncome: has_income,
				debtAmount: debt_amount,
			});
		}
	}, [idx, total]);

	useEffect(() => {
		if (!requestCallback && currentIndex !== idx) setCurrentIndex(idx);
	}, [requestCallback, currentIndex, idx]);

	const value = {
		calcStatus,
		isAbandoned,
		setIsAbandoned,
		isLocked,
		setIsLocked,
		status,
	};

	return (
		<StatusContext.Provider value={value}>{children}</StatusContext.Provider>
	);
}

export const useStatus = () => useContext(StatusContext);
