import {
	Document,
	Page,
	Text,
	View,
	StyleSheet,
	PDFDownloadLink,
	Image,
	Font,
} from "@react-pdf/renderer";
import normalize from "../../../../utils/normalizeCurrency/normalizeCurrency";
import { useSession } from "../../../Context/SessionProvider";
import { useTranslation } from "react-i18next";
import useBudgetHelpers from "../../../../utils/useBudgetHelpers/useBudgetHelpers";
import { getSourceOptions } from "../../Income/IncomeSources/IncomeSources";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { config } from "../../../../utils/countryConfig";

Font.registerHyphenationCallback((word: string) => [word]);

const styles = StyleSheet.create({
	page: {
		display: "flex",
		flexDirection: "column",
		color: "#212529",
		breakInside: "avoid",
		paddingBottom: 30,
	},
	wrapper: {
		marginHorizontal: 50,
	},
	logo: {
		width: 150,
		margin: 30,
	},
	chartContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		marginBottom: 30,
	},
	chart: {
		width: 200,
		height: 200,
		marginRight: 20,
	},
	legend: {
		display: "flex",
		flexDirection: "column",
	},
	legendItem: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginBottom: 5,
	},
	label: {
		fontSize: 12,
	},
	square: {
		width: 20,
		height: 20,
		borderRadius: 5,
		marginRight: 10,
	},
	sectionTitle: {
		fontSize: 20,
		fontWeight: "bold",
		marginTop: 20,
		marginBottom: 20,
		textAlign: "center",
	},
	sectionSubTitle: {
		fontSize: 12,
		fontWeight: "light",
		textTransform: "uppercase",
		letterSpacing: 1.05,
		marginTop: 10,
		marginBottom: 20,
		color: "#003768",
		textAlign: "left",
	},
	budgetContainer: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		width: "100%",
		marginBottom: 20,
	},
	budgetItem: {
		fontSize: 12,
		marginBottom: 5,
		flexBasis: "50%",
		paddingRight: 25,
		display: "flex",
		flexDirection: "row",
	},
	budgetItemLabel: {
		flexBasis: "75%",
		paddingRight: 10,
	},
	budgetItemAmount: {
		textAlign: "right",
	},
	TotalIncomeAndExpenseContainer: {
		display: "flex",
		flexDirection: "column",
		flexWrap: "nowrap",
		width: "100%",
		marginBottom: 10,
	},
	totalIncomeItem: {
		fontSize: 12,
		marginBottom: 5,
		flexBasis: "2%",
		paddingRight: 25,
		display: "flex",
		flexDirection: "row",
	},
});

const BudgetSummaryPDF = ({
	chartImage,
	session,
	getValue,
	t,
	budgetSummaryT,
	remaining,
}: any) => {
	const {
		currentBudget: {
			aggregates: {
				totalMonthlyChildrenExpenses = 0,
				totalMonthlyExpenses = 0,
				totalMonthlyFoodExpenses = 0,
				totalMonthlyHousingExpenses = 0,
				totalMonthlyIncome = 0,
				totalMonthlyInsuranceExpenses = 0,
				totalMonthlyMedicalExpenses = 0,
				totalMonthlyPersonalExpenses = 0,
				totalMonthlyTransportationExpenses = 0,
				totalMonthlyUtilitiesExpenses = 0,
			} = {},
		} = {},
	} = session.vic || {};

	const chartData = [
		{
			label: t("categories.housing"),
			value: normalize(totalMonthlyHousingExpenses, true),
			backgroundColor: "#A6CEE3",
		},
		{
			label: t("categories.food"),
			value: normalize(totalMonthlyFoodExpenses, true),
			backgroundColor: "#1F78B4",
		},
		{
			label: t("categories.utilities"),
			value: normalize(totalMonthlyUtilitiesExpenses, true),
			backgroundColor: "#FF7F50",
		},
		{
			label: t("categories.children"),
			value: normalize(totalMonthlyChildrenExpenses, true),
			backgroundColor: "#FBB138",
		},
		{
			label: t("categories.transportation"),
			value: normalize(totalMonthlyTransportationExpenses, true),
			backgroundColor: "#85BC68",
		},
		{
			label: t("categories.personal"),
			value: normalize(totalMonthlyPersonalExpenses, true),
			backgroundColor: "#FDAC98",
		},
		{
			label: t("categories.medical"),
			value: normalize(totalMonthlyMedicalExpenses, true),
			backgroundColor: "#906E98",
		},
		{
			label: t("categories.insurance"),
			value: normalize(totalMonthlyInsuranceExpenses, true),
			backgroundColor: "#088DA5",
		},
	];

	const expensesData: { [key: string]: string | undefined } = {};

	const incomeData: { [key: string]: string | undefined } = {};

	const incomeSources = getSourceOptions();

	incomeSources.forEach((source) => {
		const value = getValue(source.value, true);
		if (+value > 0) {
			incomeData[source.label] = normalize(value, true);
		}
	});

	const { expenses } = config;

	for (const cat in expenses) {
		expenses[cat].fields.forEach((field) => {
			const value = getValue(field.value, true);
			if (+value > 0) {
				expensesData[t(field.label)] = normalize(
					getValue(field.value, true),
					true
				);
			}
		});
	}

	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<Image src={config.logos.png} style={styles.logo} fixed />

				<Text style={styles.sectionTitle}>{t("pdfTitle")}</Text>

				<View style={styles.wrapper}>
					<Text style={styles.sectionSubTitle}>{t("chartTitle")}</Text>
				</View>

				<View style={styles.chartContainer}>
					<div style={styles.chart}>
						<Image src={chartImage} />
					</div>
					<div style={styles.legend}>
						{chartData.map((i) => {
							return (
								<div key={i.label} style={styles.legendItem}>
									<div
										style={{
											...styles.square,
											backgroundColor: i.backgroundColor,
										}}
									/>
									<Text style={styles.label}>
										{i.label}: {i.value}
									</Text>
								</div>
							);
						})}
					</div>
				</View>

				<View style={styles.wrapper}>
					<Text style={styles.sectionSubTitle}>
						{t("categories.allIncome")}
					</Text>

					<View style={styles.budgetContainer}>
						{Object.keys(incomeData).map((field) => {
							return (
								<View style={styles.budgetItem} key={field}>
									<Text style={styles.budgetItemLabel}>{field}</Text>
									<Text style={styles.budgetItemAmount}>
										{incomeData[field]}
									</Text>
								</View>
							);
						})}
					</View>

					<Text style={styles.sectionSubTitle}>
						{t("categories.allExpenses")}
					</Text>

					<View style={styles.budgetContainer}>
						{Object.keys(expensesData).map((field) => {
							return (
								<View key={field} style={styles.budgetItem}>
									<Text style={styles.budgetItemLabel}>{field}</Text>
									<Text style={styles.budgetItemAmount}>
										{expensesData[field]}
									</Text>
								</View>
							);
						})}
					</View>

					<Text style={styles.sectionSubTitle}>{t("totalTitle")}</Text>

					<View style={styles.TotalIncomeAndExpenseContainer}>
						<View style={styles.totalIncomeItem}>
							<Text style={styles.budgetItemLabel}>
								{t("categories.totalIncome")}
							</Text>
							<Text style={styles.budgetItemAmount}>
								{normalize(totalMonthlyIncome, true)}
							</Text>
						</View>
						<View style={styles.totalIncomeItem}>
							<Text style={styles.budgetItemLabel}>
								{t("categories.totalExpenses")}
							</Text>
							<Text style={styles.budgetItemAmount}>
								{normalize(totalMonthlyExpenses, true)}
							</Text>
						</View>
						<View style={styles.totalIncomeItem}>
							<Text style={styles.budgetItemLabel}>
								{remaining >= 0
									? budgetSummaryT("budgetSummary.aggregates.remaining")
									: budgetSummaryT("budgetSummary.aggregates.shortfall")}
							</Text>
							<Text style={styles.budgetItemAmount}>
								{normalize(remaining, true)}
							</Text>
						</View>
					</View>
				</View>
			</Page>
		</Document>
	);
};

export default function DownloadBudget({ chartImage }: { chartImage: string }) {
	const { t: budgetSummaryT } = useTranslation("budgetSummary");
	const { t: expensesT } = useTranslation("expensesFieldLabels");

	const { session } = useSession();
	const { getValue, getRemaining } = useBudgetHelpers();
	const remaining = getRemaining();

	return (
		<PDFDownloadLink
			fileName="budget"
			document={
				<BudgetSummaryPDF
					chartImage={chartImage}
					getValue={getValue}
					session={session}
					t={expensesT}
					budgetSummaryT={budgetSummaryT}
					remaining={remaining}
				/>
			}
			className="btn btn-primary btn-sm"
		>
			<>
				<FontAwesomeIcon icon={"download"} className="d-inline-block me-2" />
				{budgetSummaryT("budgetSummary.downloadBudget.linkLabel")}
			</>
		</PDFDownloadLink>
	);
}
