import { Frequencies } from "./Types";

function getYearlyAmount(amount: string | number, frequency: Frequencies) {
	if (typeof amount === "string" && isNaN(parseInt(amount))) return NaN;

	const quotient = +amount * frequency;
	return Math.round(quotient * 100) / 100;
}

function divideCurrency(amount: string, frequency: Frequencies = 12) {
	if (isNaN(parseInt(amount))) return NaN;
	const parts = amount.split(".");

	const whole = parseInt(parts[0]) * 100;
	const partial = parseInt(parts[1]) || 0;

	let wh = whole / frequency;
	const pw = partial / frequency;

	wh += pw;
	wh = Math.round(wh);

	const currency = wh / 100;
	return currency;
}

const CURRENCY_REGEX = /^\d*.\d{0,2}$|^\d+$/;

export { getYearlyAmount, divideCurrency, CURRENCY_REGEX };
