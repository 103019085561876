import { useSession } from "../../components/Context/SessionProvider";
import {
	ApplicantParams,
	AssistanceReasonParams,
	DebtAmountParams,
	DebtTypesParams,
	ExpensesParams,
	HasIncomeParams,
	HouseholdParams,
	IncomeParams,
	PaymentStatusParams,
	StatusParams,
} from "../Types";

// maps data from the session and vic into a central location

export default function useSessionData() {
	const {
		session: {
			applicant_first_name,
			applicant_last_name,
			applicant_cell_phone,
			applicant_zip_code,
			assistance_reason,
			assistance_reason_options,
			debt_amount,
			debt_types,
			has_income,
			applicant_household_size,
			payment_status,
			applicant_income,
			applicant_expenses,
			vic,
			applicant_form_status,
			applicant_finalized,
		},
	} = useSession();

	const collectPIIConsumer = vic?.consent?.collectPII?.consumer;
	const VTConsumer = vic?.consent?.ventureTech?.consumer;
	const budgetElements = vic?.currentBudget?.elements;

	const applicant: ApplicantParams = {
		applicant_first_name:
			applicant_first_name ?? collectPIIConsumer?.firstName ?? "",
		applicant_last_name:
			applicant_last_name ?? collectPIIConsumer?.lastName ?? "",
		applicant_cell_phone:
			applicant_cell_phone ??
			collectPIIConsumer?.attributes?.phone?.number ??
			"",
		applicant_zip_code:
			applicant_zip_code ?? collectPIIConsumer?.attributes?.zip ?? "",
	};

	const assistanceReason: AssistanceReasonParams = {
		assistance_reason:
			assistance_reason ?? VTConsumer?.attributes?.assistanceReason ?? "",
		assistance_reason_options:
			assistance_reason_options ??
			VTConsumer?.attributes?.assistanceReasonOptions ??
			"",
	};

	const debtAmount: DebtAmountParams = {
		debt_amount: debt_amount ?? VTConsumer?.attributes?.debtAmount ?? "",
	};

	const debtTypes: DebtTypesParams = {
		debt_types: debt_types ?? VTConsumer?.attributes?.debtTypes ?? [],
	};

	const hasIncome: HasIncomeParams = {
		has_income: has_income ?? VTConsumer?.attributes?.hasIncome ?? undefined,
	};

	const householdSize: HouseholdParams = {
		applicant_household_size:
			applicant_household_size ??
			collectPIIConsumer?.attributes?.householdSize ??
			"",
	};

	const paymentStatus: PaymentStatusParams = {
		payment_status: payment_status ?? VTConsumer?.attributes?.debtStatus ?? "",
	};

	const applicantIncome = (): IncomeParams => {
		const vicIncome =
			budgetElements?.filter((element) => element.category === "Income") || [];

		const map = new Map(
			[
				...(applicant_income || []),
				...vicIncome,
				...(applicant_income || []),
			].map((pos) => [pos.name, pos])
		);

		return {
			applicant_income: Array.from(map.values()),
		};
	};

	const applicantExpenses = (): ExpensesParams => {
		const vicExpenses =
			budgetElements?.filter((element) => element.category !== "Income") || [];

		const map = new Map(
			[
				...(applicant_expenses || []),
				...vicExpenses,
				...(applicant_expenses || []),
			].map((pos) => [pos.name, pos])
		);

		return {
			applicant_expenses: Array.from(map.values()),
		};
	};

	const formStatus: StatusParams = {
		applicant_form_status:
			applicant_form_status ??
			vic?.consent?.ventureTech?.attributes?.formStatus,
	};

	const finalized: StatusParams = {
		applicant_finalized:
			applicant_finalized ?? vic?.consent?.ventureTech?.attributes?.finalized,
	};

	return {
		...applicant,
		...assistanceReason,
		...debtAmount,
		...debtTypes,
		...hasIncome,
		...householdSize,
		...paymentStatus,
		...applicantIncome(),
		...applicantExpenses(),
		...formStatus,
		...finalized,
	};
}
