import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CURRENCY_REGEX } from "../../../../utils/currencyUtils";

import type { MortgageParams, RentParams } from "../../../../utils/Types";
import useBudgetHelpers from "../../../../utils/useBudgetHelpers/useBudgetHelpers";
import useNavigateHelpers from "../../../../utils/useNavigateHelpers/useNavigateHelpers";
import usePostIntent from "../../../../utils/usePostIntent/usePostIntent";
import FormNavButtons from "../../../Common/FormNavButtons/FormNavButtons";
import FormTitle from "../../../Common/FormTitle/FormTitle";
import TextFieldGroup from "../../../Common/TextFieldGroup/TextFieldGroup";
import MortgageForm from "./MortgageForm";

export interface MortgagePayments {
	mortgages?: {
		monthly_payment: string;
	}[];
}

export function Housing() {
	const { t: global } = useTranslation("global");
	const { t } = useTranslation("expenses");

	const { postFormData, postStatus } = usePostIntent();
	const { getValue, generateDefaultValues } = useBudgetHelpers();

	const apostropheHack1 = () => {
		const generated = generateDefaultValues<RentParams>([
			"Rent",
			"Renters Insurance",
			"Renter's Insurance",
		]);
		generated["Renters Insurance"] = generated["Renter's Insurance"];
		return generated;
	};

	const apostropheHack2 = (data: RentParams) => {
		if ("Renters Insurance" in data) {
			data["Renter's Insurance"] = data["Renters Insurance"];
			delete data["Renters Insurance"];
		}
		return data;
	};

	const defaultValues = {
		mortgages: [
			{
				monthly_payment: getValue("First Mortgage"),
			},
		],
		...generateDefaultValues<MortgageParams>([
			"Association Fees",
			"Home Owners Insurance",
			"Real Estate Taxes",
			"Home Equity Loan",
		]),
		...apostropheHack1(),
	};

	if (getValue("Second Mortgage")) {
		defaultValues.mortgages.push({
			monthly_payment: getValue("Second Mortgage"),
		});
	}

	const {
		handleSubmit,
		control,
		setValue,
		formState: { isDirty },
		watch,
	} = useForm<MortgageParams & MortgagePayments & RentParams>({
		defaultValues,
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: "mortgages",
	});

	const { navigateNextPrompt } = useNavigateHelpers();

	const submitForm = (data: any) => {
		if (isDirty) {
			const [firstMortgage, secondMortgage] = data.mortgages || [];

			if (firstMortgage) {
				data["First Mortgage"] = firstMortgage.monthly_payment;
			}

			if (secondMortgage) {
				data["Second Mortgage"] = secondMortgage.monthly_payment;
			}

			delete data.mortgages;

			const transformedData = apostropheHack2(data);

			const CATEGORY = "Housing";

			postFormData(transformedData, CATEGORY).then(() => {
				postStatus();
				navigateNextPrompt();
			});
		} else {
			postStatus();
			navigateNextPrompt();
		}
	};

	const deleteMortgage = () => {
		const SECOND_MORTGAGE_INDEX = 1;

		remove(SECOND_MORTGAGE_INDEX);
		setValue("Second Mortgage", "");
	};

	const setRequired = (value: string) => {
		if (watch("Renters Insurance")) {
			return !!value || t("housing.rent.rentersInsurance.rules.required");
		}
	};

	return (
		<>
			<FormTitle faIcon={["fas", "house"]}>{t("housing.formTitle")}</FormTitle>
			<Form onSubmit={handleSubmit(submitForm)} noValidate>
				<p className="text-navy-blue fw-700 mt-5">
					{t("housing.rent.formTitle")}
				</p>
				<Row className="mt-4">
					<Col>
						<TextFieldGroup
							label={t("housing.rent.monthlyPayment.label")}
							name="Rent"
							prefix="$"
							className="field-xs"
							type="number"
							control={control}
							rules={{
								validate: {
									setRequired,
								},
								pattern: {
									value: CURRENCY_REGEX,
									message: global("rules.dollarAmount"),
								},
								max: {
									value: 100000,
									message: global("rules.max", { amount: 100000 }),
								},
								min: {
									value: 0,
									message: global("rules.min"),
								},
							}}
						/>
					</Col>
					<Col>
						<TextFieldGroup
							rules={{
								pattern: {
									value: CURRENCY_REGEX,
									message: global("rules.dollarAmount"),
								},
								max: {
									value: 100000,
									message: global("rules.max", { amount: 100000 }),
								},
								min: {
									value: 0,
									message: global("rules.min"),
								},
							}}
							label={t("housing.rent.rentersInsurance.label")}
							name="Renters Insurance"
							prefix="$"
							className="field-xs"
							type="number"
							control={control}
						/>
					</Col>
				</Row>

				<p className="text-navy-blue fw-700 mt-5">
					{t("housing.mortgage.formTitle")}
				</p>

				<Row className="align-items-center">
					{fields.map((item, index) => (
						<Col sm={12} md={6} lg={4} key={item.id}>
							<MortgageForm
								control={control}
								index={index}
								deleteMortgage={deleteMortgage}
								name={`mortgages.${index}`}
								t={t}
							/>
						</Col>
					))}
					{fields.length < 2 && (
						<Col xs={12} lg={6} xl={4}>
							<Button
								variant="link"
								className="all-caps-link"
								onClick={() => append({ monthly_payment: "" })}
							>
								<FontAwesomeIcon icon="plus" className="d-inline-block me-2" />
								{t("housing.mortgage.addMortgage")}
							</Button>
						</Col>
					)}
				</Row>

				<div className="info-block my-5">
					<p className="m-0">
						{t("housing.mortgage.description")} <br />
						{t("housing.mortgage.hoaBlank")} <br />
						{t("housing.mortgage.taxesBlank")}
					</p>
				</div>

				<Row>
					<Col xs={6} lg>
						<Form.Group>
							<TextFieldGroup
								rules={{
									pattern: {
										value: CURRENCY_REGEX,
										message: global("rules.dollarAmount"),
									},
									max: {
										value: 100000,
										message: global("rules.max", { amount: 100000 }),
									},
									min: {
										value: 0,
										message: global("rules.min"),
									},
								}}
								label={t("housing.mortgage.taxesAndInsurance.label")}
								prefix="$"
								name="Home Owners Insurance"
								type="number"
								className="field-xs"
								control={control}
							/>
						</Form.Group>
					</Col>
					<Col xs={6} lg>
						<Form.Group>
							<TextFieldGroup
								rules={{
									pattern: {
										value: CURRENCY_REGEX,
										message: global("rules.dollarAmount"),
									},
									max: {
										value: 100000,
										message: global("rules.max", { amount: 100000 }),
									},
									min: {
										value: 0,
										message: global("rules.min"),
									},
								}}
								label={t("housing.mortgage.hoa.label")}
								prefix="$"
								name="Association Fees"
								type="number"
								className="field-xs"
								control={control}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row className="mt-4">
					<Col>
						<Form.Group>
							<TextFieldGroup
								rules={{
									pattern: {
										value: CURRENCY_REGEX,
										message: global("rules.dollarAmount"),
									},
									max: {
										value: 100000,
										message: global("rules.max", { amount: 100000 }),
									},
									min: {
										value: 0,
										message: global("rules.min"),
									},
								}}
								label={t("housing.mortgage.taxes.label")}
								prefix="$"
								name="Real Estate Taxes"
								type="number"
								className="field-xs"
								control={control}
							/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group>
							<TextFieldGroup
								rules={{
									pattern: {
										value: CURRENCY_REGEX,
										message: global("rules.dollarAmount"),
									},
									max: {
										value: 100000,
										message: global("rules.max", { amount: 100000 }),
									},
									min: {
										value: 0,
										message: global("rules.min"),
									},
								}}
								label={t("housing.mortgage.equityLoan.label")}
								prefix="$"
								name="Home Equity Loan"
								type="number"
								className="field-xs"
								control={control}
							/>
						</Form.Group>
					</Col>
				</Row>
				<FormNavButtons />
			</Form>
		</>
	);
}
