import { Form } from "react-bootstrap";
import { useController } from "react-hook-form";

interface RadioGroupProps {
	options: { label: string; value: string }[];
	control: any;
	name: string;
	rules?: any;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

/**
 * RadioGroup is a custom form control for rendering a group of radio buttons.
 * It should be used in conjunction with the `react-hook-form` library.
 *
 * @param options an array of objects representing the radio buttons to be
 * rendered, with properties "label" and "value"
 * @param control an instance of the `react-hook-form` controller
 * @param name the name attribute to be applied to each radio button
 * @param rules optional validation rules to be applied to the radio group
 * @param onChange an optional callback function to be called when a radio button
 * is selected
 */
function RadioGroup({
	options,
	control,
	name,
	rules,
	onChange,
}: RadioGroupProps) {
	const { field } = useController({
		control,
		name,
		rules,
		defaultValue: "",
	});

	return (
		<Form.Group>
			{options.map((option, index) => {
				const { label, value } = option;
				return (
					<div key={index}>
						<Form.Check
							checked={field.value === value}
							id={`${name}-${index}`}
							label={label}
							name={name}
							onChange={onChange || field.onChange}
							type="radio"
							value={value}
						/>
					</div>
				);
			})}
		</Form.Group>
	);
}

export default RadioGroup;
