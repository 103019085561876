import { Row, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import FormNavButtons from "../../../Common/FormNavButtons/FormNavButtons";
import FormTitle from "../../../Common/FormTitle/FormTitle";
import TextFieldGroup from "../../../Common/TextFieldGroup/TextFieldGroup";
import useBudgetHelpers from "../../../../utils/useBudgetHelpers/useBudgetHelpers";
import useNavigateHelpers from "../../../../utils/useNavigateHelpers/useNavigateHelpers";
import usePostIntent from "../../../../utils/usePostIntent/usePostIntent";
import { CableInternetPhoneParams } from "../../../../utils/Types";
import { CURRENCY_REGEX } from "../../../../utils/currencyUtils";

export function Bundle() {
	const { t: global } = useTranslation("global");
	const { t } = useTranslation("expenses");
	const { postStatus, postFormData } = usePostIntent();
	const { generateDefaultValues } = useBudgetHelpers();

	const {
		handleSubmit,
		control,
		formState: { isDirty },
	} = useForm<CableInternetPhoneParams>({
		defaultValues: generateDefaultValues<CableInternetPhoneParams>([
			"Bundle TV/Cable/Internet",
			"Internet",
			"Cable",
			"Phone",
			"Cellular",
		]),
	});

	const { navigateNextPrompt } = useNavigateHelpers();

	const submitForm = (data: CableInternetPhoneParams) => {
		if (isDirty) {
			const CATEGORY = "Utilities";
			postFormData(data, CATEGORY).then(() => {
				postStatus();
				navigateNextPrompt();
			});
		} else {
			postStatus();
			navigateNextPrompt();
		}
	};

	return (
		<>
			<FormTitle faIcon={["fas", "wifi"]}>{t("bundle.formTitle")}</FormTitle>
			<p className="text-navy-blue italic text-small">{global("monthly")}</p>

			<Form onSubmit={handleSubmit(submitForm)} noValidate>
				<Row>
					<Col>
						<Form.Group>
							<TextFieldGroup
								label={t("bundle.bundleServices.label")}
								rules={{
									pattern: {
										value: CURRENCY_REGEX,
										message: global("rules.dollarAmount"),
									},
									max: {
										value: 10000,
										message: global("rules.max", { amount: 10000 }),
									},
									min: {
										value: 0,
										message: global("rules.min"),
									},
								}}
								prefix="$"
								name="Bundle TV/Cable/Internet"
								type="number"
								className="field-xs"
								control={control}
							/>
						</Form.Group>
					</Col>
				</Row>

				<p className="mt-4">{t("bundle.description")}</p>

				<Row className="align-items-lg-end">
					<Col xs={12} lg>
						<Form.Group>
							<TextFieldGroup
								label={t("bundle.internet.label")}
								rules={{
									pattern: {
										value: CURRENCY_REGEX,
										message: global("rules.dollarAmount"),
									},
									max: {
										value: 10000,
										message: global("rules.max", { amount: 10000 }),
									},
									min: {
										value: 0,
										message: global("rules.min"),
									},
								}}
								prefix="$"
								name="Internet"
								type="number"
								className="field-xs"
								control={control}
							/>
						</Form.Group>
					</Col>
					<Col xs={12} lg className="mt-4 mt-lg-0">
						<Form.Group>
							<TextFieldGroup
								label={t("bundle.cableTV.label")}
								rules={{
									pattern: {
										value: CURRENCY_REGEX,
										message: global("rules.dollarAmount"),
									},
									max: {
										value: 10000,
										message: global("rules.max", { amount: 10000 }),
									},
									min: {
										value: 0,
										message: global("rules.min"),
									},
								}}
								prefix="$"
								name="Cable"
								type="number"
								className="field-xs"
								control={control}
							/>
						</Form.Group>
					</Col>

					<Col xs={12} lg className="mt-4 mt-lg-0">
						<Form.Group>
							<TextFieldGroup
								label={t("bundle.cellular.label")}
								rules={{
									pattern: {
										value: CURRENCY_REGEX,
										message: global("rules.dollarAmount"),
									},
									max: {
										value: 10000,
										message: global("rules.max", { amount: 10000 }),
									},
									min: {
										value: 0,
										message: global("rules.min"),
									},
								}}
								prefix="$"
								name="Cellular"
								type="number"
								className="field-xs"
								control={control}
							/>
						</Form.Group>
					</Col>
					<Col xs={12} lg className="mt-4 mt-lg-0">
						<Form.Group>
							<TextFieldGroup
								label={t("bundle.homePhone.label")}
								rules={{
									pattern: {
										value: CURRENCY_REGEX,
										message: global("rules.dollarAmount"),
									},
									max: {
										value: 10000,
										message: global("rules.max", { amount: 10000 }),
									},
									min: {
										value: 0,
										message: global("rules.min"),
									},
								}}
								prefix="$"
								name="Phone"
								type="number"
								className="field-xs"
								control={control}
							/>
						</Form.Group>
					</Col>
				</Row>

				<FormNavButtons />
			</Form>
		</>
	);
}
