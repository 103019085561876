import { Suspense, useEffect } from "react";

import { Alert, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";

import AppRouter from "../../../AppRouter";
import useIntentHelpers from "../../../utils/usePostIntent/usePostIntent";
import FullPageLoader from "../../Common/FullPageLoader/FullPageLoader";
import { useSession } from "../../Context/SessionProvider";
import Header from "../Header/Header";
import Stepper from "../Stepper/Stepper";
import Consent from "../../Consent/Consent";
import ChatbotWidget from "../Chatbot/ChatbotWidget";
import { useChatbot } from "../../Context/ChatbotProvider";
import { Disclaimer } from "../../Disclaimer/Disclaimer";

export default function Content() {
	const { t } = useTranslation("global");
	const { displayChatbot, isChatbotOpen, isSmallScreen } = useChatbot();
	const { postHandshake } = useIntentHelpers();

	useEffect(() => {
		postHandshake();
	}, []);

	const { initializing, error, isConsentedUser } = useSession();
	const { isContentHidden } = useChatbot();

	if (initializing) return <FullPageLoader />;

	return (
		<Suspense fallback={<FullPageLoader />}>
			<div className="content">
				<Header />
				<Container fluid className="flex-grow-1 d-flex flex-column">
					<Row className="flex-grow-1">
						<AnimatePresence mode={isSmallScreen ? "wait" : undefined}>
							<motion.div className="col">
								<Container>
									{error ? (
										<Alert
											variant="danger"
											className="mt-3 container-max-lg mx-auto text-center"
										>
											{t("error")}
										</Alert>
									) : (
										""
									)}

									<div
										className={`${
											isContentHidden ? "d-none" : ""
										} container-max-lg mx-auto m-5`}
									>
										<motion.div layout={isSmallScreen ? false : "position"}>
											<div className="bg-white p-4 mx-auto shadow rounded">
												<Stepper />
												<div className="mx-lg-4  mt-5">
													<AppRouter />
													{!isConsentedUser && <Consent />}
												</div>
											</div>
										</motion.div>
									</div>
								</Container>
							</motion.div>

							{displayChatbot && isChatbotOpen && (
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									className="col-auto chatbot-container"
									key="chatbot"
								>
									<ChatbotWidget />
								</motion.div>
							)}
						</AnimatePresence>
					</Row>
				</Container>
			</div>
			<Disclaimer />
		</Suspense>
	);
}
