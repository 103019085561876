import { useNavigate } from "react-router-dom";
import FormTitle from "../Common/FormTitle/FormTitle";
import { APPLICANT_PATH, CALLBACK_PATH } from "../../utils/routes";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function PageNotFound() {
	const navigate = useNavigate();
	const { t } = useTranslation("pageNotFound");

	document.title = "Page Not Found";

	return (
		<div className="mx-auto container-max-sm">
			<FormTitle className="text-center">{t("title")}</FormTitle>
			<p>{t("description")}</p>
			<div className="d-grid gap-2 field-md mx-auto">
				<Button onClick={() => navigate(APPLICANT_PATH)}>
					{t("returnToApplication")}
				</Button>
				<Button
					variant="link"
					className="text-primary"
					onClick={() => navigate(CALLBACK_PATH)}
				>
					{t("requestCallback")}
				</Button>
			</div>
		</div>
	);
}

export default PageNotFound;
