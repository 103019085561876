import { Form } from "react-bootstrap";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";

import InvalidFeedback from "../InvalidFeedback/InvalidFeedback";

interface SelectProps {
	label?: string;
	options:
		| string[]
		| {
				value?: string | number;
				label?: string;
		  }[];
	control: any;
	name: string;
	rules?: any;
	required?: boolean;
	disabled?: boolean;
	firstOption?: string;
}

export default function Select({
	label,
	options,
	control,
	name,
	rules,
	required,
	disabled,
	firstOption,
}: SelectProps) {
	const { t } = useTranslation("global");
	const {
		field,
		fieldState: { error },
	} = useController({ name, control, rules });

	return (
		<Form.Group className="w-100">
			{label ? (
				<Form.Label htmlFor={name}>
					{label} {required && <span className="text-danger">*</span>}
				</Form.Label>
			) : (
				""
			)}

			<Form.Select
				{...field}
				id={name}
				isInvalid={!!error}
				data-testid="select"
				disabled={disabled}
			>
				<option value="">{firstOption || t("select")}</option>
				{options.map((option) => (
					<option
						value={typeof option === "string" ? option : option.value}
						key={typeof option === "string" ? option : option.value}
						data-testid="select-option"
					>
						{typeof option === "string" ? option : option.label}
					</option>
				))}
			</Form.Select>
			<InvalidFeedback>{error?.message}</InvalidFeedback>
		</Form.Group>
	);
}
