import { Trans, useTranslation } from "react-i18next";
import CallbackForm from "./CallbackForm";

export default function Callback() {
	const { t } = useTranslation("requestCallback");

	return (
		<div className="mx-auto container-max-sm m-4">
			<p className="text-navy-blue italic text-small">{t("title")}</p>
			<Trans t={t} i18nKey="description">
				description
			</Trans>
			<CallbackForm />
		</div>
	);
}
