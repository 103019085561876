import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import useIntentHelpers from "../../../../utils/usePostIntent/usePostIntent";
import useNavigateHelpers from "../../../../utils/useNavigateHelpers/useNavigateHelpers";
import FormNavButtons from "../../../Common/FormNavButtons/FormNavButtons";
import CheckboxGroup from "../../../Common/CheckboxGroup/CheckboxGroup";
import FormTitle from "../../../Common/FormTitle/FormTitle";
import type { DebtTypesParams } from "../../../../utils/Types";
import { useTranslation } from "react-i18next";
import { DEBT_TYPES_PROVIDED_INTENT } from "../../../../utils/intents";
import useSessionData from "../../../../utils/useSessionData/useSessionData";
import { useSession } from "../../../Context/SessionProvider";

export function options() {
	const { t } = useTranslation("overview");

	return [
		{
			label: t("debtTypes.options.creditCard"),
			value: "Credit Card",
			icon: "credit-card",
		},
		{
			label: t("debtTypes.options.personalLoan"),
			value: "Personal Loan",
			icon: "user",
		},
		{
			label: t("debtTypes.options.other"),
			value: "Other",
			icon: "ellipsis",
		},
	];
}

export default function DebtTypes() {
	const { t } = useTranslation("overview");
	const { routesToSkip } = useSession();
	const { debt_types } = useSessionData();

	const {
		handleSubmit,
		control,
		watch,
		formState: { isDirty },
	} = useForm<DebtTypesParams>({
		defaultValues: {
			debt_types,
		},
	});

	const { postIntent, postStatus } = useIntentHelpers();

	const { navigateNextPrompt } = useNavigateHelpers();

	const submitForm = (data: DebtTypesParams) => {
		if (isDirty) {
			postIntent({ parameters: data, text: DEBT_TYPES_PROVIDED_INTENT }).then(
				() => navigateNextPrompt()
			);
		} else {
			postStatus();
			navigateNextPrompt();
		}
	};

	const isComplete = !!watch("debt_types")?.length;

	return (
		<>
			<FormTitle
				className="text-center"
				instructions={t("debtTypes.formTitle.instructions")}
			>
				{t("debtTypes.formTitle.text")}
			</FormTitle>
			<Form onSubmit={handleSubmit(submitForm)}>
				<Form.Group className="mb-5">
					<CheckboxGroup
						name="debt_types"
						options={options()}
						control={control}
						rules={{ required: t("debtTypes.rules.required") }}
					/>
				</Form.Group>
				<FormNavButtons
					hasBackLink={routesToSkip.applicant === true ? false : true}
					nextLinkDisabled={!isComplete}
				/>
			</Form>
		</>
	);
}
