import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import "./Disclaimer.scss";
import { useSession } from "../Context/SessionProvider";
import { getCountry } from "../../utils/getCountry";

const getCookie = () => {
	const cookieString = document.cookie;
	const cookies = cookieString.split(";").map((cookie) => cookie.trim());

	for (const cookie of cookies) {
		const [name, value] = cookie.split("=");
		if (name === "Disclaimer") {
			return value;
		}
	}

	return null;
};

const setCookie = (value: "True" | "False" | "Close") => {
	const now = new Date();

	if (value === "True") {
		now.setFullYear(now.getFullYear() + 1);
	} else {
		now.setDate(now.getDate() + 1);
	}

	const expiration = now.toUTCString();
	document.cookie = `Disclaimer=${value}; expires=${expiration}`;
};

export const Disclaimer = () => {
	const { consentNeeded } = useSession();
	const [showModal, setShowModal] = useState(false);
	const { t } = useTranslation("disclaimer");
	const country = getCountry();

	useEffect(() => {
		const cookie = getCookie();

		if (!cookie && consentNeeded) {
			setShowModal(true);
		}
	}, []);

	return (
		<Modal
			show={showModal}
			centered
			onHide={() => {
				setCookie("Close");
				setShowModal(false);
			}}
			className="disclaimer-modal"
			size={country === "CA" ? "lg" : undefined}
		>
			<Modal.Header closeButton />
			<Modal.Body>
				<p className="fw-light text-navy-blue">
					<Trans
						t={t}
						i18nKey="language"
						components={[
							<a
								href={t("termsAndConditions")}
								key={0}
								target="_blank"
								rel="noopener noreferrer"
								className="text-primary"
							/>,

							<a
								href={t("privacyPolicy")}
								key={1}
								target="_blank"
								rel="noopener noreferrer"
								className="text-primary"
							/>,
						]}
					/>
				</p>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="secondary"
					onClick={() => {
						setCookie("False");
						setShowModal(false);
					}}
				>
					{t("decline")}
				</Button>
				<Button
					variant="primary"
					onClick={() => {
						setCookie("True");
						setShowModal(false);
					}}
				>
					{t("accept")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
