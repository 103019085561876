import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import type {
	BudgetElement,
	FrequencyOptions,
	OtherExpensesParams,
} from "../../../../utils/Types";
import Select from "../../../Common/Select/Select";
import TextFieldGroup from "../../../Common/TextFieldGroup/TextFieldGroup";
import {
	maxAmountAnnually,
	maxAmountWeekly,
	maxAmountMonthly,
	MaxAmount,
} from "../../../../utils/maxAmounts";
import { CURRENCY_REGEX } from "../../../../utils/currencyUtils";
import { getCountry } from "../../../../utils/getCountry";
import GroupedSelect from "../../../Common/GroupedSelect/GroupedSelect";

const country = getCountry();

export function getFrequencyOptions(): FrequencyOptions[] {
	const { t: global } = useTranslation("global");

	return [
		{
			label: global("frequencyOptions.weekly"),
			value: 52,
		},
		{
			label: global("frequencyOptions.monthly"),
			value: 12,
		},
		{
			label: global("frequencyOptions.annually"),
			value: 1,
		},
	];
}

function OtherExpensesForm({
	control,
	name,
	index,
	options,
	expenses,
	deleteExpense,
	selectedExpenseType,
	reValidate,
}: {
	control: any;
	name: string;
	index: number;
	options: {
		label: string;
		value: string;
	}[];
	expenses: BudgetElement[];
	deleteExpense: (index: number) => void;
	selectedExpenseType?: string;
	reValidate: (field: any) => void;
}) {
	const { t: global } = useTranslation("global");
	const { t } = useTranslation("expenses");
	const { t: categories } = useTranslation("expensesFieldLabels", {
		keyPrefix: "expenses.otherExpenses.categories",
	});

	const checkIfTypeExists = (type: string) => {
		const isDuplicate =
			expenses.filter((expense: any) => expense.subCategory === type).length >
			1;

		return !isDuplicate || t("otherExpensesForm.duplicateError");
	};

	const [maxAmount, setMaxAmount] = useState<MaxAmount>(maxAmountMonthly);
	const frequency = expenses[index].frequency;
	const amountEntered = expenses[index].amount;

	useEffect(() => {
		if (frequency) {
			if (+frequency === 1 && maxAmount.amount !== 120000) {
				setMaxAmount(maxAmountAnnually);
			}

			if (+frequency === 12 && maxAmount.amount !== 10000) {
				setMaxAmount(maxAmountMonthly);
			}

			if (+frequency === 52 && maxAmount.amount !== 2300) {
				setMaxAmount(maxAmountWeekly);
			}
		}
	}, [frequency, maxAmount]);

	useEffect(() => {
		if (amountEntered) {
			reValidate(`${name}.amount`);
		}
	}, [maxAmount]);

	const caOptions: {
		name: string;
		options: {
			value: string;
			label: string;
		}[];
	}[] = [
		{ name: categories("recurring"), options: [] },
		{
			name: categories("personal"),
			options: [],
		},
		{
			name: categories("home"),
			options: [],
		},
	];

	// setting up grouped options formatting for Canada
	if (country === "CA") {
		options.forEach((option) => {
			switch (option.value as keyof OtherExpensesParams) {
				case "Total Recurring Credit Card Payments":
				case "Student Loan":
				case "Other Loan":
				case "CRA/Tax Payments":
					caOptions[0].options.push(option);
					break;
				case "Savings":
				case "Clothing":
				case "Cleaning/Laundry":
				case "Health Club Membership":
				case "Hair Care/Nails/Barber":
				case "Alimony":
				case "Miscellaneous":
				case "Entertainment":
				case "Church/Tithe/Charity":
				case "Pets":
				case "Cigarettes":
					caOptions[1].options.push(option);
					break;
				case "Home Repairs/Maintenance":
					caOptions[2].options.push(option);
			}
		});
	}

	if (control) {
		return (
			<Card className="p-3 mb-4 form-group">
				<Row>
					<div className="form-group-label">
						{t("otherExpensesForm.expense.label")} #{index + 1}
					</div>
					<Col>
						<Row>
							<Col className="text-end">
								<Button
									variant="link"
									className="all-caps-link ms-auto"
									onClick={() => deleteExpense(index)}
								>
									<FontAwesomeIcon
										icon="xmark"
										className="d-inline-block me-2"
									/>
									{global("remove")}
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row>
					<Col xs={12} sm={12} lg={4}>
						{country === "CA" ? (
							<GroupedSelect
								firstOption={selectedExpenseType || global("select")}
								groupedOptions={caOptions}
								control={control}
								name={`${name}.subCategory`}
								label={t("otherExpensesForm.expenseType.label")}
								rules={{
									required: t("otherExpensesForm.expenseType.rules.required"),
									validate: {
										typeAlreadyExists: checkIfTypeExists,
									},
								}}
							/>
						) : (
							<Select
								firstOption={selectedExpenseType || global("select")}
								options={options}
								control={control}
								name={`${name}.subCategory`}
								label={t("otherExpensesForm.expenseType.label")}
								rules={{
									required: t("otherExpensesForm.expenseType.rules.required"),
									validate: {
										typeAlreadyExists: checkIfTypeExists,
									},
								}}
							/>
						)}
					</Col>
					<Col xs={12} sm={12} lg={4}>
						<Form.Group>
							<TextFieldGroup
								label={t("otherExpensesForm.amount.label")}
								control={control}
								prefix="$"
								name={`${name}.amount`}
								type="number"
								step="any"
								rules={{
									pattern: {
										value: CURRENCY_REGEX,
										message: global("rules.dollarAmount"),
									},
									max: {
										value: maxAmount.amount,
										message: global(maxAmount.message, {
											amount: maxAmount.amount,
										}),
									},
									min: {
										value: 0,
										message: global("rules.min"),
									},
									required: t("otherExpensesForm.amount.rules.required"),
								}}
							/>
						</Form.Group>
					</Col>
					<Col xs={12} sm={12} lg={4}>
						<Select
							options={getFrequencyOptions()}
							control={control}
							name={`${name}.frequency`}
							label={t("otherExpensesForm.frequency.label")}
							rules={{
								required: t("otherExpensesForm.frequency.rules.required"),
							}}
						/>
					</Col>
				</Row>
			</Card>
		);
	} else {
		return null;
	}
}

export default OtherExpensesForm;
