import { Nav, Navbar, NavDropdown, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LanguageSelector from "../../LanguageSelector/LanguageSelector";
import { useSession } from "../../Context/SessionProvider";
import { REQUEST_CALLBACK_PATH } from "../../../utils/routes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useChatbot } from "../../Context/ChatbotProvider";
import { useState } from "react";
import { useUrlParams } from "../../Context";
import { useStatus } from "../../Context/StatusProvider";
import { Logo } from "../Logo";

export default function Header() {
	const { t } = useTranslation("global");
	const [expanded, setExpanded] = useState(false);
	const navigate = useNavigate();
	const { isFormEditable } = useSession();
	const { leadQueryStr } = useUrlParams();
	const { openChatbot, displayChatbot } = useChatbot();
	const { isAbandoned, isLocked } = useStatus();
	const accessDenied = !isFormEditable() || isAbandoned || isLocked;

	const toggleMenu = (controls: boolean) => {
		setExpanded(controls);
	};

	return (
		<Navbar bg="light" expand="lg" expanded={expanded} onToggle={toggleMenu}>
			<Container>
				<Logo />
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="ms-auto">
						<NavDropdown
							title={
								<div className="d-inline text-uppercase">
									<FontAwesomeIcon icon="globe" className="me-2" />
									{t("header.language")}
								</div>
							}
							className="me-3"
							id="basic-nav-dropdown"
						>
							<LanguageSelector toggleMenu={toggleMenu} />
						</NavDropdown>
						{!accessDenied && (
							<Nav.Link
								onClick={() => {
									navigate(`${REQUEST_CALLBACK_PATH}${leadQueryStr}`);
									toggleMenu(false);
								}}
								className="me-3 text-uppercase"
							>
								<FontAwesomeIcon icon="phone" className="me-2" />
								{t("header.requestCallback")}
							</Nav.Link>
						)}
						{displayChatbot && (
							<Nav.Link
								onClick={() => {
									openChatbot();
									toggleMenu(false);
								}}
								className="text-uppercase chatbot-open"
							>
								<FontAwesomeIcon icon="headset" className="me-2" />
								{t("header.chat")}
							</Nav.Link>
						)}
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}
