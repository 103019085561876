import { v4 as uuidv4 } from "uuid";

import { useSession } from "../../components/Context/SessionProvider";
import { unescapeApostrophes } from "../../utils/stringUtils";
import type {
	BudgetElement,
	FormExpenses,
	Frequencies,
	SubCategories,
} from "../Types";
import useSessionData from "../useSessionData/useSessionData";
import { divideCurrency, getYearlyAmount } from "../currencyUtils";
import { config } from "../countryConfig";

interface formatBudgetElement {
	subCategory: SubCategories;
	category?: string;
	type?: "Variable" | "Fixed";
	frequency?: Frequencies;
	amount?: number | string;
}

export default function useBudgetHelpers() {
	const {
		session: { vic: { currentBudget: { aggregates = {} } = {} } = {} },
	} = useSession();
	const { applicant_expenses, applicant_income } = useSessionData();
	const incomeAndExpenses = [
		...(applicant_income || []),
		...(applicant_expenses || []),
	];

	const getUnexcapedSubCategory = (subCategory: SubCategories) => {
		return unescapeApostrophes(subCategory) as SubCategories;
	};

	const formatBudgetElement = ({
		subCategory,
		category,
		type = "Variable",
		frequency = 12,
		amount,
	}: formatBudgetElement): BudgetElement => {
		const unescapedSubCategory = getUnexcapedSubCategory(subCategory);
		return {
			name: getName(unescapedSubCategory) || uuidv4(),
			category: category || getExpenseCategory(unescapedSubCategory),
			subCategory: unescapedSubCategory,
			frequency,
			amount: amount ? +amount : 0,
			type,
		};
	};

	const getName = (subCategory?: SubCategories) => {
		if (subCategory) {
			const unescapedSubCategory = getUnexcapedSubCategory(subCategory);
			const match = incomeAndExpenses?.find(
				(expense) => expense.subCategory === unescapedSubCategory
			);
			return match?.name;
		}
	};

	const getBudgetElement = (subCategory?: SubCategories) => {
		const unescapedSubCategory = getUnexcapedSubCategory(subCategory || "");
		const match = applicant_expenses?.find(
			(expense) => expense.subCategory === unescapedSubCategory
		);
		return match;
	};

	// TODO: no need to export this function
	const getExpenseCategory = (subCategory?: SubCategories) => {
		if (subCategory) {
			const expenses = config.expenses;
			const unescapedSubCategory = getUnexcapedSubCategory(subCategory);

			for (const cat in expenses) {
				if (
					expenses[cat].fields.find(
						(field) => field.value === unescapedSubCategory
					)
				) {
					return expenses[cat].categoryValue;
				}
			}
		}
		return "";
	};

	const getRemaining = () => {
		if (aggregates?.totalMonthlyIncome && aggregates?.totalMonthlyExpenses) {
			return aggregates?.totalMonthlyIncome - aggregates?.totalMonthlyExpenses;
		} else return 0;
	};

	const generateDefaultValues = <Type extends FormExpenses>(
		subCategories: Array<keyof Type>
	) => {
		return subCategories.reduce(
			(obj, cat) => {
				if (cat) {
					const match = applicant_expenses?.find(
						(expense) => expense.subCategory === cat
					);
					obj[cat] = match?.amount ? `${match.amount}` : "";
					return obj;
				}
				return obj;
			},
			{} as Record<keyof Type, string | undefined>
		);
	};

	const getValue = (subCategory: SubCategories, convertToMonthly = false) => {
		const unescapedSubCategory = getUnexcapedSubCategory(subCategory);
		const match = incomeAndExpenses?.find(
			(expense) => expense.subCategory === unescapedSubCategory
		);

		if (convertToMonthly && match?.amount && match?.frequency) {
			return `${getMonthlyAmount(+match.amount, match.frequency)}`;
		}

		return match?.amount ? `${match.amount}` : "";
	};

	const getMonthlyAmount = (amount: number, frequency: Frequencies) => {
		return divideCurrency(`${getYearlyAmount(amount, frequency)}`, 12);
	};

	const addBudgetElements = (newElements: BudgetElement[]) => {
		const map = new Map(
			[...newElements, ...(applicant_expenses || []), ...newElements].map(
				(pos) => [pos.name, pos]
			)
		);
		const combinedElements = Array.from(map.values());
		return combinedElements;
	};

	return {
		formatBudgetElement,
		getRemaining,
		getName,
		getValue,
		getBudgetElement,
		generateDefaultValues,
		getExpenseCategory,
		getMonthlyAmount,
		addBudgetElements,
	};
}
