import { createContext, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { overview, income, expenses, complete } from "../../utils/routes";
import {
	StepLabel,
	SessionParams,
	ChatMessage,
	SkippableRoutes,
} from "../../utils/Types";

interface Values {
	session: SessionParams;
	setSession:
		| React.Dispatch<React.SetStateAction<{ [key: string]: any }>>
		| (() => void);
	error: boolean;
	setError: React.Dispatch<React.SetStateAction<boolean>> | (() => void);
	loading: boolean;
	setLoading: React.Dispatch<React.SetStateAction<boolean>> | (() => void);
	initializing: boolean;
	setInitializing: React.Dispatch<React.SetStateAction<boolean>> | (() => void);
	isConsentedUser: boolean;
	setIsConsentedUser:
		| React.Dispatch<React.SetStateAction<boolean>>
		| (() => void);
	chatMessages: ChatMessage[];
	setChatMessages?: any;
	chatLoading: boolean;
	setChatLoading?: any;
	activeStep: StepLabel;
	maxStep: number; // the furthest step the user has gone in the session
	isFormEditable: () => boolean | undefined;
	routesToSkip: SkippableRoutes;
	setRoutesToSkip:
		| React.Dispatch<React.SetStateAction<SkippableRoutes>>
		| (() => void);
	consentNeeded: boolean | undefined;
}

export const initialSessionValues: Values = {
	session: {},
	setSession: () => undefined,
	error: false,
	setError: () => undefined,
	loading: false,
	setLoading: () => undefined,
	initializing: true,
	setInitializing: () => undefined,
	isConsentedUser: false,
	setIsConsentedUser: () => undefined,
	chatMessages: [],
	chatLoading: false,
	activeStep: "overview",
	maxStep: 0,
	isFormEditable: () => undefined,
	routesToSkip: {},
	setRoutesToSkip: () => undefined,
	consentNeeded: undefined,
};

export const SessionContext = createContext<Values>(initialSessionValues);

export default function SessionProvider({ children }: any) {
	const [loading, setLoading] = useState(false);
	const [initializing, setInitializing] = useState(true);
	const [chatLoading, setChatLoading] = useState(false);
	const [isConsentedUser, setIsConsentedUser] = useState(false);
	const [session, setSession] = useState<SessionParams>({});
	const [chatMessages, setChatMessages] = useState([]);
	const [error, setError] = useState(false);
	const { pathname } = useLocation();
	const [routesToSkip, setRoutesToSkip] = useState<SkippableRoutes>({
		applicant: false,
		debtAmount: false,
		paymentStatus: false,
	});

	// stepper logic
	let activeStep: StepLabel = "overview";

	const [maxStep, setMaxStep] = useState(0);
	if (overview.includes(pathname)) {
		activeStep = "overview";
	} else if (income.includes(pathname)) {
		activeStep = "income";
		if (maxStep < 1) setMaxStep(maxStep + 1);
	} else if (expenses.includes(pathname)) {
		activeStep = "expenses";
		if (maxStep < 2) setMaxStep(maxStep + 1);
	} else if (complete.includes(pathname)) {
		activeStep = "complete";
		if (maxStep < 3) setMaxStep(maxStep + 1);
	}

	const isFormEditable = () => {
		const percentComplete =
			session?.vic?.consent?.ventureTech?.attributes?.percentComplete;
		const allowFormEdit =
			session?.vic?.consent?.ventureTech?.attributes?.VTResponse?.AllowFormEdit;
		const formStatus =
			session?.vic?.consent?.ventureTech?.attributes?.formStatus;
		const finalized = session?.vic?.consent?.ventureTech?.attributes?.finalized;
		const badStatus = formStatus === "Abandoned" || formStatus === "Locked";

		return percentComplete != 100 && allowFormEdit && !finalized && !badStatus;
	};

	const consentNeeded =
		session?.vic?.consent?.ventureTech?.attributes?.VTResponse?.ConsentNeeded;

	const values: Values = {
		session: session || {},
		setSession,
		error,
		setError,
		chatMessages,
		setChatMessages,
		loading,
		setLoading,
		isConsentedUser,
		setIsConsentedUser,
		initializing,
		setInitializing,
		chatLoading,
		setChatLoading,
		activeStep,
		maxStep,
		isFormEditable,
		routesToSkip,
		setRoutesToSkip,
		consentNeeded,
	};

	return (
		<SessionContext.Provider value={values}>{children}</SessionContext.Provider>
	);
}

export const useSession = () => useContext(SessionContext);
