import { Button, ButtonGroup as Group, Form } from "react-bootstrap";
import { useController } from "react-hook-form";
import InvalidFeedback from "../InvalidFeedback/InvalidFeedback";

interface ButtonGroupProps {
	label?: string;
	options: {
		label: string;
		value: number;
	}[];
	className?: string;
	control: any;
	name: string;
	rules?: any;
	required?: boolean;
}

export default function ButtonGroup({
	label,
	options,
	className,
	name,
	rules,
	control,
	required,
}: ButtonGroupProps) {
	const {
		field: { onChange, value },
		fieldState: { error },
	} = useController({ name, control, rules });

	return (
		<Form.Group className={className}>
			<Form.Label className="d-block">
				{label} {required && <span className="text-danger">*</span>}
			</Form.Label>
			<Group aria-label={name} className="w-100">
				{options.map((option) => (
					<Button
						variant={value === option.value ? "primary" : "outline-dark"}
						key={option.value}
						value={option.value}
						onClick={() => onChange(option.value)}
					>
						{option.label}
					</Button>
				))}
			</Group>
			<InvalidFeedback>{error?.message}</InvalidFeedback>
		</Form.Group>
	);
}
