export const preventNonNumberInput = (
	event: React.KeyboardEvent<any>,
	type: "num" | "float"
) => {
	const regex = type === "float" ? /[0-9.]/ : /\d+/;

	const allowedKeys = ["Backspace", "Delete", "Enter", "Tab"];

	if (allowedKeys.includes(event.key)) {
		return;
	}

	if (!regex.test(event.key)) {
		event.preventDefault();
	}
};
