import { Form } from "react-bootstrap";
import { useController } from "react-hook-form";
import InvalidFeedback from "../InvalidFeedback/InvalidFeedback";

interface TextAreaProps {
	label?: string;
	name: string;
	control: any;
	rules: any;
	required?: boolean;
}

export default function TextArea({
	label,
	control,
	name,
	rules,
	required,
}: TextAreaProps) {
	const {
		field,
		fieldState: { error },
	} = useController({ name, control, rules, defaultValue: "" });
	return (
		<Form.Group>
			<Form.Label>
				{label} {required && <span className="text-danger">*</span>}
			</Form.Label>
			<Form.Control as="textarea" rows={3} isInvalid={!!error} {...field} />
			<InvalidFeedback>{error?.message}</InvalidFeedback>
		</Form.Group>
	);
}
