import { StatusParams } from "../Types";

export const getFinalizedStatus = ({
	percentComplete,
	lastPageCollected,
	applicant_finalized,
}: {
	percentComplete: number;
	lastPageCollected: string;
	applicant_finalized: StatusParams["applicant_finalized"];
}) => {
	if (
		percentComplete === 100 ||
		applicant_finalized ||
		lastPageCollected === "Request Callback"
	) {
		return true;
	}
	return false;
};
