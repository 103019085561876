interface InvalidFeedbackProps {
	className?: string;
	children?: JSX.Element | string;
}
export default function InvalidFeedback({
	className,
	children,
}: InvalidFeedbackProps) {
	return (
		<div
			className={`invalid-feedback d-block ${className}`}
			data-testid="invalid-feedback"
		>
			{children}
		</div>
	);
}
