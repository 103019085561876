import { useTranslation } from "react-i18next";
import NavDropdown from "react-bootstrap/NavDropdown";
import { config } from "../../utils/countryConfig";

function LanguageSelector({
	toggleMenu,
}: {
	toggleMenu: (control: boolean) => void;
}) {
	const { i18n } = useTranslation();

	const fontWeight = (language: string) => {
		return i18n.language === language ? "bold" : "normal";
	};

	return (
		<div className="container-max-lg mx-auto">
			{Object.keys(config.languages).map((lng) => (
				<NavDropdown.Item
					key={lng}
					type="submit"
					style={{ fontWeight: fontWeight(lng) }}
					onClick={() => {
						i18n.changeLanguage(lng);
						toggleMenu(false);
					}}
				>
					{config.languages[lng as keyof typeof config.languages].nativeName}
				</NavDropdown.Item>
			))}
		</div>
	);
}

export default LanguageSelector;
