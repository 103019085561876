import { useContext, createContext, ReactNode } from "react";
import useIntentHelpers from "../../utils/usePostIntent/usePostIntent";
import { useSearchParams } from "react-router-dom";

interface Values {
	leadId: string | null;
	channel: string | null;
	leadQueryStr: string | null;
	channelQueryStr: string | null;
}

const initialValues: Values = {
	leadId: "",
	channel: "",
	leadQueryStr: "",
	channelQueryStr: "",
};

/**
 * Context to provide access to URL parameters.
 */
const UrlParamsContext = createContext(initialValues);

/**
 * Convert parameter names to lowercase.
 */
const convertToLowercase = (params: URLSearchParams) => {
	const lowercaseParams = new URLSearchParams();
	params.forEach((value, key) => lowercaseParams.set(key.toLowerCase(), value));
	return lowercaseParams;
};

/**
 * Context provider that provides the LeadID / ID and channel parameters.
 */
export function UrlParamsProvider({ children }: { children: ReactNode }) {
	const { redirect } = useIntentHelpers();
	const [searchParams] = useSearchParams();
	const lowercasedSearchParams = convertToLowercase(searchParams);

	const LeadGUIDParam = lowercasedSearchParams.get("leadguid");
	const IDParam = lowercasedSearchParams.get("id");
	const channel = lowercasedSearchParams.get("channel");

	const leadId = LeadGUIDParam || IDParam;

	if (!leadId) {
		console.error("No lead ID found.");
		redirect();
	}

	const leadParamType = LeadGUIDParam ? "LeadGUID" : IDParam ? "ID" : "";
	const leadQueryStr = `?${leadParamType}=${leadId}`;
	const channelQueryStr = channel ? `&channel=${channel}` : "";

	const values = {
		leadId,
		leadQueryStr,
		channel,
		channelQueryStr,
	};

	return (
		<UrlParamsContext.Provider value={values}>
			{children}
		</UrlParamsContext.Provider>
	);
}

/**
 * Custom hook to consume the UrlParamsContext values.
 */
export const useUrlParams = () => useContext(UrlParamsContext);
