import { useEffect, useState } from "react";

import { Button, Form } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { INCOME_PROVIDED_INTENT } from "../../../../utils/intents";
import type {
	BudgetElement,
	Sources,
	SourceOptions,
	FrequencyOptions,
	Frequencies,
} from "../../../../utils/Types";
import useNavigateHelpers from "../../../../utils/useNavigateHelpers/useNavigateHelpers";
import useIntentHelpers from "../../../../utils/usePostIntent/usePostIntent";
import FormNavButtons from "../../../Common/FormNavButtons/FormNavButtons";
import FormTitle from "../../../Common/FormTitle/FormTitle";
import IncomeForm from "./IncomeForm";
import useSessionData from "../../../../utils/useSessionData/useSessionData";
import useBudgetHelpers from "../../../../utils/useBudgetHelpers/useBudgetHelpers";
import { config } from "../../../../utils/countryConfig";
import { getCountry } from "../../../../utils/getCountry";

const FIELD_NAME = "Applicant Income";

export function getSourceOptions(): SourceOptions[] {
	const { t: income } = useTranslation("income");
	let ctr = 0;
	const usOptions = [
		"primaryIncome",
		"secondaryIncome",
		"childSupportAlimony",
		"socialSecurity",
		"pension",
		"disability",
		"unemployment",
		"rentalIncome",
		"other",
	];

	const caOptions = [
		"primaryIncome",
		"secondaryIncome",
		"childTaxBenefit",
		"govtPension",
		"privatePension",
		"publicAssistance",
		"childSupportAlimony",
		"rentalIncome",
		"other",
	];

	function capitalizeFirstWord(sentence: string): string {
		return sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();
	}

	function translateSourceOptions(options: string[]): SourceOptions[] {
		const translatedSources = config.incomeSources.map((source) => {
			const label = income(`incomeForm.sourceOptions.${options[ctr++]}`);
			source.label = capitalizeFirstWord(label);
			return source;
		});
		return translatedSources as SourceOptions[];
	}

	if (getCountry() == "US") {
		const translatedSources = translateSourceOptions(usOptions);
		return translatedSources as SourceOptions[];
	} else {
		const translatedSources = translateSourceOptions(caOptions);
		return translatedSources as SourceOptions[];
	}
}

export function getFrequencyOptions(): FrequencyOptions[] {
	const { t: global } = useTranslation("global");

	return [
		{
			label: global("frequencyOptions.weekly"),
			value: 52,
		},
		{
			label: global("frequencyOptions.biweekly"),
			value: 26,
		},
		{
			label: global("frequencyOptions.twiceAMonth"),
			value: 24,
		},
		{
			label: global("frequencyOptions.monthly"),
			value: 12,
		},
	];
}

function Income() {
	const { t } = useTranslation("income");
	const sourceOptions = getSourceOptions();

	const initialValues: BudgetElement = {
		name: uuidv4(),
		category: "Income",
		subCategory: "",
		frequency: 12 as const,
		amount: "",
		type: "Fixed",
	};

	const { applicant_income } = useSessionData();

	const incomeSources = applicant_income?.filter(
		(expense) => expense.amount !== 0
	);

	const defaultValues =
		Array.isArray(incomeSources) && incomeSources.length
			? incomeSources
			: [initialValues];

	const {
		control,
		handleSubmit,
		formState: { isDirty },
		watch,
		trigger,
	} = useForm<{
		[FIELD_NAME]: BudgetElement[];
	}>({
		defaultValues: {
			[FIELD_NAME]: defaultValues,
		},
		mode: "onChange",
	});

	const { fields, append, remove } = useFieldArray<{
		[FIELD_NAME]: BudgetElement[];
	}>({
		control,
		name: FIELD_NAME,
	});

	const [deletedItems, setDeletedItems] = useState<BudgetElement[]>([]);
	const [selectedOptions, setSelectedOptions] = useState<Sources[]>([]);
	const [options, setOptions] = useState(sourceOptions);
	const formData = watch(FIELD_NAME);

	// saves all selected Source Types in local state
	watch((data) => {
		const incomeSources = data[FIELD_NAME];
		if (selectedOptions.length !== incomeSources?.length) {
			const sources = incomeSources?.map(
				(income) => income?.subCategory
			) as Sources[];
			setSelectedOptions(sources);
		}
	});

	// uses selected Source Types to filter the dropdown options
	useEffect(() => {
		const filteredOptions = sourceOptions.filter(
			(opt) => !selectedOptions.includes(opt.value)
		);
		setOptions(filteredOptions);
	}, [selectedOptions]);

	const deleteItem = (index: number) => {
		setDeletedItems([...deletedItems, formData[index]]);
		console.log("deleted items set to ", [...deletedItems, formData[index]]);
		remove(index);
	};

	const { postStatus, postIntent } = useIntentHelpers();
	const { getName } = useBudgetHelpers();

	const { navigateNextPrompt } = useNavigateHelpers();
	const submitForm = (data: { [FIELD_NAME]: BudgetElement[] }) => {
		const formData = data[FIELD_NAME];

		if (isDirty) {
			const oldData = applicant_income;

			oldData?.forEach((income) => {
				if (
					!formData.find((el) => {
						el.subCategory === income.subCategory;
					})
				) {
					income.amount = 0;
				}
			});

			// any new items should match their names with any items with the same subcategory
			formData?.forEach((income) => {
				income.name = getName(income.subCategory) || uuidv4();
				income.frequency = income.frequency
					? (+income.frequency as Frequencies)
					: undefined;
				income.amount = income.amount ? +income.amount : undefined;
			});

			const map = new Map(
				[...(oldData || []), ...formData].map((pos) => [pos.name, pos])
			);

			const mappedElements = Array.from(map.values());

			postIntent({
				parameters: { applicant_income: mappedElements },
				text: INCOME_PROVIDED_INTENT,
			}).then(() => {
				postStatus();
				navigateNextPrompt();
			});
		} else {
			postStatus();
			navigateNextPrompt();
		}
	};

	const reValidate = (field: any) => {
		if (isDirty) {
			trigger(field);
		}
	};

	return (
		<>
			<FormTitle>{t("incomeSources.formTitle")}</FormTitle>
			<Form onSubmit={handleSubmit(submitForm)} noValidate>
				{fields.map((field, index) => (
					<IncomeForm
						control={control}
						name={`${[FIELD_NAME]}.${index}`}
						key={field.id}
						index={index}
						deleteItem={deleteItem}
						selectedSourceType={
							sourceOptions.find((opt) => opt.value === selectedOptions[index])
								?.label
						}
						options={options}
						income={formData}
						reValidate={reValidate}
					/>
				))}
				{fields.length < sourceOptions.length ? (
					<Button
						variant="link"
						className="all-caps-link d-block ms-auto"
						onClick={() => append(initialValues)}
					>
						<FontAwesomeIcon icon="plus" className="d-inline-block me-2" />
						{t("incomeSources.addAnotherIncomeSource")}
					</Button>
				) : (
					""
				)}
				<FormNavButtons />
			</Form>
		</>
	);
}

export default Income;
