import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useSessionData from "../../../utils/useSessionData/useSessionData";
import usePostIntent from "../../../utils/usePostIntent/usePostIntent";
import { useUrlParams } from "../../Context";

export default function NoReEnter() {
	const { t } = useTranslation("noReEntry");
	const { leadId } = useUrlParams();
	const { applicant_form_status } = useSessionData();
	const { postStatus } = usePostIntent();

	useEffect(() => {
		if (applicant_form_status === "Abandoned" && leadId) {
			postStatus({ setToLocked: true });
		}
	}, [applicant_form_status, leadId]);

	return (
		<div className="m-4 mx-auto container-max-sm">
			<p>{t("description")}</p>
			<p>{t("description2")}</p>
		</div>
	);
}
