const normalizeCurrency = (
	amount: string | number | undefined,
	returnZero = false
) => {
	if (amount) {
		const formatter = new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
			maximumFractionDigits: 0,
		});

		return formatter.format(+amount);
	}

	if (returnZero) {
		return "$0";
	}
};

export default normalizeCurrency;
