import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import type { ChartData, ChartOptions } from "chart.js";
import normalize from "../../../../utils/normalizeCurrency/normalizeCurrency";
import { useTranslation } from "react-i18next";
import { useSession } from "../../../Context/SessionProvider";
import { forwardRef } from "react";

interface Data {
	label: string;
	value: number;
	backgroundColor: string;
}

const DoughnutChart = forwardRef(function Chart(
	{ render = true }: { render?: boolean },
	ref: React.ForwardedRef<any>
) {
	const { t } = useTranslation("expensesFieldLabels");
	const {
		session: {
			vic: {
				currentBudget: {
					aggregates: {
						totalMonthlyHousingExpenses = 0,
						totalMonthlyFoodExpenses = 0,
						totalMonthlyUtilitiesExpenses = 0,
						totalMonthlyChildrenExpenses = 0,
						totalMonthlyTransportationExpenses = 0,
						totalMonthlyMedicalExpenses = 0,
						totalMonthlyInsuranceExpenses = 0,
						totalMonthlyPersonalExpenses = 0,
					} = {},
				} = {},
			} = {},
		},
	} = useSession();

	const data: Data[] = [
		{
			label: t("categories.housing"),
			value: totalMonthlyHousingExpenses,
			backgroundColor: "#A6CEE3",
		},
		{
			label: t("categories.food"),
			value: totalMonthlyFoodExpenses,
			backgroundColor: "#1F78B4",
		},
		{
			label: t("categories.utilities"),
			value: totalMonthlyUtilitiesExpenses,
			backgroundColor: "#FF7F50",
		},
		{
			label: t("categories.children"),
			value: totalMonthlyChildrenExpenses,
			backgroundColor: "#FBB138",
		},
		{
			label: t("categories.transportation"),
			value: totalMonthlyTransportationExpenses,
			backgroundColor: "#85BC68",
		},
		{
			label: t("categories.personal"),
			value: totalMonthlyPersonalExpenses,
			backgroundColor: "#FDAC98",
		},
		{
			label: t("categories.medical"),
			value: totalMonthlyMedicalExpenses,
			backgroundColor: "#906E98",
		},
		{
			label: t("categories.insurance"),
			value: totalMonthlyInsuranceExpenses,
			backgroundColor: "#088DA5",
		},
	];

	const chartData: ChartData<"doughnut"> = {
		labels: data.map(({ label }) => label),
		datasets: [
			{
				data: data.map(({ value }) => value),
				backgroundColor: data.map(({ backgroundColor }) => backgroundColor),
				hoverOffset: 5,
			},
		],
	};

	const options: ChartOptions<"doughnut"> = {
		animation: false,
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				callbacks: {
					label: function (context) {
						let label = context.label || "";
						if (label) label += ": ";
						label += normalize(context.parsed, true);
						return label;
					},
				},
			},
		},
	};

	return (
		<Doughnut
			data={chartData}
			className={`${render ? "ChartDisplay" : "d-none"}`}
			options={options}
			ref={ref}
		/>
	);
});

export default DoughnutChart;
