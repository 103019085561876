/**

escapeApostrophes function replaces all apostrophes in the input string
with "\_apostrophe\_"
@param {string | undefined} value - input string
@returns {string} - string with apostrophes replaced
*/
export function escapeApostrophes(
	value: string | undefined,
	replaceValue = "_apostrophe_"
): string {
	if (typeof value !== "string") {
		return "";
	}

	return value.replace(/'/g, replaceValue);
}

/**

unescapeApostrophes function replaces all "\_apostrophe\_" in the input string
with apostrophes
@param {string | undefined} value - input string
@returns {string} - string with "\_apostrophe\_" replaced
*/
export function unescapeApostrophes(
	value: string | undefined,
	searchValue = "_apostrophe_"
): string {
	if (typeof value !== "string") {
		return "";
	}

	return value.replace(new RegExp(searchValue, "g"), "'");
}
