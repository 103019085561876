import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
	CableInternetPhoneParams,
	UtilitiesParams,
	FoodParams,
	VehicleParams,
	TransportationParams,
	HealthParams,
	ChildCareParams,
	OtherExpensesParams,
	MortgageParams,
	RentParams,
} from "./Types";

interface Expenses {
	[key: string]: {
		icon: IconProp;
		category: string;
		categoryValue: string;
		fields: {
			label: string;
			maxAmount?: number;
			value:
				| keyof CableInternetPhoneParams
				| keyof UtilitiesParams
				| keyof FoodParams
				| keyof MortgageParams
				| keyof RentParams
				| keyof VehicleParams
				| keyof TransportationParams
				| keyof HealthParams
				| keyof ChildCareParams
				| keyof OtherExpensesParams;
		}[];
	};
}

export const USExpenses: Expenses = {
	["Internet & Cable TV"]: {
		icon: ["fas", "wifi"],
		category: "expenses.internetAndCable.title",
		categoryValue: "Utilities",
		fields: [
			{
				label: "expenses.internetAndCable.fields.bundle",
				value: "Bundle TV/Cable/Internet",
			},
			{
				label: "expenses.internetAndCable.fields.internet",
				value: "Internet",
			},
			{
				label: "expenses.internetAndCable.fields.cable",
				value: "Cable",
			},
			{
				label: "expenses.internetAndCable.fields.cellular",
				value: "Cellular",
			},
			{
				label: "expenses.internetAndCable.fields.landline",
				value: "Phone",
			},
		],
	},
	["Utilities"]: {
		icon: ["fas", "plug-circle-bolt"],
		category: "expenses.utilities.title",
		categoryValue: "Utilities",
		fields: [
			{
				label: "expenses.utilities.fields.electricity",
				value: "Electricity",
			},
			{
				label: "expenses.utilities.fields.water",
				value: "Water",
			},
			{
				label: "expenses.utilities.fields.heat",
				value: "Gas/Oil/Heat",
			},
		],
	},
	["Food"]: {
		icon: ["fas", "burger-fries"],
		category: "expenses.food.title",
		categoryValue: "Food",
		fields: [
			{
				label: "expenses.food.fields.grocery",
				value: "Grocery",
			},
			{
				label: "expenses.food.fields.restaurant",
				value: "Lunches/Eating Out",
			},
		],
	},
	["Housing"]: {
		icon: ["fas", "house"],
		category: "expenses.housing.title",
		categoryValue: "Housing",
		fields: [
			{
				label: "expenses.housing.fields.rentMonthlyPayment",
				value: "Rent",
				maxAmount: 100000,
			},
			{
				label: "expenses.housing.fields.rentersInsurance",
				value: "Renter's Insurance",
				maxAmount: 100000,
			},
			{
				label: "expenses.housing.fields.firstMortgage",
				value: "First Mortgage",
				maxAmount: 100000,
			},
			{
				label: "expenses.housing.fields.secondMortgage",
				value: "Second Mortgage",
				maxAmount: 100000,
			},
			{
				label: "expenses.housing.fields.taxes",
				value: "Real Estate Taxes",
				maxAmount: 100000,
			},
			{
				label: "expenses.housing.fields.taxesAndInsurance",
				value: "Home Owners Insurance",
				maxAmount: 100000,
			},
			{
				label: "expenses.housing.fields.hoa",
				value: "Association Fees",
				maxAmount: 100000,
			},
			{
				label: "expenses.housing.fields.equityLoan",
				value: "Home Equity Loan",
				maxAmount: 100000,
			},
		],
	},
	["Car"]: {
		icon: ["fas", "car"],
		category: "expenses.car.title",
		categoryValue: "Transportation",
		fields: [
			{
				label: "expenses.car.fields.autoPayment1",
				value: "Auto/Boat/Bike Payment 1",
			},
			{
				label: "expenses.car.fields.autoPayment2",
				value: "Auto/Boat/Bike Payment 2",
			},
			{
				label: "expenses.car.fields.autoPayment3",
				value: "Auto/Boat/Bike Payment 3",
			},
			{
				label: "expenses.car.fields.gas",
				value: "Gas",
			},
			{
				label: "expenses.car.fields.carInsurance",
				value: "Car Insurance",
			},
			{
				label: "expenses.car.fields.parkingTolls",
				value: "Parking/Tolls",
			},
			{
				label: "expenses.car.fields.maintenance",
				value: "Repairs/Maintenance",
			},
		],
	},
	["Public Transportation"]: {
		icon: ["fas", "bus"],
		category: "expenses.publicTransportation.title",
		categoryValue: "Transportation",
		fields: [
			{
				label: "expenses.publicTransportation.fields.bus",
				value: "Bus Fare",
			},
			{
				label: "expenses.publicTransportation.fields.otherFares",
				value: "Other Transportation Fees",
			},
		],
	},
	["Medical"]: {
		icon: ["fas", "briefcase-medical"],
		category: "expenses.health.title",
		categoryValue: "Medical",
		fields: [
			{
				label: "expenses.health.fields.doctorBills",
				value: "Doctor Bills",
				maxAmount: 100000,
			},
			{
				label: "expenses.health.fields.medications",
				value: "Prescription/Medication",
				maxAmount: 100000,
			},
		],
	},
	["Insurance"]: {
		icon: ["fas", "shield-alt"],
		category: "expenses.insurance.title",
		categoryValue: "Insurance",
		fields: [
			{
				label: "expenses.health.fields.healthInsurance",
				value: "Health Insurance",
				maxAmount: 100000,
			},
			{
				label: "expenses.health.fields.lifeInsurance",
				value: "Life Insurance",
				maxAmount: 100000,
			},
		],
	},
	["Children"]: {
		icon: ["fas", "family"],
		category: "expenses.childCare.title",
		categoryValue: "Children",
		fields: [
			{
				label: "expenses.childCare.fields.childCare",
				value: "Child Care",
			},
			{
				label: "expenses.childCare.fields.childSupport",
				value: "Child Support/Alimony",
			},
		],
	},
	["Other Expenses"]: {
		icon: ["fas", "shirt"],
		category: "expenses.otherExpenses.title",
		categoryValue: "Personal",
		fields: [
			{
				label: "expenses.otherExpenses.fields.clothing",
				value: "Clothing",
			},
			{
				label: "expenses.otherExpenses.fields.hairCare",
				value: "Hair Care/Nails/Barber",
			},
			{
				label: "expenses.otherExpenses.fields.pets",
				value: "Pets",
			},
			{
				label: "expenses.otherExpenses.fields.cigarettes",
				value: "Cigarettes",
			},
			{
				label: "expenses.otherExpenses.fields.educational",
				value: "Educational/Professional",
			},
			{
				label: "expenses.otherExpenses.fields.church",
				value: "Church/Tithe/Charity",
			},
			{
				label: "expenses.otherExpenses.fields.cleaning",
				value: "Cleaning/Laundry",
			},
			{
				label: "expenses.otherExpenses.fields.health",
				value: "Health Club Membership",
			},
			{
				label: "expenses.otherExpenses.fields.studentLoan",
				value: "Student Loan",
			},
			{
				label: "expenses.otherExpenses.fields.creditCard",
				value: "Total Recurring Credit Card Payments",
			},
			{
				label: "expenses.otherExpenses.fields.homeRepairs",
				value: "Home Repairs/Maintenance",
			},
			{
				label: "expenses.otherExpenses.fields.personalLoan",
				value: "Total Recurring Personal Loan Payments",
			},
			{
				label: "expenses.otherExpenses.fields.miscellaneous",
				value: "Miscellaneous",
			},
			{
				label: "expenses.otherExpenses.fields.savings",
				value: "Savings",
			},
		],
	},
};

export const CAExpenses: Expenses = {
	...USExpenses,
	["Other Expenses"]: {
		icon: ["fas", "shirt"],
		category: "expenses.otherExpenses.title",
		categoryValue: "Personal",
		fields: [
			{
				label: "expenses.otherExpenses.fields.creditCard",
				value: "Total Recurring Credit Card Payments",
			},
			{
				label: "expenses.otherExpenses.fields.studentLoan",
				value: "Student Loan",
			},
			{
				label: "expenses.otherExpenses.fields.otherLoan",
				value: "Other Loan",
			},
			{
				label: "expenses.otherExpenses.fields.craTaxPayments",
				value: "CRA/Tax Payments",
			},
			{
				label: "expenses.otherExpenses.fields.savings",
				value: "Savings",
			},
			{
				label: "expenses.otherExpenses.fields.clothing",
				value: "Clothing",
			},
			{
				label: "expenses.otherExpenses.fields.cleaning",
				value: "Cleaning/Laundry",
			},
			{
				label: "expenses.otherExpenses.fields.gym",
				value: "Health Club Membership",
			},
			{
				label: "expenses.otherExpenses.fields.hairCare",
				value: "Hair Care/Nails/Barber",
			},
			{
				label: "expenses.otherExpenses.fields.alimony",
				value: "Alimony",
			},
			{
				label: "expenses.otherExpenses.fields.miscellaneous",
				value: "Miscellaneous",
			},
			{
				label: "expenses.otherExpenses.fields.entertainment",
				value: "Entertainment",
			},
			{
				label: "expenses.otherExpenses.fields.church",
				value: "Church/Tithe/Charity",
			},
			{
				label: "expenses.otherExpenses.fields.pets",
				value: "Pets",
			},
			{
				label: "expenses.otherExpenses.fields.cigarettes",
				value: "Cigarettes",
			},
			{
				label: "expenses.otherExpenses.fields.homeRepairs",
				value: "Home Repairs/Maintenance",
			},
		],
	},
};
