import { Form } from "react-bootstrap";
import { useController } from "react-hook-form";
import InvalidFeedback from "../InvalidFeedback/InvalidFeedback";
import Flatpickr from "react-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { French } from "flatpickr/dist/l10n/fr.js";
import { english as English } from "flatpickr/dist/l10n/default";
import { CustomLocale } from "flatpickr/dist/types/locale";
import "flatpickr/dist/themes/airbnb.css";
import { useTranslation } from "react-i18next";

interface CalendarProps {
	label?: string;
	minDate?: Date;
	name: string;
	control: any;
	rules?: any;
	required?: boolean;
	onChange?: any;
	enableDates: (date: Date) => boolean;
}

export default function Calendar({
	label,
	minDate,
	control,
	name,
	rules,
	required,
	onChange,
	enableDates,
}: CalendarProps) {
	const {
		field,
		fieldState: { error },
	} = useController({ name, control, rules });

	const { i18n } = useTranslation();
	const currLanguage = i18n.language;
	let calendarLanguage: CustomLocale;
	let dateFormat: string;

	if (currLanguage === "fr-CA") {
		calendarLanguage = French;
		dateFormat = "j F, Y";
	} else if (currLanguage === "es") {
		calendarLanguage = Spanish;
		dateFormat = "F j, Y";
	} else {
		calendarLanguage = English;
		dateFormat = "F j, Y";
	}

	return (
		<Form.Group>
			<Form.Label htmlFor={name}>
				{label} {required && <span className="text-danger">*</span>}
			</Form.Label>
			<Flatpickr
				className="d-block field-md form-control"
				id={name}
				options={{
					locale: calendarLanguage,
					minDate,
					defaultDate: new Date(),
					dateFormat: dateFormat,
					enable: [(date: Date) => enableDates(date)],
				}}
				{...field}
				onChange={onChange}
				placeholder="Select a Date"
			/>

			<InvalidFeedback>{error?.message}</InvalidFeedback>
		</Form.Group>
	);
}
