import { useEffect, useRef, useState } from "react";
import DoughnutChart from "../../Prompts/Results/BudgetSummary/DoughnutChart";
import DownloadBudget from "../../Prompts/Results/BudgetSummary/DownloadBudget";
import EditBudget from "../../Prompts/Results/BudgetSummary/EditBudget";
import { AggregatesCard } from "../";
import { useTranslation } from "react-i18next";
import { useSession } from "../../Context/SessionProvider";

export const BudgetSummarySidebar = () => {
	const chartRef = useRef<any>();
	const [chartImage, setChartImage] = useState("");
	const { t } = useTranslation("budgetSummary");
	const {
		session: {
			vic: {
				currentBudget: { aggregates: { totalMonthlyExpenses = 0 } = {} } = {},
			} = {},
		},
	} = useSession();

	useEffect(() => {
		if (chartRef.current) {
			const image = chartRef.current.canvas.toDataURL("image/png");
			setChartImage(image);
		}
	}, [chartRef.current, totalMonthlyExpenses]);

	const renderChart = !!totalMonthlyExpenses;

	return (
		<>
			<div className="mb-2 d-flex align-items-center justify-content-center">
				<EditBudget />
				{renderChart && <DownloadBudget chartImage={chartImage} />}
			</div>
			<div className="bg-gray bg-opacity-10 rounded p-4">
				<div className="text-uppercase text-center text-navy-blue mb-2">
					{t("budgetSummary.aggregates.title")}
				</div>
				<div className="mb-4">
					<AggregatesCard />
				</div>
				{renderChart && (
					<>
						<div className="text-uppercase text-center text-navy-blue mb-2">
							{t("budgetSummary.chart.chartTitle")}
						</div>

						<DoughnutChart ref={chartRef} />

						<p className="mt-2 text-muted small text-center">
							{t("budgetSummary.chart.instructions")}
						</p>
					</>
				)}
			</div>
		</>
	);
};
