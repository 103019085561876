import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useController } from "react-hook-form";
import InvalidFeedback from "../InvalidFeedback/InvalidFeedback";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { preventNonNumberInput } from "../../../utils/preventNonNumberInput";

interface TextFieldProps {
	label?: string;
	name: string;
	type: string;
	className?: string;
	control: any;
	rules?: any;
	required?: boolean;
	autoComplete?: string;
	disabled?: string | boolean;
	maxLength?: number;
	placeholder?: string;
	tooltipMessage?: string;
}

export default function TextField({
	label,
	placeholder,
	control,
	name,
	type,
	className,
	rules,
	autoComplete,
	disabled,
	maxLength,
	required,
	tooltipMessage,
}: TextFieldProps) {
	const {
		field,
		fieldState: { error },
	} = useController({ name, control, rules, defaultValue: "" });

	const renderTooltip = (message: string) => {
		return <Tooltip id="info-tooltip">{message}</Tooltip>;
	};

	return (
		<Form.Group>
			{label && <Form.Label htmlFor={name}>{label}</Form.Label>}
			{required && <span className="text-danger"> *</span>}
			{tooltipMessage && (
				<OverlayTrigger
					placement="top"
					delay={{ show: 250, hide: 400 }}
					overlay={renderTooltip(tooltipMessage)}
				>
					<FontAwesomeIcon
						icon="info-circle"
						className="d-inline-block text-primary ms-2"
					/>
				</OverlayTrigger>
			)}
			<Form.Control
				className={className}
				placeholder={placeholder}
				id={name}
				autoComplete={autoComplete}
				type={type}
				isInvalid={!!error}
				disabled={!!disabled}
				maxLength={maxLength}
				{...field}
				onKeyDown={(e) => type === "number" && preventNonNumberInput(e, "num")}
				onWheel={(e) => {
					return (e.target as HTMLElement).blur();
				}}
			/>
			<InvalidFeedback>{error?.message}</InvalidFeedback>
		</Form.Group>
	);
}
