import {
	createContext,
	useContext,
	useState,
	useEffect,
	useLayoutEffect,
} from "react";
import { useSession } from "./SessionProvider";
import { useLocation } from "react-router-dom";
import { pathsOrder, REQUEST_CALLBACK_PATH } from "../../utils/routes";
import { useStatus } from "./StatusProvider";

interface Values {
	openChatbot: () => void;
	closeChatbot: () => void;
	displayChatbot: boolean;
	isContentHidden: boolean;
	isChatbotOpen: boolean;
	isSmallScreen: boolean;
}

export const initialValues: Values = {
	openChatbot: () => undefined,
	closeChatbot: () => undefined,
	displayChatbot: false,
	isContentHidden: false,
	isChatbotOpen: false,
	isSmallScreen: false,
};

export const ChatbotContext = createContext<Values>(initialValues);

export default function ChatbotProvider({ children }: any) {
	const smallScreenMediaQuery = window.matchMedia("(max-width: 816px)");

	const [isContentHidden, setIsContentHidden] = useState(false);
	const [isChatbotOpen, setIsChatbotOpen] = useState(false);
	const [isSmallScreen, setIsSmallScreen] = useState(
		smallScreenMediaQuery.matches
	);

	const { isAbandoned, isLocked } = useStatus();
	const { isFormEditable } = useSession();
	const { pathname } = useLocation();

	const displayChatbot =
		((pathsOrder.includes(pathname) || pathname === REQUEST_CALLBACK_PATH) &&
			isFormEditable() &&
			!isAbandoned &&
			!isLocked) ??
		false;

	useEffect(() => {
		if (!isSmallScreen) {
			setIsContentHidden(false);
		}
		if (isSmallScreen && isChatbotOpen) {
			setIsContentHidden(true);
		}
	}, [isSmallScreen]);

	useLayoutEffect(() => {
		function updateWidth() {
			setIsSmallScreen(smallScreenMediaQuery.matches);
		}
		window.addEventListener("resize", updateWidth);
		return () => window.removeEventListener("resize", updateWidth);
	}, []);

	const openChatbot = () => {
		setIsChatbotOpen(true);
		if (isSmallScreen) setIsContentHidden(true);
	};

	const closeChatbot = () => {
		setIsChatbotOpen(false);
		setIsContentHidden(false);
	};

	const values: Values = {
		openChatbot,
		closeChatbot,
		displayChatbot,
		isContentHidden,
		isChatbotOpen,
		isSmallScreen,
	};

	return (
		<ChatbotContext.Provider value={values}>{children}</ChatbotContext.Provider>
	);
}

export const useChatbot = () => useContext(ChatbotContext);
