import { StatusParams } from "../Types";

export const getFormStatus = ({
	isLocked,
	isAbandoned,
	percentComplete,
}: {
	isLocked: boolean;
	isAbandoned: boolean;
	percentComplete: number;
}): StatusParams["applicant_form_status"] => {
	if (isLocked) return "Locked";
	if (isAbandoned) return "Abandoned";

	if (percentComplete === 100) {
		return "Complete";
	}

	return "In Progress";
};
