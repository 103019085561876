import { Row, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import FormNavButtons from "../../../Common/FormNavButtons/FormNavButtons";
import FormTitle from "../../../Common/FormTitle/FormTitle";
import TextField from "../../../Common/TextField/TextField";
import isFormComplete from "../../../../utils/isFormComplete/isFormComplete";
import type { ApplicantParams } from "../../../../utils/Types";
import useIntentHelpers from "../../../../utils/usePostIntent/usePostIntent";
import useNavigateHelpers from "../../../../utils/useNavigateHelpers/useNavigateHelpers";
import { PROFILE_PROVIDED_INTENT } from "../../../../utils/intents";
import useSessionData from "../../../../utils/useSessionData/useSessionData";
import { validateZipCode } from "../../../../utils/validateZipCode/validateZipCode";
import { getCountry } from "../../../../utils/getCountry";

const country = getCountry();

const usPostalCodeValidation = {
	type: "number",
	minCharacters: 5,
	maxCharacters: 5,
};

const caPostalCodeValidation = {
	type: "text",
	minCharacters: 6,
	maxCharacters: 7,
};

const zipCodeValidation =
	country === "US" ? usPostalCodeValidation : caPostalCodeValidation;

export default function Applicant() {
	// Sanitizes strings that might be containing ZeroWidthSpace characters
	const sanitizeString = (string?: string) => {
		if (string) return string.replace(/\u200B/g, "");
	};

	const { t } = useTranslation("overview");
	const session = useSessionData();

	const applicant_first_name = sanitizeString(session.applicant_first_name);
	const applicant_last_name = sanitizeString(session.applicant_last_name);
	const applicant_cell_phone = sanitizeString(session.applicant_cell_phone);
	const applicant_zip_code = sanitizeString(session.applicant_zip_code);

	const defaultValues: ApplicantParams = {
		applicant_first_name,
		applicant_last_name,
		applicant_cell_phone,
		applicant_zip_code,
	};

	const {
		handleSubmit,
		control,
		watch,
		formState: { isDirty },
		setError,
	} = useForm<ApplicantParams>({
		defaultValues,
	});

	const { postIntent, postStatus } = useIntentHelpers();
	const { navigateNextPrompt } = useNavigateHelpers();
	const [allowEditZipCode, setAllowEditZipCode] = useState<boolean>(
		!applicant_zip_code
	);

	const submitForm = async (data: ApplicantParams) => {
		if (isDirty) {
			try {
				const zipCode = watch("applicant_zip_code");
				await validateZipCode(zipCode);
			} catch (err) {
				setAllowEditZipCode(true);
				if (err instanceof Error) {
					setError("applicant_zip_code", {
						message: t(`applicant.zipCode.rules.${err.message}`),
					});
				}

				return;
			}

			await postIntent({
				parameters: data,
				text: PROFILE_PROVIDED_INTENT,
			});

			navigateNextPrompt();
		} else {
			postStatus();
			navigateNextPrompt();
		}
	};

	const isComplete = isFormComplete(watch());

	return (
		<>
			<FormTitle className="text-center">{t("applicant.formTitle")}</FormTitle>

			<Form onSubmit={handleSubmit(submitForm)}>
				<div className="mx-auto container-max-sm">
					<Row>
						<Col xs={12} md={6}>
							<Form.Group className="input-text-group">
								<TextField
									label={t("applicant.firstName.label")}
									name="applicant_first_name"
									disabled={applicant_first_name}
									autoComplete="given-name"
									type="text"
									control={control}
									rules={{
										required: t("applicant.firstName.rules.required"),
									}}
									required
								/>
							</Form.Group>
						</Col>

						<Col xs={12} md={6}>
							<Form.Group className="input-text-group">
								<TextField
									label={t("applicant.lastName.label")}
									name="applicant_last_name"
									disabled={applicant_last_name}
									autoComplete="family-name"
									type="text"
									control={control}
									rules={{
										required: t("applicant.lastName.rules.required"),
									}}
									required
								/>
							</Form.Group>
						</Col>
					</Row>

					<Row>
						<Col>
							<Form.Group className="input-text-group">
								<TextField
									label={t("applicant.cellPhone.label")}
									name="applicant_cell_phone"
									disabled={applicant_cell_phone}
									autoComplete="tel"
									type="tel"
									control={control}
									rules={{
										required: t("applicant.cellPhone.rules.required"),
										pattern: {
											value: /\d[0-9+\\(\\)#\\.\s\\/-]+$/,
											message: t("applicant.cellPhone.rules.requiredNum"),
										},
										maxLength: {
											value: 14,
											message: t("applicant.cellPhone.rules.requiredMax"),
										},
										minLength: {
											value: 10,
											message: t("applicant.cellPhone.rules.requiredMin"),
										},
									}}
									required
								/>
							</Form.Group>
						</Col>

						<Col lg={6}>
							<Form.Group className="input-text-group">
								<TextField
									label={t("applicant.zipCode.label")}
									name="applicant_zip_code"
									disabled={!allowEditZipCode}
									autoComplete="postal-code"
									className="zip"
									type={zipCodeValidation.type}
									control={control}
									rules={{
										required: t("applicant.zipCode.rules.required"),
										minLength: {
											value: zipCodeValidation.minCharacters,
											message: t("applicant.zipCode.rules.required"),
										},
										maxLength: {
											value: zipCodeValidation.maxCharacters,
											message: t("applicant.zipCode.rules.required"),
										},
									}}
									required
								/>
							</Form.Group>
						</Col>
					</Row>
				</div>
				<FormNavButtons hasBackLink={false} nextLinkDisabled={!isComplete} />
			</Form>
		</>
	);
}
