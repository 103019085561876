import { DebtAmountParams, HasIncomeParams, StatusParams } from "../Types";

// Hotfix: allows income and debt amount data to be passed in directly rather than pulling from state to bypass 'lag' issue where Qualification status was being calculated with stale values

// Todo: Refactor to pull income data from RHF Form context directly
export const getQualificationStatus = ({
	hasIncome,
	debtAmount,
}: {
	hasIncome?: HasIncomeParams["has_income"];
	debtAmount?: DebtAmountParams["debt_amount"];
}): StatusParams["applicant_qualified_status"] => {
	if ((debtAmount && Number(debtAmount) < 3000) || hasIncome === false)
		return "Not Qualified";

	if (debtAmount && Number(debtAmount) >= 3000 && hasIncome === true)
		return "Qualified";

	return "Pending";
};
