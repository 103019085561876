import { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import normalize from "../../../../utils/normalizeCurrency/normalizeCurrency";
import { escapeApostrophes } from "../../../../utils/stringUtils";
import {
	BudgetElement,
	ExpensesParams,
	FormExpenses,
	IncomeParams,
	Sources,
	SubCategories,
} from "../../../../utils/Types";
import useBudgetHelpers from "../../../../utils/useBudgetHelpers/useBudgetHelpers";
import usePostIntent from "../../../../utils/usePostIntent/usePostIntent";
import TextFieldGroup from "../../../Common/TextFieldGroup/TextFieldGroup";
import { useSession } from "../../../Context/SessionProvider";
import { getSourceOptions as getIncome } from "../../Income/IncomeSources/IncomeSources";
import {
	EXPENSES_PROVIDED_INTENT,
	INCOME_PROVIDED_INTENT,
} from "../../../../utils/intents";
import { config } from "../../../../utils/countryConfig";

function EditBudget() {
	const { loading } = useSession();
	const [show, setShow] = useState(false);
	const { t: global } = useTranslation("global");
	const { t: budgetSummaryT } = useTranslation("budgetSummary");
	const { t: expensesT } = useTranslation("expensesFieldLabels");

	const { getValue, formatBudgetElement, getRemaining } = useBudgetHelpers();
	const [total, setTotal] = useState(getRemaining());
	const { postIntent } = usePostIntent();

	const { expenses } = config;
	const income = getIncome();

	const getDefaultValues = () => {
		const incomeDefaultValues: { [key in Sources]?: any } = {};
		income.forEach(
			(field) =>
				(incomeDefaultValues[field.value] = getValue(field.value, true))
		);

		const expenseDefaultValues = Object.keys(expenses).reduce(
			(obj: { [key: string]: any }, expense) => {
				const fields = expenses[expense].fields;
				for (let i = 0; i < fields.length; i++) {
					const field = fields[i];
					obj[escapeApostrophes(field.value)] = getValue(field.value, true);
				}
				return obj;
			},
			{}
		);

		return { ...expenseDefaultValues, ...incomeDefaultValues };
	};

	const {
		handleSubmit,
		control,
		formState,
		watch,
		reset,
		formState: { isDirty },
	} = useForm<FormExpenses & { [key in Sources]?: string }>({
		defaultValues: getDefaultValues(),
		mode: "onChange",
	});

	useEffect(() => {
		if (formState.isValid && !formState.isValidating) {
			const data = watch();
			setTotal(
				Object.keys(data).reduce((total, field) => {
					const value = data[field as Sources];
					if (income.map((i) => i.value).includes(field as Sources)) {
						return total + +(value || 0);
					}
					return total - +(value || 0);
				}, 0) || 0
			);
		}
	}, [formState]);

	useEffect(() => {
		reset(getDefaultValues());
	}, [show]);

	const buildParameters = (
		formData: FormExpenses & { [key in Sources]?: string }
	) => {
		const expensesFormData: BudgetElement[] = [];
		const incomeFormData: BudgetElement[] = [];

		Object.keys(formData).forEach((field) => {
			const subCategory = field as SubCategories;
			const amount = formData[subCategory];

			if (income.map((i) => i.value).includes(field as Sources)) {
				const budgetElement = formatBudgetElement({
					category: "Income",
					subCategory,
					amount,
					type: "Fixed",
				});
				incomeFormData.push(budgetElement);
			} else {
				const budgetElement = formatBudgetElement({
					subCategory,
					amount,
				});
				expensesFormData.push(budgetElement);
			}
		});

		const parameters: IncomeParams & ExpensesParams = {
			applicant_expenses: expensesFormData,
			applicant_income: incomeFormData,
		};

		return parameters;
	};

	const submitForm = (data: FormExpenses) => {
		if (isDirty) {
			const { applicant_expenses, applicant_income } = buildParameters(data);
			postIntent({
				parameters: { applicant_expenses },
				text: EXPENSES_PROVIDED_INTENT,
			})
				.then(() =>
					postIntent({
						parameters: { applicant_income },
						text: INCOME_PROVIDED_INTENT,
					})
				)
				.then(() => setShow(false));
		} else {
			setShow(false);
		}
	};

	const renderModal = () => {
		return (
			<Modal show={show} onHide={() => setShow(false)} size="xl" centered>
				<Modal.Header closeButton className="border-0 mx-2">
					<h1 className="modal-header">
						{budgetSummaryT("budgetSummary.editBudget.title")}
					</h1>
				</Modal.Header>
				<Form onSubmit={handleSubmit(submitForm)}>
					<Modal.Body>
						<p className="mx-2">
							{budgetSummaryT("budgetSummary.editBudget.description")}
						</p>

						<Row className="info-block align-items-center m-2">
							<Col sm={12} lg={3}>
								<div className="mb-3 mb-lg-0">
									<FontAwesomeIcon
										icon={["fas", "money-bill"]}
										className="text-muted me-3"
									/>
									<span className="text-navy-blue">
										<strong>
											{budgetSummaryT("budgetSummary.aggregates.income")}
										</strong>
									</span>
								</div>
							</Col>
							<Col>
								<Row>
									{income.map(({ label, value }) => {
										return (
											<Col key={value} xs="auto">
												<TextFieldGroup
													rules={{
														pattern: {
															value: /^\d*\.\d{0,2}$|^\d+$/,
															message: global("rules.dollarAmount"),
														},
														max: {
															value: 100000,
															message: global("rules.max", {
																amount: 100000,
															}),
														},
														min: {
															value: 0,
															message: global("rules.min"),
														},
													}}
													label={label}
													prefix="$"
													name={value}
													type="number"
													className="field-xs"
													control={control}
												/>
											</Col>
										);
									})}
								</Row>
							</Col>
						</Row>
						{Object.keys(expenses).map((expense) => {
							return (
								<Row
									className="info-block align-items-center m-2"
									key={expense}
								>
									<Col sm={12} lg={3}>
										<div className="mb-3 mb-lg-0">
											<FontAwesomeIcon
												icon={expenses[expense].icon}
												className="text-muted me-3"
											/>
											<span className="text-navy-blue">
												<strong>{expensesT(expenses[expense].category)}</strong>
											</span>
										</div>
									</Col>
									<Col>
										<Row>
											{expenses[expense].fields.map(
												({ label, value, maxAmount }) => {
													return (
														<Col key={escapeApostrophes(value)} xs="auto">
															<TextFieldGroup
																rules={{
																	pattern: {
																		value: /^\d*\.\d{0,2}$|^\d+$/,
																		message: global("rules.dollarAmount"),
																	},
																	max: {
																		value: maxAmount || 10000,
																		message: global("rules.max", {
																			amount: maxAmount || 10000,
																		}),
																	},
																	min: {
																		value: 0,
																		message: global("rules.min"),
																	},
																}}
																label={expensesT(label)}
																prefix="$"
																name={escapeApostrophes(value)}
																type="number"
																className="field-xs"
																control={control}
															/>
														</Col>
													);
												}
											)}
										</Row>
									</Col>
								</Row>
							);
						})}
					</Modal.Body>
					<Modal.Footer className="border-0">
						<div className="d-flex align-items-center justify-content-between me-4">
							<div>{budgetSummaryT("budgetSummary.editBudget.total")}</div>
							<div className="ms-2" style={{ wordBreak: "break-all" }}>
								{normalize(total, true)}
							</div>
						</div>
						<Button
							variant="primary"
							type="submit"
							data-role="submit"
							disabled={loading}
						>
							{loading ? (
								<>
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/>{" "}
									{global("loading")}
								</>
							) : (
								budgetSummaryT("budgetSummary.editBudget.save")
							)}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		);
	};

	return (
		<>
			<Button
				className="btn btn-primary me-4"
				size="sm"
				onClick={() => setShow(true)}
			>
				<span>
					<FontAwesomeIcon icon={"edit"} className="d-inline-block me-2" />
					{budgetSummaryT("budgetSummary.editBudget.title")}
				</span>
			</Button>
			{renderModal()}
		</>
	);
}

export default EditBudget;
