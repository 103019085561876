import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import FormNavButtons from "../../../Common/FormNavButtons/FormNavButtons";
import FormTitle from "../../../Common/FormTitle/FormTitle";
import Select from "../../../Common/Select/Select";
import type { DebtAmountParams } from "../../../../utils/Types";
import useIntentHelpers from "../../../../utils/usePostIntent/usePostIntent";
import useNavigateHelpers from "../../../../utils/useNavigateHelpers/useNavigateHelpers";
import { DEBT_AMOUNT_PROVIDED_INTENT } from "../../../../utils/intents";
import useSessionData from "../../../../utils/useSessionData/useSessionData";

export const options = [
	{ value: 1000, label: "$1,000 - $2,999" },
	{ value: 3000, label: "$3,000 - $4,999" },
	{ value: 5000, label: "$5,000 - $9,999" },
	{ value: 10000, label: "$10,000 - $14,999" },
	{ value: 15000, label: "$15,000 - $19,999" },
	{ value: 20000, label: "$20,000 - $24,999" },
	{ value: 25000, label: "$25,000 - $29,999" },
	{ value: 30000, label: "$30,000 - $34,999" },
	{ value: 35000, label: "$35,000 - $39,999" },
	{ value: 40000, label: "$40,000 - $44,999" },
	{ value: 45000, label: "$45,000 - $49,999" },
	{ value: 50000, label: "$50,000 - $54,999" },
	{ value: 55000, label: "$55,000 - $59,999" },
	{ value: 60000, label: "$60,000 - $64,999" },
	{ value: 65000, label: "$65,000 - $69,999" },
	{ value: 70000, label: "$70,000 - $74,999" },
	{ value: 75000, label: "$75,000+" },
];

export const optionsFrench = [
	{ value: 1000, label: "1,000 $ - 2,999 $" },
	{ value: 3000, label: "3,000 $ - 4,999 $" },
	{ value: 5000, label: "5,000 $ - 9,999 $" },
	{ value: 10000, label: "10,000 $ - 14,999 $" },
	{ value: 15000, label: "15,000 $ - 19,999 $" },
	{ value: 20000, label: "20,000 $ - 24,999 $" },
	{ value: 25000, label: "25,000 $ - 29,999 $" },
	{ value: 30000, label: "30,000 $ - 34,999 $" },
	{ value: 35000, label: "35,000 $ - 39,999 $" },
	{ value: 40000, label: "40,000 $ - 44,999 $" },
	{ value: 45000, label: "45,000 $ - 49,999 $" },
	{ value: 50000, label: "50,000 $ - 54,999 $" },
	{ value: 55000, label: "55,000 $ - 59,999 $" },
	{ value: 60000, label: "60,000 $ - 64,999 $" },
	{ value: 65000, label: "65,000 $ - 69,999 $" },
	{ value: 70000, label: "70,000 $ - 74,999 $" },
	{ value: 75000, label: "75,000 $ +" },
];

export default function DebtAmount() {
	const { t: global } = useTranslation("global");
	const { t, i18n } = useTranslation("overview");
	const { debt_amount } = useSessionData();

	const currentLanguage = i18n.language;

	const {
		handleSubmit,
		control,
		watch,
		formState: { isDirty },
	} = useForm<DebtAmountParams>({
		defaultValues: {
			debt_amount,
		},
	});

	const isComplete = !!watch("debt_amount");

	const { postIntent, postStatus } = useIntentHelpers();
	const { navigateNextPrompt } = useNavigateHelpers();

	const submitForm = async (data: DebtAmountParams) => {
		if (isDirty) {
			data.debt_amount = data.debt_amount ? +data.debt_amount : undefined;
			await postIntent({ parameters: data, text: DEBT_AMOUNT_PROVIDED_INTENT });
			navigateNextPrompt();
		} else {
			postStatus();
			navigateNextPrompt();
		}
	};

	return (
		<>
			<FormTitle className="text-center" label="debt_amount">
				{t("debtAmount.formTitle")}
			</FormTitle>

			<Form onSubmit={handleSubmit(submitForm)}>
				<div className="mx-auto field-md">
					<Select
						name="debt_amount"
						label={t("debtAmount.label")}
						firstOption={global("select")}
						options={currentLanguage === "fr-ca" ? optionsFrench : options}
						control={control}
						rules={{
							required: t("debtAmount.rules.required"),
						}}
						required
					/>
				</div>

				<FormNavButtons nextLinkDisabled={!isComplete} />
			</Form>
		</>
	);
}
