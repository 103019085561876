import { Card, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSession } from "../../Context/SessionProvider";
import normalize from "../../../utils/normalizeCurrency/normalizeCurrency";
import useBudgetHelpers from "../../../utils/useBudgetHelpers/useBudgetHelpers";

/** Displays a card of the Total Income, Expenses, and Remaining amounts. */
export const AggregatesCard = () => {
	const { t } = useTranslation("budgetSummary");
	const { session } = useSession();
	const { getRemaining } = useBudgetHelpers();

	const totalMonthlyExpenses =
		session.vic?.currentBudget?.aggregates?.totalMonthlyExpenses || 0;
	const totalMonthlyIncome =
		session.vic?.currentBudget?.aggregates?.totalMonthlyIncome || 0;

	return (
		<Card>
			<ListGroup className="list-group-flush">
				<ListGroup.Item className="d-flex align-items-center justify-content-between border-0">
					<div>{t("budgetSummary.aggregates.income")}</div>
					<div className="bg-success bg-opacity-25 p-2 rounded">
						{normalize(totalMonthlyIncome, true)}
					</div>
				</ListGroup.Item>
				<ListGroup.Item className="d-flex align-items-center justify-content-between">
					<div>{t("budgetSummary.aggregates.expenses")}</div>
					<div className="bg-negative bg-opacity-25 p-2 rounded">
						{normalize(totalMonthlyExpenses, true)}
					</div>
				</ListGroup.Item>

				<ListGroup.Item className="d-flex align-items-center justify-content-between">
					{getRemaining() >= 0 ? (
						<div>{t("budgetSummary.aggregates.remaining")}</div>
					) : (
						<div>{t("budgetSummary.aggregates.shortfall")}</div>
					)}
					<div className="p-2">{normalize(getRemaining(), true)}</div>
				</ListGroup.Item>
			</ListGroup>
		</Card>
	);
};
