import { CAExpenses, USExpenses } from "./Expenses";
import { getCountry } from "./getCountry";
import usLogo from "../assets/virtual-counselor.png";
import caLogo from "../assets/virtual-counselor-ca.png";
import usLogoSvg from "../assets/virtual-counselor.svg";
import caLogoSvg from "../assets/virtual-counselor-ca.svg";

type languageConfig = {
	[key: string]: { nativeName: string };
};

const country = getCountry();

export const USTimeslots = [
	"9:00 AM - 10:00 AM",
	"10:00 AM - 11:00 AM",
	"11:00 AM - 12:00 PM",
	"12:00 PM - 1:00 PM",
	"1:00 PM - 2:00 PM",
	"2:00 PM - 3:00 PM",
	"3:00 PM - 4:00 PM",
	"4:00 PM - 5:00 PM",
	"5:00 PM - 6:00 PM",
	"6:00 PM - 7:00 PM",
	"7:00 PM - 8:00 PM",
	"8:00 PM - 9:00 PM",
];

export const CATimeslots = [
	"9:00 AM - 10:00 AM",
	"10:00 AM - 11:00 AM",
	"11:00 AM - 12:00 PM",
	"12:00 PM - 1:00 PM",
	"1:00 PM - 2:00 PM",
	"2:00 PM - 3:00 PM",
	"3:00 PM - 4:00 PM",
	"4:00 PM - 5:00 PM",
	"5:00 PM - 6:00 PM",
	"6:00 PM - 7:00 PM",
	"7:00 PM - 8:00 PM",
];

const USIncomeSources = [
	{
		label: "incomeForm.sourceOptions.primaryIncome",
		value: "Applicant Primary Income",
	},
	{
		label: "incomeForm.sourceOptions.secondaryIncome",
		value: "Applicant Secondary Income",
	},
	{
		label: "incomeForm.sourceOptions.childSupportAlimony",
		value: "Applicant Alimony/Child Support",
	},
	{
		label: "incomeForm.sourceOptions.socialSecurity",
		value: "Applicant Social Security",
	},
	{
		label: "incomeForm.sourceOptions.pension",
		value: "Applicant Pension",
	},
	{
		label: "incomeForm.sourceOptions.disability",
		value: "Applicant Disability",
	},
	{
		label: "incomeForm.sourceOptions.unemployment",
		value: "Applicant Unemployment",
	},
	{
		label: "incomeForm.sourceOptions.rentalIncome",
		value: "Applicant Rental Income",
	},
	{
		label: "incomeForm.sourceOptions.other",
		value: "Applicant Other Income",
	},
];

const CAIncomeSources = [
	{
		label: "incomeForm.sourceOptions.primaryIncome",
		value: "Applicant Primary Income",
	},
	{
		label: "incomeForm.sourceOptions.secondaryIncome",
		value: "Applicant Secondary Income",
	},
	{
		label: "incomeForm.sourceOptions.childTaxBenefit",
		value: "Child Tax Benefit",
	},
	{
		label: "incomeForm.sourceOptions.govtPension",
		value: "Applicant Social Security",
	},
	{
		label: "incomeForm.sourceOptions.privatePension",
		value: "Applicant Pension",
	},
	{
		label: "incomeForm.sourceOptions.publicAssistance",
		value: "Public Assistance",
	},
	{
		label: "incomeForm.sourceOptions.childSupportAlimony",
		value: "Applicant Alimony/Child Support",
	},
	{
		label: "incomeForm.sourceOptions.rentalIncome",
		value: "Applicant Rental Income",
	},
	{
		label: "incomeForm.sourceOptions.other",
		value: "Applicant Other Income",
	},
];

const usLanguages: languageConfig = {
	en: { nativeName: "English" },
	es: { nativeName: "Español" },
};

const caLanguages: languageConfig = {
	"en-CA": { nativeName: "English" },
	"es-CA": { nativeName: "Español" },
	"fr-CA": { nativeName: "Français" },
};

const countryConfig = {
	US: {
		languages: usLanguages,
		logos: {
			png: usLogo,
			svg: usLogoSvg,
		},
		incomeSources: USIncomeSources,
		expenses: USExpenses,
		calendarTimeslots: {
			weekdays: USTimeslots,
			friday: USTimeslots,
			saturday: USTimeslots.slice(1, 5),
		},
		redirectUrl: process.env.REACT_APP_REDIRECT_URL,
	},
	CA: {
		languages: caLanguages,
		logos: {
			png: caLogo,
			svg: caLogoSvg,
		},
		incomeSources: CAIncomeSources,
		expenses: CAExpenses,
		calendarTimeslots: {
			weekdays: CATimeslots,
			friday: CATimeslots.slice(0, 9),
			saturday: CATimeslots.slice(1, 5),
		},
		redirectUrl: process.env.REACT_APP_REDIRECT_URL_CA,
	},
};

const config = countryConfig[country];

export { config };
