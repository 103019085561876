import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import TextFieldGroup from "../../../Common/TextFieldGroup/TextFieldGroup";

export default function MortgageForm({
	control,
	name,
	index,
	deleteMortgage,
	t,
}: any) {
	const { t: global } = useTranslation("global");

	return (
		<Card className="p-3 mb-4 form-group">
			<div className="form-group-label">
				{index === 0 && t("housing.mortgage.firstMortgage")}
				{index === 1 && t("housing.mortgage.secondMortgage")}
			</div>
			<div className="d-flex align-items-start justify-content-between">
				<Form.Group>
					<TextFieldGroup
						rules={{
							pattern: {
								value: /^\d*\.\d{0,2}$|^\d+$/,
								message: global("rules.dollarAmount"),
							},
							max: {
								value: 100000,
								message: global("rules.max", { amount: 100000 }),
							},
							min: {
								value: 0,
								message: global("rules.min"),
							},
						}}
						label={t("housing.mortgage.monthlyPayment.label")}
						prefix="$"
						name={`${name}.monthly_payment`}
						type="number"
						control={control}
					/>
				</Form.Group>
				{index !== 0 && (
					<Button
						variant="link"
						className="all-caps-link p-0"
						onClick={() => deleteMortgage(index)}
					>
						<FontAwesomeIcon icon="xmark" className="d-inline-block" />
					</Button>
				)}
			</div>
		</Card>
	);
}
