import { Form, InputGroup, FormGroup } from "react-bootstrap";
import { useController } from "react-hook-form";
import InvalidFeedback from "../InvalidFeedback/InvalidFeedback";
import { preventNonNumberInput } from "../../../utils/preventNonNumberInput";

interface TextFieldGroupProps {
	label?: string;
	name: string;
	type: string;
	control: any;
	rules?: any;
	step?: string;
	prefix?: string;
	required?: boolean;
	[key: string]: any;
}

export default function TextFieldGroup({
	label,
	control,
	name,
	type,
	rules,
	prefix,
	required,
	...props
}: TextFieldGroupProps) {
	const {
		field,
		fieldState: { error },
	} = useController({ name, control, rules });

	return (
		<FormGroup className="w-100">
			<Form.Label htmlFor={name}>
				{label} {required && <span className="text-danger">*</span>}
			</Form.Label>
			<InputGroup>
				<InputGroup.Text>{prefix}</InputGroup.Text>
				<Form.Control
					id={name}
					type={type}
					isInvalid={!!error}
					data-role={name}
					{...field}
					{...props}
					onKeyDown={(e) =>
						type === "number" && preventNonNumberInput(e, "float")
					}
					onWheel={(e) => {
						return (e.target as HTMLElement).blur();
					}}
				/>
				<InvalidFeedback>{error?.message}</InvalidFeedback>
			</InputGroup>
		</FormGroup>
	);
}
