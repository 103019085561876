import { Spinner } from "react-bootstrap";

export default function ChatbotMessage({
	firstMessage = false,
	user = false,
	loadingResponse = false,
	children,
}: {
	firstMessage?: boolean;
	user?: boolean;
	loadingResponse?: boolean;
	children?: JSX.Element | string;
}) {
	if (loadingResponse) {
		return (
			<div>
				{firstMessage && <div className="triangle"></div>}
				<div className="p-3 vic-bubble mb-2">
					<Spinner animation="grow" className="spinner-grow-xs" />
					<Spinner animation="grow" className="spinner-grow-xs mx-2" />
					<Spinner animation="grow" className="spinner-grow-xs" />
				</div>
			</div>
		);
	}

	return (
		<div>
			{firstMessage && !user && <div className="triangle"></div>}
			<div
				className={`p-3 ${
					user ? "user-bubble ms-auto bg-primary" : "vic-bubble"
				} mb-2`}
			>
				{children}
			</div>
		</div>
	);
}
