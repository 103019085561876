import { Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CURRENCY_REGEX } from "../../../../utils/currencyUtils";

import { ChildCareParams } from "../../../../utils/Types";
import useBudgetHelpers from "../../../../utils/useBudgetHelpers/useBudgetHelpers";
import useNavigateHelpers from "../../../../utils/useNavigateHelpers/useNavigateHelpers";
import usePostIntent from "../../../../utils/usePostIntent/usePostIntent";
import FormNavButtons from "../../../Common/FormNavButtons/FormNavButtons";
import FormTitle from "../../../Common/FormTitle/FormTitle";
import TextFieldGroup from "../../../Common/TextFieldGroup/TextFieldGroup";

export function ChildCare() {
	const { t: global } = useTranslation("global");
	const { t } = useTranslation("expenses");
	const { postStatus, postFormData } = usePostIntent();
	const { generateDefaultValues } = useBudgetHelpers();

	const {
		handleSubmit,
		control,
		formState: { isDirty },
	} = useForm<ChildCareParams>({
		defaultValues: generateDefaultValues<ChildCareParams>([
			"Child Care",
			"Child Support/Alimony",
		]),
	});

	const { navigateNextPrompt } = useNavigateHelpers();

	const submitForm = (data: ChildCareParams) => {
		if (isDirty) {
			const CATEGORY = "Children";
			postFormData(data, CATEGORY).then(() => {
				postStatus();
				navigateNextPrompt();
			});
		} else {
			postStatus();
			navigateNextPrompt();
		}
	};

	return (
		<>
			<FormTitle faIcon={["fas", "family"]}>
				{t("childCare.formTitle")}
			</FormTitle>
			<p className="text-navy-blue italic text-small">{global("monthly")}</p>
			<p className="fw-light text-navy-blue">{t("childCare.description")}</p>
			<Form onSubmit={handleSubmit(submitForm)} noValidate>
				<Row>
					<Form.Group>
						<TextFieldGroup
							rules={{
								pattern: {
									value: CURRENCY_REGEX,
									message: global("rules.dollarAmount"),
								},
								max: {
									value: 10000,
									message: global("rules.max", { amount: 10000 }),
								},
								min: {
									value: 0,
									message: global("rules.min"),
								},
							}}
							label={t("childCare.childCare.label")}
							prefix="$"
							name="Child Care"
							type="number"
							className="field-xs"
							control={control}
						/>
					</Form.Group>
				</Row>

				<Row className="mt-4">
					<Form.Group>
						<TextFieldGroup
							rules={{
								pattern: {
									value: CURRENCY_REGEX,
									message: global("rules.dollarAmount"),
								},
								max: {
									value: 10000,
									message: global("rules.max", { amount: 10000 }),
								},
								min: {
									value: 0,
									message: global("rules.min"),
								},
							}}
							label={t("childCare.childSupportAlimony.label")}
							prefix="$"
							name="Child Support/Alimony"
							type="number"
							className="field-xs"
							control={control}
						/>
					</Form.Group>
				</Row>
				<FormNavButtons />
			</Form>
		</>
	);
}
