import { useEffect, useState } from "react";

import { DateTime } from "luxon";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import {
	getNextDate,
	isFriday,
	isSaturday,
	isSunday,
	isToday,
	isValidDate,
} from "../../../../utils/date";
import { CALL_BACK_PROVIDED_INTENT } from "../../../../utils/intents";
import { BUDGET_SUMMARY_PATH } from "../../../../utils/routes";
import { CallbackParams } from "../../../../utils/Types";
import usePostIntent from "../../../../utils/usePostIntent/usePostIntent";
import Calendar from "../../../Common/Calendar/Calendar";
import FormNavButtons from "../../../Common/FormNavButtons/FormNavButtons";
import RadioGroup from "../../../Common/RadioGroup/RadioGroup";
import Select from "../../../Common/Select/Select";
import { useSession } from "../../../Context/SessionProvider";
import { useSessionTimeout } from "../../../Context/SessionTimeoutContext";
import { config } from "../../../../utils/countryConfig";

interface CallbackFormParams {
	scheduleType: string;
	date: string | Date;
	time: string;
}

const DEFAULT_TIMEZONE = "America/New_York";
const USER_TIMEZONE = DateTime.local().zoneName ?? undefined;

const getHour = (time: string): number => {
	const [hour] = time.split(":");
	const period = time.slice(-2);
	const hourInt = parseInt(hour, 10);
	if (isNaN(hourInt)) {
		return NaN;
	}
	if (period === "PM" && hourInt < 12) {
		return hourInt + 12;
	}
	return hourInt;
};

export const generateTimes = (date: Date | string) => {
	const now = new Date();

	const d = new Date(date);
	let times = config.calendarTimeslots.weekdays;

	if (!isSunday(d)) {
		times = config.calendarTimeslots.weekdays;
	}

	if (isFriday(d)) {
		times = config.calendarTimeslots.friday;
	} else if (isSaturday(d)) {
		times = config.calendarTimeslots.saturday;
	}

	if (isToday(d)) {
		// if selected date is today, remove the incompatible time slots
		const currentHourInEST =
			DateTime.fromJSDate(now).setZone(DEFAULT_TIMEZONE).hour;

		const firstTimeSlot = times[0];
		const firstHour = getHour(firstTimeSlot);

		const index =
			currentHourInEST > firstHour ? currentHourInEST - firstHour + 1 : 0;

		times = times.slice(index);
	}

	// convert the time options to the user's time zone
	if (USER_TIMEZONE !== DEFAULT_TIMEZONE) {
		times = times.map((timeslot) => {
			const [start, end] = timeslot.split(" - ");
			const startDateTime = DateTime.fromFormat(start, "h:mm a", {
				zone: DEFAULT_TIMEZONE,
			});
			const endDateTime = DateTime.fromFormat(end, "h:mm a", {
				zone: DEFAULT_TIMEZONE,
			});

			const targetStart = startDateTime
				.setZone(USER_TIMEZONE || DEFAULT_TIMEZONE)
				.toFormat("h:mm a");
			const targetEnd = endDateTime.setZone("system").toFormat("h:mm a");

			return `${targetStart} - ${targetEnd}`;
		});
	}

	return times;
};

function CallbackForm() {
	const now = new Date();

	const { t } = useTranslation("callbackForm");
	const { pathname } = useLocation();
	const initialTimes = generateTimes(now);
	const [timeOptions, setTimeOptions] = useState<string[]>(initialTimes);
	const nextLabel =
		pathname === BUDGET_SUMMARY_PATH
			? t("submitLabel.finish")
			: t("submitLabel.submit");

	const { handleSubmit, control, setValue, watch } =
		useForm<CallbackFormParams>({
			defaultValues: {
				scheduleType: "asap",
				date: getCurrentOrNextDate(),
				time: "",
			},
		});

	function getCurrentOrNextDate() {
		let d = now;

		if (!timeOptions.length) {
			d = getNextDate(d);
		}

		return DateTime.fromJSDate(d).startOf("day").toJSDate();
	}

	const scheduleType = watch("scheduleType");

	useEffect(() => {
		if (scheduleType === "asap") {
			setValue("date", getCurrentOrNextDate());
			setValue("time", timeOptions[0]);
		} else {
			setValue("time", "");
		}
	}, [scheduleType]);

	const { postIntent } = usePostIntent();
	const { session, setInitializing } = useSession();
	const { setIsFormComplete } = useSessionTimeout();

	const submitForm = async ({
		date,
		time,
		scheduleType,
	}: CallbackFormParams) => {
		setIsFormComplete(true);

		const dt = getDateTimeInEST(date, time, scheduleType);
		const parameters = buildParameters(dt);

		postIntent({
			text: CALL_BACK_PROVIDED_INTENT,
			parameters,
		})
			.then(() => {
				setInitializing(true);
			})
			.then(() => redirect(getRedirectUrl()));
	};

	const getRedirectUrl = () => {
		const vt = session.vic?.consent?.ventureTech?.attributes?.VTResponse;
		return vt?.RedirectThankYouPageUrl;
	};

	const buildParameters = (dt: DateTime): CallbackParams => ({
		applicant_callback_time: dt.toUTC().toISO() || undefined,
	});

	const redirect = (url?: string) => {
		window.location.replace(url || "");
	};

	const addMinutes = (date: Date, minutes: number) => {
		return DateTime.fromJSDate(date).plus({ minutes }).toJSDate();
	};

	const getDateTimeInEST = (
		date: Date | string,
		time: string,
		scheduleType: string
	): DateTime => {
		if (scheduleType === "asap") {
			const todayPlus2Minutes = addMinutes(now, 2); // VT's request
			const dateString = todayPlus2Minutes.toISOString();
			return DateTime.fromISO(dateString, { zone: DEFAULT_TIMEZONE });
		} else {
			let dateObj = date instanceof Date ? date : now;
			if (!isValidDate(dateObj)) {
				dateObj = now;
			}
			const hour = getHour(time.split(" - ")[0]);
			if (isNaN(hour)) {
				dateObj.setHours(now.getHours());
			} else {
				dateObj.setHours(hour);
			}
			dateObj.setMinutes(0);
			return DateTime.fromObject(
				{
					day: dateObj.getDate(),
					month: +dateObj.getMonth() + 1,
					year: dateObj.getFullYear(),
					hour: dateObj.getHours(),
					minute: dateObj.getMinutes(),
				},
				{ zone: USER_TIMEZONE || DEFAULT_TIMEZONE }
			);
		}
	};

	const handleDateChange = ([date]: Date[]) => {
		setValue("date", date);
		setValue("time", "");
		setTimeOptions(generateTimes(date));
	};

	const disableTime = !watch("date"); // disables time if date is absent

	const enableDates = (date: Date) => {
		const thirtyDaysFromNow = new Date();
		thirtyDaysFromNow.setDate(now.getDate() + 30);

		return date.getDay() !== 0 && date <= thirtyDaysFromNow;
	};

	return (
		<Form onSubmit={handleSubmit(submitForm)}>
			<div className="info-block mt-4">
				<h1 className="text-center">{t("formTitle")}</h1>
				<RadioGroup
					name="scheduleType"
					options={[
						{
							label: t("options.asap"),
							value: "asap",
						},
						{
							label: t("options.schedule"),
							value: "schedule",
						},
					]}
					control={control}
				/>
				{scheduleType === "schedule" && (
					<div
						id="datetime-container"
						className="d-flex justify-content-center mt-3"
					>
						<div className="mx-2">
							<Calendar
								name="date"
								minDate={getCurrentOrNextDate()}
								control={control}
								label={t("date.label")}
								rules={{
									required: t("date.rules.required"),
								}}
								enableDates={enableDates}
								required
								onChange={handleDateChange}
							/>
						</div>
						<div>
							<Form.Label htmlFor="time">
								{t("time.label")} <span className="text-danger">*</span>
							</Form.Label>
							<div className="d-flex align-items-center">
								<Select
									options={timeOptions}
									name="time"
									control={control}
									disabled={disableTime}
									required
									rules={{
										required: t("time.rules.required"),
									}}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
			<FormNavButtons nextLabel={nextLabel} />
		</Form>
	);
}

export default CallbackForm;
