import { useLocation, useNavigate } from "react-router-dom";
import * as r from "../routes";
import { useSession } from "../../components/Context/SessionProvider";
import useSessionData from "../useSessionData/useSessionData";
import { useUrlParams } from "../../components/Context";

const useNavigateHelpers = () => {
	const { routesToSkip } = useSession();
	const { leadQueryStr, channelQueryStr } = useUrlParams();
	const { has_income } = useSessionData();
	const location = useLocation();
	const navigate = useNavigate();

	/**
	 * Returns an array of skipped routes, such as:
	 * - routes that collect data that were already supplied in the short form
	 * - Income Sources route if the user has no income.
	 *
	 * @param {boolean} hideShortFormOnlyRoutes - Determines if the array of skipped routes should ONLY include routes that represent data that were collected in the short form
	 * - if true: omits Income Sources form the array
	 * - if false: includes Income Sources in the array (if the user )
	 *
	 * @returns {string[]} - An array of route paths
	 */
	const skipRoutes = (hideShortFormOnlyRoutes = false) => {
		let skipRoutes: string[] = [];

		if (!hideShortFormOnlyRoutes && has_income === false) {
			skipRoutes.push(r.INCOME_SOURCES_PATH);
		}

		if (
			!hideShortFormOnlyRoutes &&
			has_income === true &&
			skipRoutes.includes(r.INCOME_SOURCES_PATH)
		) {
			skipRoutes = skipRoutes.filter(
				(route) => route === r.INCOME_SOURCES_PATH
			);
		}

		if (routesToSkip.applicant === true) {
			skipRoutes.push(r.APPLICANT_PATH);
		}

		if (routesToSkip.debtAmount === true) {
			skipRoutes.push(r.DEBT_AMOUNT_PATH);
		}

		if (routesToSkip.paymentStatus === true) {
			skipRoutes.push(r.PAYMENT_STATUS_PATH);
		}

		return skipRoutes;
	};

	/**
	 * Returns an array of routes, where certain routes may be filtered out. These routes include:
	 * - Routes that represent data that were already collected in the short form
	 * - Income Sources route, if the user answers that they have no income
	 *
	 * @param {boolean} hideShortFormOnlyRoutes - Determines if only the routes that represent data that were collected in the short form should be removed.
	 * - if true: Only the short form routes may be removed
	 * - if false: Both short form routes and Income Sources may be removed.
	 *
	 * @returns {string[]} - An array of route paths
	 */
	const filterSkippedRoutes = (hideShortFormOnlyRoutes = false) => {
		const routesToSkip = skipRoutes(hideShortFormOnlyRoutes);

		let paths = r.pathsOrder;
		paths = paths.filter((path) => !routesToSkip.includes(path));

		return paths;
	};

	const getNextPath = () => {
		const routes = filterSkippedRoutes();

		const nextIndex =
			routes.findIndex((route) => route === location.pathname) + 1;

		return routes[nextIndex]
			? `${routes[nextIndex]}${leadQueryStr}${channelQueryStr}`
			: "";
	};

	const getPrevPath = () => {
		const routes = filterSkippedRoutes();

		const prevIndex =
			routes.findIndex((route) => route === location.pathname) - 1;

		return routes[prevIndex]
			? `${routes[prevIndex]}${leadQueryStr}${channelQueryStr}`
			: "";
	};

	const navigateNextPrompt = () => {
		const nextPath = getNextPath();
		return navigate(nextPath);
	};

	const navigatePrevPrompt = () => {
		const prevPath = getPrevPath();

		if (!prevPath) {
			// if no prevPath, just go back via browser history
			return navigate(-1);
		}

		return navigate(prevPath);
	};

	return {
		getNextPath,
		navigateNextPrompt,
		getPrevPath,
		navigatePrevPrompt,
		skipRoutes,
		filterSkippedRoutes,
	};
};

export default useNavigateHelpers;
