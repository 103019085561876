import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CURRENCY_REGEX } from "../../../../utils/currencyUtils";

import TextFieldGroup from "../../../Common/TextFieldGroup/TextFieldGroup";

export default function VehicleForm({
	control,
	name,
	index,
	deleteVehicle,
}: any) {
	const { t: global } = useTranslation("global");
	const { t } = useTranslation("expenses");

	return (
		<Card className="p-3 mb-4 form-group">
			<div className="form-group-label">
				{t("car.formTitle")} #{index + 1}
			</div>

			<div className="d-flex align-items-start justify-content-between">
				<Form.Group>
					<TextFieldGroup
						rules={{
							pattern: {
								value: CURRENCY_REGEX,
								message: global("rules.dollarAmount"),
							},
							max: {
								value: 10000,
								message: global("rules.max", { amount: 10000 }),
							},
							min: {
								value: 0,
								message: global("rules.min"),
							},
						}}
						label={t("car.monthlyPayment.label")}
						prefix="$"
						name={`${name}.monthly_payment`}
						type="number"
						className="field-xs"
						control={control}
					/>
				</Form.Group>
				{index !== 0 && (
					<Button
						variant="link"
						className="all-caps-link p-0"
						onClick={() => deleteVehicle(index + 1)}
					>
						<FontAwesomeIcon icon="xmark" className="d-inline-block" />
					</Button>
				)}
			</div>
		</Card>
	);
}
