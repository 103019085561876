import { useEffect } from "react";

import axios from "axios";
import { PostIntentPayload, PostIntentResponse } from "./Types";
import { useSession } from "../components/Context/SessionProvider";

const instance = axios.create({
	baseURL: process.env.REACT_APP_DFCLIENT_URL as string,
	timeout: 30000, // 30 seconds, default is 0 (no timeout)
	method: "post",
});

export const AxiosInterceptor = ({ children }: { children: JSX.Element }) => {
	const { error, setError } = useSession();

	useEffect(() => {
		const handleResponse = (response: any) => {
			if (error) {
				setError(false);
			}

			return response;
		};

		const handleError = (error: any) => {
			setError(true);
			return Promise.reject(error);
		};

		const interceptor = instance.interceptors.response.use(
			handleResponse,
			handleError
		);
		return () => instance.interceptors.response.eject(interceptor);
	}, [error, setError]);

	return children;
};

export const postData = (
	data: PostIntentPayload
): Promise<PostIntentResponse> => instance({ data });
