/** getPercentComplete: Returns the percentComplete value
 * @param requestCallback - (default: `false`) If the page is on the Request Callback page
 * @param currentIndex - The current index of the page that the user is on
 * @param allRoutes - The array of all pages
 * @param pathname - The current path name.
 * Returns a percentage rounded to the nearest hundredth.
 */
export const getPercentComplete = ({
	requestCallback = false,
	currentIndex,
	allRoutes,
	pathname,
}: {
	requestCallback: boolean;
	currentIndex: number;
	allRoutes: string[];
	pathname: string;
}) => {
	const idx = requestCallback
		? currentIndex
		: (allRoutes.findIndex((route) => route === pathname) || 0) + 1;
	const total = allRoutes.length;

	const percent = (idx / total) * 100;
	return Math.round(percent * 100) / 100;
};
