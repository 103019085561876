export const INITIALIZE_INTENT = "initialize_session";
export const WELCOME_INTENT = "initialize_welcome_info";
export const CHANNEL_PROVIDED_INTENT = "applicant_channel_provided";
export const TRUSTED_FORM_URL_INTENT = "applicant_trusted_form_url_provided";
export const PROFILE_PROVIDED_INTENT = "applicant_profile_provided";
export const DEBT_TYPES_PROVIDED_INTENT = "applicant_debt_type_provided";
export const PAYMENT_STATUS_PROVIDED_INTENT =
	"applicant_payment_status_provided";
export const ASSISTANCE_REASON_PROVIDED_INTENT = "applicant_hardship_provided";
export const DEBT_AMOUNT_PROVIDED_INTENT = "applicant_debt_amount_provided";
export const HOUSEHOLD_PROVIDED_INTENT = "applicant_household_provided";
export const HAS_INCOME_PROVIDED_INTENT = "applicant_has_income_provided";
export const INCOME_PROVIDED_INTENT = "applicant_income_provided";
export const EXPENSES_PROVIDED_INTENT = "applicant_expenses_provided";
export const CALL_BACK_PROVIDED_INTENT = "applicant_callback_provided";
export const STATUS_INTENT = "applicant_status_fields_provided";
export const INITIALIZE_STATUS_PROVIDED_INTENT =
	"applicant_initialize_status_provided";
