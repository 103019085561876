import axios from "axios";
import { getCountry } from "../getCountry";

const key = process.env.REACT_APP_GEOCODE_API_KEY;

interface Address {
	long_name: string;
	short_name: string;
	types: string[];
}

interface Response {
	results: {
		address_components: Address[];
		types: string[];
	}[];
	status: string;
}

export const validateZipCode = async (zipCode?: string) => {
	const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=${key}`;

	if (!zipCode) {
		throw new Error("required");
	}

	const res = await axios.get<Response>(url);
	const { status, results } = res.data;

	if (status === "ZERO_RESULTS" && !results.length) {
		throw new Error("invalid");
	}

	// checks to make sure there is a match to the correct country and that the match has been identified as a postal code
	const isCorrectCountry = results.some((result) => {
		return (
			result.address_components.some((address) => {
				return (
					address.short_name === getCountry() &&
					address.types.includes("country")
				);
			}) && result.types.includes("postal_code")
		);
	});

	if (!isCorrectCountry) {
		throw new Error("invalid");
	}

	if (status !== "OK") {
		throw new Error("requestError");
	}
};
