import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ChatbotMessage from "./ChatbotMessage";
import TextField from "../../Common/TextField/TextField";
import useIntentHelpers from "../../../utils/usePostIntent/usePostIntent";
import { ChatMessage, Message } from "../../../utils/Types";
import { useSession } from "../../Context/SessionProvider";
import { WELCOME_INTENT } from "../../../utils/intents";
import { useChatbot } from "../../Context/ChatbotProvider";
import "./Chatbot.scss";

export default function ChatbotWidget() {
	const { closeChatbot } = useChatbot();
	const { postIntent } = useIntentHelpers();
	const { chatMessages, setChatMessages, chatLoading } = useSession();
	const [userMessage, setUserMessage] = useState<ChatMessage>();

	useEffect(() => {
		if (!chatMessages.length) {
			postIntent({
				text: WELCOME_INTENT,
				toggleLoadingState: false,
				toggleChatLoadingState: true,
			});
		}
	}, []);

	const { handleSubmit, control, reset, watch } = useForm<Message>({
		defaultValues: {
			text: "",
		},
	});

	const input = watch("text");

	const submitForm = (data: Message) => {
		if (data) {
			const message: ChatMessage = {
				Message: {
					Text: data,
					User: true,
				},
			};

			setUserMessage(message);
			reset();
		}
	};

	useEffect(() => {
		// updates the chat queue with the user's message
		setChatMessages([...chatMessages, userMessage]);
	}, [userMessage]);

	const scrollAnchor = useRef<HTMLDivElement>(null);

	useEffect(() => {
		scrollAnchor.current?.scrollIntoView({
			behavior: "smooth",
			block: "nearest",
			inline: "start",
		});
	}, [chatMessages]);

	useEffect(() => {
		// makes a post request on user's input submission
		if (
			chatMessages[chatMessages.length - 1] === userMessage &&
			userMessage?.Message?.Text
		) {
			postIntent({
				...userMessage.Message.Text,
				toggleLoadingState: false,
				toggleChatLoadingState: true,
			});
		}
	}, [chatMessages]);

	const { t } = useTranslation("global");

	return (
		<div className="chatbot-widget m-xl-5">
			<Row>
				<Col className="chatbot-header bg-primary sticky d-flex align-items-center jusitfy-content-between">
					<div className="d-flex align-items-center ">
						<div className="chatbot-icon me-2 text-primary">
							<FontAwesomeIcon icon={["fas", "headset"]} />
						</div>
						Virtual Counselor Chat
					</div>
					<Button
						variant="primary"
						className="chatbot-close-btn ms-auto"
						onClick={closeChatbot}
					>
						<FontAwesomeIcon
							className="text-white fw-bold"
							icon={["fas", "xmark"]}
						/>
					</Button>
				</Col>
			</Row>
			<Row>
				<Col className="chatbot-body">
					{chatMessages?.map(
						(message, index) =>
							message && (
								<ChatbotMessage
									key={index}
									firstMessage={index === 0}
									user={message.Message?.User === true}
								>
									{message.Message?.Text?.text || ""}
								</ChatbotMessage>
							)
					)}
					{chatLoading && (
						<ChatbotMessage
							loadingResponse={true}
							firstMessage={chatMessages.length === 1}
						/>
					)}
					<div ref={scrollAnchor} />
				</Col>
			</Row>
			<Form onSubmit={handleSubmit(submitForm)}>
				<Row className="chatbot-footer py-2">
					<Col className="pe-0">
						<TextField
							placeholder={t("placeholder")}
							type="text"
							name="text"
							control={control}
							className="rounded-pill"
						/>
					</Col>
					<Col xs="auto">
						<Button
							variant="primary"
							type="submit"
							className="rounded-circle p-0 chatbot-submit-btn"
							disabled={!input}
						>
							<FontAwesomeIcon icon={["fas", "paper-plane"]} />
						</Button>
					</Col>
				</Row>
			</Form>
		</div>
	);
}
