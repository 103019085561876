import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Card } from "react-bootstrap";
import { useController, useWatch } from "react-hook-form";
import InvalidFeedback from "../InvalidFeedback/InvalidFeedback";

interface CheckboxGroupProps {
	options: { label: string; icon: string }[];
	control: any;
	name: string;
	rules: any;
}

export default function CheckboxGroup({
	options,
	control,
	name,
	rules,
}: CheckboxGroupProps) {
	const {
		field: { value, onChange, ...field },
		fieldState: { error },
	} = useController({ name, control, rules, defaultValue: [] });

	const checkboxIds = useWatch({ control, name: name }) || [];

	const handleChange = (value: string) => {
		const newArray = [...checkboxIds];

		if (newArray.length) {
			const index = newArray.findIndex((i) => i === value);
			if (index === -1) {
				newArray.push(value);
			} else {
				newArray.splice(index, 1);
			}
		} else {
			newArray.push(value);
		}

		onChange(newArray);
	};

	return (
		<>
			<div className="d-flex justify-content-center debt-types">
				{options.map((option: any) => (
					<div key={option.value} className="d-flex text-center">
						<Card
							className="p-3 mx-2"
							onClick={() => handleChange(option.value)}
						>
							<FontAwesomeIcon
								icon={option.icon}
								className="text-primary"
								size="lg"
							/>

							<Form.Check
								checked={
									Array.isArray(value) &&
									value?.some((checked) => checked === option.value)
								}
								label={option.label}
								{...field}
								onChange={() => handleChange(option.value)}
								type="checkbox"
							/>
						</Card>
					</div>
				))}
			</div>
			<InvalidFeedback className="text-center mt-2 mb-4">
				{error?.message}
			</InvalidFeedback>
		</>
	);
}
