import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CURRENCY_REGEX } from "../../../../utils/currencyUtils";

import type { HealthParams } from "../../../../utils/Types";
import useBudgetHelpers from "../../../../utils/useBudgetHelpers/useBudgetHelpers";
import useNavigateHelpers from "../../../../utils/useNavigateHelpers/useNavigateHelpers";
import usePostIntent from "../../../../utils/usePostIntent/usePostIntent";
import FormNavButtons from "../../../Common/FormNavButtons/FormNavButtons";
import FormTitle from "../../../Common/FormTitle/FormTitle";
import TextFieldGroup from "../../../Common/TextFieldGroup/TextFieldGroup";

export function Health() {
	const { t: global } = useTranslation("global");
	const { t } = useTranslation("expenses");
	const { postFormData, postStatus } = usePostIntent();
	const { generateDefaultValues } = useBudgetHelpers();

	const {
		handleSubmit,
		control,
		formState: { isDirty },
	} = useForm<HealthParams>({
		defaultValues: generateDefaultValues<HealthParams>([
			"Doctor Bills",
			"Health Insurance",
			"Life Insurance",
			"Prescription/Medication",
		]),
	});

	const { navigateNextPrompt } = useNavigateHelpers();

	const submitForm = (data: HealthParams) => {
		// It will pull CATEGORY from utils/Expenses -> getExpenses() function
		// Because some fields are under Medical category and some under Insurance
		if (isDirty) {
			postFormData(data).then(() => {
				postStatus();
				navigateNextPrompt();
			});
		} else {
			postStatus();
			navigateNextPrompt();
		}
	};

	return (
		<>
			<FormTitle faIcon={["fas", "briefcase-medical"]}>
				{t("health.formTitle")}
			</FormTitle>
			<p className="text-navy-blue italic text-small">{global("monthly")}</p>
			<p>{t("health.description")}</p>
			<Form onSubmit={handleSubmit(submitForm)} noValidate>
				<Row>
					<Col xs={6} lg>
						<TextFieldGroup
							rules={{
								pattern: {
									value: CURRENCY_REGEX,
									message: global("rules.dollarAmount"),
								},
								max: {
									value: 100000,
									message: global("rules.max", { amount: 100000 }),
								},
								min: {
									value: 0,
									message: global("rules.min"),
								},
							}}
							label={t("health.healthInsurance.label")}
							name="Health Insurance"
							prefix="$"
							className="field-xs"
							type="number"
							control={control}
						/>
					</Col>

					<Col xs={6} lg>
						<TextFieldGroup
							rules={{
								pattern: {
									value: CURRENCY_REGEX,
									message: global("rules.dollarAmount"),
								},
								max: {
									value: 100000,
									message: global("rules.max", { amount: 100000 }),
								},
								min: {
									value: 0,
									message: global("rules.min"),
								},
							}}
							label={t("health.doctorBills.label")}
							name="Doctor Bills"
							prefix="$"
							className="field-xs"
							type="number"
							control={control}
						/>
					</Col>
				</Row>
				<Row className="mt-4">
					<Col xs={6} lg>
						<TextFieldGroup
							rules={{
								pattern: {
									value: CURRENCY_REGEX,
									message: global("rules.dollarAmount"),
								},
								max: {
									value: 100000,
									message: global("rules.max", { amount: 100000 }),
								},
								min: {
									value: 0,
									message: global("rules.min"),
								},
							}}
							label={t("health.prescriptionMedications.label")}
							name="Prescription/Medication"
							prefix="$"
							className="field-xs"
							type="number"
							control={control}
						/>
					</Col>

					<Col xs={6} lg>
						<TextFieldGroup
							rules={{
								pattern: {
									value: CURRENCY_REGEX,
									message: global("rules.dollarAmount"),
								},
								max: {
									value: 100000,
									message: global("rules.max", { amount: 100000 }),
								},
								min: {
									value: 0,
									message: global("rules.min"),
								},
							}}
							label={t("health.lifeInsurance.label")}
							name="Life Insurance"
							prefix="$"
							className="field-xs"
							type="number"
							control={control}
						/>
					</Col>
				</Row>
				<FormNavButtons />
			</Form>
		</>
	);
}
