import { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import useBudgetHelpers from "../../../../utils/useBudgetHelpers/useBudgetHelpers";
import TextFieldGroup from "../../../Common/TextFieldGroup/TextFieldGroup";
import FormNavButtons from "../../../Common/FormNavButtons/FormNavButtons";
import useNavigateHelpers from "../../../../utils/useNavigateHelpers/useNavigateHelpers";
import FormTitle from "../../../Common/FormTitle/FormTitle";
import {
	VehicleParams,
	Frequencies,
	FrequencyOptions,
} from "../../../../utils/Types";
import { useTranslation } from "react-i18next";
import usePostIntent from "../../../../utils/usePostIntent/usePostIntent";
import { EXPENSES_PROVIDED_INTENT } from "../../../../utils/intents";
import ButtonGroup from "../../../Common/ButtonGroup/ButtonGroup";
import normalize from "../../../../utils/normalizeCurrency/normalizeCurrency";
import {
	MaxAmount,
	maxAmountAnnually,
	maxAmountBiannually,
	maxAmountMonthly,
} from "../../../../utils/maxAmounts";
import { CURRENCY_REGEX } from "../../../../utils/currencyUtils";

interface VehicleFrequencies {
	["Car Insurance Frequency"]?: Frequencies;
	["Repairs/Maintenance Frequency"]?: Frequencies;
	["Gas Frequency"]?: Frequencies;
	["Parking/Tolls Frequency"]?: Frequencies;
}

export function VehicleExpenses() {
	const { t: global } = useTranslation("global");
	const { t } = useTranslation("expenses");
	const {
		getBudgetElement,
		formatBudgetElement,
		addBudgetElements,
		getMonthlyAmount,
	} = useBudgetHelpers();
	const { postStatus, postIntent } = usePostIntent();

	const insurance = getBudgetElement("Car Insurance");
	const maintenance = getBudgetElement("Repairs/Maintenance");
	const gas = getBudgetElement("Gas");
	const parking = getBudgetElement("Parking/Tolls");

	const frequencyOptions: FrequencyOptions[] = [
		{
			label: global("frequencyOptions.weekly"),
			value: 52,
		},
		{
			label: global("frequencyOptions.monthly"),
			value: 12,
		},
		{
			label: global("frequencyOptions.annually"),
			value: 1,
		},
	];

	const defaultValues = {
		["Car Insurance"]: insurance?.amount ? `${insurance.amount}` : "",
		["Car Insurance Frequency"]: insurance?.frequency || 12,
		["Repairs/Maintenance"]: maintenance?.amount ? `${maintenance.amount}` : "",
		["Repairs/Maintenance Frequency"]: maintenance?.frequency || 12,
		Gas: gas?.amount ? `${gas?.amount}` : "",
		["Gas Frequency"]: gas?.frequency || 12,
		["Parking/Tolls"]: parking?.amount ? `${parking.amount}` : "",
		["Parking/Tolls Frequency"]: parking?.frequency || 12,
	};

	const [insuranceMaxAmount, setInsuranceMaxAmount] =
		useState<MaxAmount>(maxAmountMonthly);
	const [maintenanceMaxAmount, setMaintenanceMaxAmount] =
		useState<MaxAmount>(maxAmountMonthly);
	const [gasMaxAmount, setGasMaxAmount] = useState<MaxAmount>(maxAmountMonthly);
	const [parkingMaxAmount, setParkingMaxAmount] =
		useState<MaxAmount>(maxAmountMonthly);

	const {
		handleSubmit,
		control,
		watch,
		formState,
		formState: { isDirty },
		trigger,
	} = useForm<VehicleParams & VehicleFrequencies>({
		defaultValues,
	});

	const formDataInsuranceFreq = watch("Car Insurance Frequency");
	const formDataParkingFreq = watch("Parking/Tolls Frequency");
	const formDataMaintenanceFreq = watch("Repairs/Maintenance Frequency");
	const formDataGasFreq = watch("Gas Frequency");

	useEffect(() => {
		if (formDataInsuranceFreq === 12 && insuranceMaxAmount.amount !== 10000) {
			setInsuranceMaxAmount(maxAmountMonthly);
		}

		if (formDataInsuranceFreq === 1 && insuranceMaxAmount.amount !== 120000) {
			setInsuranceMaxAmount(maxAmountAnnually);
		}

		if (formDataInsuranceFreq === 2 && insuranceMaxAmount.amount !== 60000) {
			setInsuranceMaxAmount(maxAmountBiannually);
		}

		if (formDataParkingFreq === 1 && parkingMaxAmount.amount !== 120000) {
			setParkingMaxAmount(maxAmountAnnually);
		}

		if (formDataParkingFreq === 12 && parkingMaxAmount.amount !== 10000) {
			setParkingMaxAmount(maxAmountMonthly);
		}

		if (
			formDataMaintenanceFreq === 1 &&
			maintenanceMaxAmount.amount !== 120000
		) {
			setMaintenanceMaxAmount(maxAmountAnnually);
		}

		if (
			formDataMaintenanceFreq === 12 &&
			maintenanceMaxAmount.amount !== 10000
		) {
			setMaintenanceMaxAmount(maxAmountMonthly);
		}

		if (formDataGasFreq === 1 && gasMaxAmount.amount !== 120000) {
			setGasMaxAmount(maxAmountAnnually);
		}

		if (formDataGasFreq === 12 && gasMaxAmount.amount !== 10000) {
			setGasMaxAmount(maxAmountMonthly);
		}
	}, [
		formState,
		formDataGasFreq,
		formDataInsuranceFreq,
		formDataMaintenanceFreq,
		formDataParkingFreq,
		gasMaxAmount,
		insuranceMaxAmount,
		parkingMaxAmount,
		maintenanceMaxAmount,
	]);

	useEffect(() => {
		trigger();
	}, [
		gasMaxAmount,
		insuranceMaxAmount,
		parkingMaxAmount,
		maintenanceMaxAmount,
	]);

	const getTotalMonthly = ({
		["Car Insurance"]: carIns = "0",
		["Car Insurance Frequency"]: carInsFreq = 12,
		["Repairs/Maintenance"]: repairs = "0",
		["Repairs/Maintenance Frequency"]: repairsFreq = 12,
		Gas: gas = "0",
		["Gas Frequency"]: gasFreq = 12,
		["Parking/Tolls"]: parking = "0",
		["Parking/Tolls Frequency"]: parkingFreq = 12,
	}: VehicleParams & VehicleFrequencies) => {
		const insTotal = getMonthlyAmount(+carIns, carInsFreq);
		const repairsTotal = getMonthlyAmount(+repairs, repairsFreq);
		const gasTotal = getMonthlyAmount(+gas, gasFreq);
		const parkingTotal = getMonthlyAmount(+parking, parkingFreq);
		return insTotal + repairsTotal + gasTotal + parkingTotal;
	};

	const [total, setTotal] = useState(getTotalMonthly(defaultValues));

	useEffect(() => {
		if (formState.isValid && !formState.isValidating) {
			setTotal(getTotalMonthly(watch()));
		}
	}, [formState]);

	const { navigateNextPrompt } = useNavigateHelpers();

	const submitForm = (data: VehicleParams & VehicleFrequencies) => {
		if (isDirty) {
			const CATEGORY = "Transportation";
			const insurance = formatBudgetElement({
				subCategory: "Car Insurance",
				category: CATEGORY,
				frequency: data["Car Insurance Frequency"],
				amount: data["Car Insurance"],
			});

			const maintenance = formatBudgetElement({
				subCategory: "Repairs/Maintenance",
				category: CATEGORY,
				frequency: data["Repairs/Maintenance Frequency"],
				amount: data["Repairs/Maintenance"],
			});

			const gas = formatBudgetElement({
				subCategory: "Gas",
				category: CATEGORY,
				frequency: data["Gas Frequency"],
				amount: data["Gas"],
			});

			const parking = formatBudgetElement({
				subCategory: "Parking/Tolls",
				category: CATEGORY,
				frequency: data["Parking/Tolls Frequency"],
				amount: data["Parking/Tolls"],
			});

			const vehicleExpenses = [insurance, maintenance, gas, parking];

			postIntent({
				parameters: {
					applicant_expenses: addBudgetElements(vehicleExpenses),
				},
				text: EXPENSES_PROVIDED_INTENT,
			}).then(() => {
				postStatus();
				navigateNextPrompt();
			});
		} else {
			postStatus();
			navigateNextPrompt();
		}
	};

	return (
		<>
			<FormTitle faIcon={["fas", "car"]}>
				{t("vehicleExpenses.formTitle")}
			</FormTitle>
			<p className="text-navy-blue italic text-small">{global("monthly")}</p>
			<Form onSubmit={handleSubmit(submitForm)} noValidate>
				<p className="fw-light text-navy-blue">
					{t("vehicleExpenses.description")}
				</p>

				<Row className="mt-3">
					<Col xs={12} md={5} lg={3}>
						<Form.Group>
							<TextFieldGroup
								rules={{
									pattern: {
										value: CURRENCY_REGEX,
										message: global("rules.dollarAmount"),
									},
									max: {
										value: gasMaxAmount.amount,
										message: global(gasMaxAmount.message, {
											amount: gasMaxAmount.amount,
										}),
									},
									min: {
										value: 0,
										message: global("rules.min"),
									},
								}}
								label={t("vehicleExpenses.gas.label")}
								prefix="$"
								name="Gas"
								type="number"
								className="field-xs"
								control={control}
							/>
						</Form.Group>
					</Col>
					<Col xs={12} md={7} lg={9}>
						<ButtonGroup
							label={t("vehicleExpenses.frequency.label")}
							options={frequencyOptions.slice(0, 2)}
							control={control}
							name={"Gas Frequency"}
							className="field-md"
						/>
					</Col>
				</Row>
				<Row className="mt-3">
					<Col xs={12} md={5} lg={3}>
						<Form.Group>
							<TextFieldGroup
								rules={{
									pattern: {
										value: CURRENCY_REGEX,
										message: global("rules.dollarAmount"),
									},
									max: {
										value: parkingMaxAmount.amount,
										message: global(parkingMaxAmount.message, {
											amount: parkingMaxAmount.amount,
										}),
									},
									min: {
										value: 0,
										message: global("rules.min"),
									},
								}}
								label={t("vehicleExpenses.parkingTolls.label")}
								prefix="$"
								name="Parking/Tolls"
								type="number"
								className="field-xs"
								control={control}
							/>
						</Form.Group>
					</Col>
					<Col xs={12} md={7} lg={9}>
						<ButtonGroup
							label={t("vehicleExpenses.frequency.label")}
							options={frequencyOptions.slice(0, 2)}
							control={control}
							name={"Parking/Tolls Frequency"}
							className="field-md"
						/>
					</Col>
				</Row>
				<Row className="mt-3">
					<Col xs={12} md={5} lg={3}>
						<Form.Group>
							<TextFieldGroup
								rules={{
									pattern: {
										value: CURRENCY_REGEX,
										message: global("rules.dollarAmount"),
									},
									max: {
										value: maintenanceMaxAmount.amount,
										message: global(maintenanceMaxAmount.message, {
											amount: maintenanceMaxAmount.amount,
										}),
									},
									min: {
										value: 0,
										message: global("rules.min"),
									},
								}}
								label={t("vehicleExpenses.maintenance.label")}
								prefix="$"
								name="Repairs/Maintenance"
								type="number"
								className="field-xs"
								control={control}
							/>
						</Form.Group>
					</Col>
					<Col xs={12} md={7} lg={9}>
						<ButtonGroup
							label={t("vehicleExpenses.frequency.label")}
							options={frequencyOptions.slice(1, 3)}
							control={control}
							name={"Repairs/Maintenance Frequency"}
							className="field-md"
						/>
					</Col>
				</Row>
				<Row className="mt-3">
					<Col xs={12} md={5} lg={3}>
						<Form.Group>
							<TextFieldGroup
								rules={{
									pattern: {
										value: CURRENCY_REGEX,
										message: global("rules.dollarAmount"),
									},
									max: {
										value: insuranceMaxAmount.amount,
										message: global(insuranceMaxAmount.message, {
											amount: insuranceMaxAmount.amount,
										}),
									},
									min: {
										value: 0,
										message: global("rules.min"),
									},
								}}
								label={t("vehicleExpenses.insurance.label")}
								prefix="$"
								name="Car Insurance"
								type="number"
								className="field-xs"
								control={control}
							/>
						</Form.Group>
					</Col>
					<Col xs={12} md={7} lg={9}>
						<ButtonGroup
							label={t("vehicleExpenses.frequency.label")}
							options={[
								frequencyOptions[1],
								{
									label: global("frequencyOptions.biannually"),
									value: 2,
								},
								frequencyOptions[2],
							]}
							control={control}
							name={"Car Insurance Frequency"}
							className="field-md"
						/>
					</Col>
				</Row>
				<Row>
					<div className="d-flex align-items-center mt-4">
						<div>{t("vehicleExpenses.total")}</div>
						<div
							className="ms-2 bg-negative bg-opacity-25 py-2 px-3 rounded"
							style={{ wordBreak: "break-all" }}
						>
							{normalize(total, true)}
						</div>
					</div>
				</Row>
				<FormNavButtons />
			</Form>
		</>
	);
}
