import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { postData as post } from "../axiosConfig";

import { useUrlParams } from "../../components/Context";
import { useSession } from "../../components/Context/SessionProvider";
import { useSessionTimeout } from "../../components/Context/SessionTimeoutContext";
import { useStatus } from "../../components/Context/StatusProvider";
import * as intents from "../intents";
import { REQUEST_CALLBACK_PATH, VERSION_PATH } from "../routes";
import type {
	ApplicantParams,
	DebtAmountParams,
	ExpensesParams,
	FormExpenses,
	HandshakeParameters,
	HasIncomeParams,
	Message,
	PostIntentPayload,
	PostIntentResponse,
	SessionParams,
	SkippableRoutes,
	Sources,
	SubCategories,
	TrustedFormParams,
} from "../Types";
import useBudgetHelpers from "../useBudgetHelpers/useBudgetHelpers";
import { getCountry } from "../getCountry";
import useSessionData from "../useSessionData/useSessionData";
import { config } from "../countryConfig";
import { VT_TO_I18N_LANGUAGE_MAP } from "../languageMap";

interface PostIntent extends Message {
	parameters?: {
		[key: string]: any;
		currentPage?: string;
	};
}

interface LoadingStates {
	toggleLoadingState?: boolean;
	toggleChatLoadingState?: boolean;
}

export default function usePostIntent() {
	const {
		setSession,
		setLoading,
		setInitializing,
		chatMessages,
		setChatMessages,
		setChatLoading,
		isConsentedUser,
		setIsConsentedUser,
		setRoutesToSkip,
		consentNeeded,
	} = useSession();
	const { leadId, channel } = useUrlParams();
	const { resetTimer } = useSessionTimeout();
	const { i18n } = useTranslation();
	const currentLanguage = i18n.language;
	const { formatBudgetElement, addBudgetElements } = useBudgetHelpers();
	const { pathname } = useLocation();
	const { calcStatus, setIsAbandoned, setIsLocked } = useStatus();
	const { debt_amount, has_income } = useSessionData();

	const redirect = () => {
		const { redirectUrl } = config;
		const noRedirect = process.env.REACT_APP_PREVENT_REDIRECT === "true";

		if (noRedirect) {
			console.error(new Error(`Redirecting to ${redirectUrl}`));
		} else if (pathname !== VERSION_PATH) {
			window.location.replace(redirectUrl as string);
		}
	};

	const buildPayload = (
		intent?: string,
		parameters: any = {},
		language = currentLanguage
	): PostIntentPayload => {
		const languageCode =
			VT_TO_I18N_LANGUAGE_MAP.es === language
				? "es-419"
				: language.toLowerCase();

		const payload: {
			sessionId: string;
			queryInput: {
				languageCode: string;
				input: { text: { text?: string } };
			};
		} = {
			sessionId: leadId ?? "",
			queryInput: {
				languageCode,
				input: {
					text: {
						text: intent,
					},
				},
			},
		};

		if (Object.keys(parameters).length > 0) {
			return {
				...payload,
				queryParams: {
					parameters,
				},
			};
		}

		return payload;
	};

	const processResponse = (
		response: PostIntentResponse,
		receiveResponseMessages = true
	) => {
		const allIntents = Object.values(intents) as string[];
		const allParams = Object.keys(response.data.parameters);

		allParams.forEach((param) => {
			// filter out invalid parameter values
			if (
				allIntents.includes(
					response.data.parameters[param as keyof SessionParams] as string
				)
			) {
				(response.data.parameters[param as keyof SessionParams] as string) = "";
			}
		});

		// if (Objresponse.data?.match?.resolved_input)
		setSession(response.data.parameters);

		if (
			receiveResponseMessages &&
			Array.isArray(response.data.response_messages)
		) {
			setChatMessages([...chatMessages, ...response.data.response_messages]);
		}
		return response;
	};

	const postHandshake = async () => {
		setInitializing(true);

		if (!channel) {
			console.log("No channel while initializing");
		}

		try {
			if (pathname === VERSION_PATH) {
				throw new Error("Version page - abort handshake");
			}

			if (!leadId) {
				throw new Error("No leadId");
			}

			const handshakeParameters: HandshakeParameters = {
				lead_id: leadId,
				country: getCountry(),
			};

			if (channel) {
				handshakeParameters.applicant_channel = channel;
			}

			const initResponse = await post(
				buildPayload(intents.INITIALIZE_INTENT, handshakeParameters)
			);

			const ventureTech =
				initResponse.data?.parameters?.vic?.consent?.ventureTech;

			if (!ventureTech) {
				console.error("No ventureTech consent while initializing");
				redirect();
				return Promise.reject(new Error("No VentureTech consent"));
			}

			const collectPII =
				initResponse.data?.parameters?.vic?.consent?.collectPII;

			if (collectPII?.attributes?.consentDate) {
				setIsConsentedUser(true);
			}

			const languageCode = ventureTech.attributes?.VTResponse?.Language;

			if (languageCode) {
				i18n.changeLanguage(VT_TO_I18N_LANGUAGE_MAP[languageCode]);
			}

			const consumer = ventureTech.consumer;
			const parameters: ApplicantParams = {
				applicant_first_name: consumer?.firstName,
				applicant_last_name: consumer?.lastName,
				applicant_cell_phone: consumer?.attributes?.phone?.number,
				applicant_zip_code: consumer?.attributes?.zip,
			};

			const routesToSkip: SkippableRoutes = {
				applicant: false,
				debtAmount: false,
				paymentStatus: false,
			};

			if (
				parameters.applicant_first_name &&
				parameters.applicant_last_name &&
				parameters.applicant_cell_phone &&
				parameters.applicant_zip_code &&
				!consentNeeded
			)
				routesToSkip.applicant = true;

			if (consumer.attributes?.debtAmount) routesToSkip.debtAmount = true;

			if (consumer.attributes?.debtStatus) routesToSkip.paymentStatus = true;

			setRoutesToSkip(routesToSkip);

			const response = await post(
				buildPayload(
					intents.PROFILE_PROVIDED_INTENT,
					parameters,
					VT_TO_I18N_LANGUAGE_MAP[languageCode!]
				)
			);

			processResponse(response, false);
		} catch (err) {
			console.error(err);
		} finally {
			setInitializing(false);
		}

		return Promise.resolve();
	};

	const pushToGTMDataLayer = (response: PostIntentResponse) => {
		const VTConsent = response?.data?.parameters?.vic?.consent?.ventureTech;
		if (VTConsent) {
			window.dataLayer?.push({
				LeadGUID: leadId,
				...VTConsent.attributes?.VTResponse,
			});
		}
	};

	const getTrustedFormUrl = () => {
		const input = document.getElementsByName(
			"xxTrustedFormCertUrl"
		)[0] as HTMLInputElement;
		return input?.value;
	};

	const postTrustedForm = async () => {
		try {
			const TrustedFormData: TrustedFormParams = {
				applicant_trusted_form_url: getTrustedFormUrl(),
			};

			const response = await post(
				buildPayload(intents.TRUSTED_FORM_URL_INTENT, TrustedFormData)
			);

			return response;
		} catch (err) {
			redirect();
			return Promise.reject(err);
		}
	};

	const postIntent = async ({
		text,
		parameters,
		toggleLoadingState = true,
		toggleChatLoadingState = false,
	}: PostIntent & LoadingStates) => {
		resetTimer();

		if (pathname === VERSION_PATH) {
			return Promise.reject(new Error("Version page"));
		}

		if (!leadId) {
			return Promise.reject(new Error("No leadId"));
		}

		let response: PostIntentResponse | undefined;

		const isProvidedIntent = text?.endsWith("_provided");

		// sending debtAmount and hasIncome so that qualification status can be immediately updated in the same call
		const debtAmount = parameters?.debt_amount ?? debt_amount;
		const hasIncome = parameters?.has_income ?? has_income;
		const status = calcStatus({ debtAmount, hasIncome });

		const params = isProvidedIntent ? { ...parameters, ...status } : parameters;

		// checks if the consent message is currently displayed and the user has
		// provided data
		const isUserConsentAgreement =
			pathname !== REQUEST_CALLBACK_PATH &&
			text !== intents.CHANNEL_PROVIDED_INTENT &&
			isProvidedIntent &&
			!isConsentedUser;

		try {
			if (toggleChatLoadingState) {
				setChatLoading(true);
			}

			if (toggleLoadingState) {
				setLoading(true);
			}

			response = await post(buildPayload(text ?? "", params));

			if (isUserConsentAgreement) {
				if (consentNeeded) {
					await postTrustedForm();
				}

				await post(
					buildPayload(intents.INITIALIZE_STATUS_PROVIDED_INTENT, status)
				);
				await post(buildPayload(intents.PROFILE_PROVIDED_INTENT));

				setIsConsentedUser(true);
				pushToGTMDataLayer(response);
			}

			processResponse(response, !isProvidedIntent);
		} catch (err) {
			console.error(err);
		} finally {
			if (toggleLoadingState) setLoading(false);
			if (toggleChatLoadingState) setChatLoading(false);
		}

		return response?.data;
	};

	const postFormData = (
		formData: FormExpenses & { [key in Sources]?: string },
		category?: string,
		type = "Variable" as const,
		frequency = 12 as const
	) => {
		const budgetItems =
			Object.keys(formData).map((data) => {
				const subCategory = data as SubCategories;
				const amount = formData[subCategory];
				return formatBudgetElement({
					category,
					subCategory,
					type,
					frequency,
					amount,
				});
			}) || [];

		const parameters: ExpensesParams = {
			applicant_expenses: addBudgetElements(budgetItems),
		};

		return postIntent({ parameters, text: intents.EXPENSES_PROVIDED_INTENT });
	};

	const sendBeacon = (text?: string, parameters?: any) => {
		const endpoint = process.env.REACT_APP_DFCLIENT_URL || "";
		navigator.sendBeacon(
			endpoint,
			JSON.stringify(buildPayload(text ?? "", parameters))
		);
	};

	const postStatus = async ({
		setToAbandoned = false,
		setToLocked = false,
		beacon = false,
		hasIncome,
		debtAmount,
	}: {
		setToAbandoned?: boolean;
		setToLocked?: boolean;
		beacon?: boolean;
		hasIncome?: HasIncomeParams["has_income"];
		debtAmount?: DebtAmountParams["debt_amount"];
	} = {}) => {
		let statusToSubmit = calcStatus({ hasIncome, debtAmount });

		if (setToAbandoned) {
			setIsAbandoned(true);
			statusToSubmit = { applicant_form_status: "Abandoned" };
		}
		if (setToLocked) {
			setIsLocked(true);
			statusToSubmit = { applicant_form_status: "Locked" };
		}

		if (beacon) {
			sendBeacon(intents.STATUS_INTENT, statusToSubmit);
		} else {
			return await post(buildPayload(intents.STATUS_INTENT, statusToSubmit));
		}
	};

	return {
		postIntent,
		postHandshake,
		postFormData,
		redirect,
		sendBeacon,
		postStatus,
	};
}
