import { Spinner } from "react-bootstrap";

export default function FullPageLoader() {
	return (
		<div
			className="d-flex justify-content-center align-items-center"
			style={{ height: "100vh" }}
		>
			<div className="text-center">
				<Spinner
					variant="primary"
					animation="border"
					style={{ width: "4rem", height: "4rem" }}
					role="status"
				/>
				<span className="visually-hidden">Loading...</span>
			</div>
		</div>
	);
}
