import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { VehicleParams } from "../../../../utils/Types";
import useBudgetHelpers from "../../../../utils/useBudgetHelpers/useBudgetHelpers";
import useNavigateHelpers from "../../../../utils/useNavigateHelpers/useNavigateHelpers";
import usePostIntent from "../../../../utils/usePostIntent/usePostIntent";
import FormNavButtons from "../../../Common/FormNavButtons/FormNavButtons";
import FormTitle from "../../../Common/FormTitle/FormTitle";
import VehicleForm from "./VehicleForm";

export interface AutoPayments {
	vehicles?: {
		monthly_payment: string;
	}[];
}

export function Vehicle() {
	const { t: global } = useTranslation("global");
	const { t } = useTranslation("expenses");
	const { postStatus, postFormData } = usePostIntent();
	const { getValue } = useBudgetHelpers();

	const defaultValues = {
		vehicles: [
			{
				monthly_payment: getValue("Auto/Boat/Bike Payment 1"),
			},
		],
	};

	if (getValue("Auto/Boat/Bike Payment 2")) {
		defaultValues.vehicles.push({
			monthly_payment: getValue("Auto/Boat/Bike Payment 2"),
		});
	}

	if (getValue("Auto/Boat/Bike Payment 3")) {
		defaultValues.vehicles.push({
			monthly_payment: getValue("Auto/Boat/Bike Payment 3"),
		});
	}

	const {
		handleSubmit,
		control,
		formState: { isDirty },
	} = useForm<VehicleParams & AutoPayments>({
		defaultValues,
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: "vehicles",
	});

	const { navigateNextPrompt } = useNavigateHelpers();

	const submitForm = (data: any) => {
		if (isDirty) {
			if (data.vehicles.length) {
				const payments: Array<keyof VehicleParams> = [
					"Auto/Boat/Bike Payment 1",
					"Auto/Boat/Bike Payment 2",
					"Auto/Boat/Bike Payment 3",
				];

				payments.forEach((num, index) => {
					data[num] = data.vehicles[index]?.monthly_payment || 0;
				});

				delete data.vehicles;
			}

			const CATEGORY = "Transportation";
			postFormData(data, CATEGORY).then(() => {
				postStatus();
				navigateNextPrompt();
			});
		} else {
			postStatus();
			navigateNextPrompt();
		}
	};

	const deleteVehicle = (index: 1 | 2 | 3) => {
		remove(index - 1);
	};

	return (
		<>
			<FormTitle faIcon={["fas", "car"]}>{t("car.formTitle")}</FormTitle>
			<p className="text-navy-blue italic text-small">{global("monthly")}</p>
			<Form onSubmit={handleSubmit(submitForm)} noValidate>
				<Row className="align-items-center">
					{fields.map((item, index) => (
						<Col xs={12} lg={4} key={item.id}>
							<VehicleForm
								control={control}
								index={index}
								deleteVehicle={deleteVehicle}
								name={`vehicles.${index}`}
							/>
						</Col>
					))}
					{fields.length < 3 && (
						<Col xs={12} lg={6} xl={4}>
							<Button
								variant="link"
								className="all-caps-link"
								onClick={() => append({ monthly_payment: "" })}
							>
								<FontAwesomeIcon icon="plus" className="d-inline-block me-2" />
								{t("car.addVehicle")}
							</Button>
						</Col>
					)}
				</Row>

				<FormNavButtons />
			</Form>
		</>
	);
}
