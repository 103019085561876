import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { EXPENSES_PROVIDED_INTENT } from "../../../../utils/intents";
import type {
	BudgetElement,
	Frequencies,
	SubCategories,
} from "../../../../utils/Types";
import useBudgetHelpers from "../../../../utils/useBudgetHelpers/useBudgetHelpers";
import useNavigateHelpers from "../../../../utils/useNavigateHelpers/useNavigateHelpers";
import usePostIntent from "../../../../utils/usePostIntent/usePostIntent";
import FormNavButtons from "../../../Common/FormNavButtons/FormNavButtons";
import FormTitle from "../../../Common/FormTitle/FormTitle";
import useSessionData from "../../../../utils/useSessionData/useSessionData";
import OtherExpenseForm from "./OtherExpenseForm";
import { config } from "../../../../utils/countryConfig";

export const FIELD_NAME = "Other Expenses";

export function OtherExpenses() {
	const { t } = useTranslation("expenses");
	const { t: labelTranslation } = useTranslation("expensesFieldLabels");

	const expenseOptions = config.expenses["Other Expenses"].fields.map(
		(field) => {
			field.label = labelTranslation(field.label);
			return field;
		}
	);

	const initialValues: BudgetElement = {
		name: uuidv4(),
		category: "Personal",
		subCategory: "",
		frequency: 12,
		amount: "",
		type: "Variable",
	};

	const { applicant_expenses } = useSessionData();

	const otherExpenses = applicant_expenses?.filter(
		(expense) => expense.category === "Personal" && expense.amount !== 0
	);

	const defaultValues =
		Array.isArray(otherExpenses) && otherExpenses.length ? otherExpenses : [];

	const {
		handleSubmit,
		control,
		watch,
		formState: { isDirty },
		trigger,
	} = useForm<{
		[FIELD_NAME]: BudgetElement[];
	}>({
		defaultValues: {
			[FIELD_NAME]: defaultValues,
		},
		mode: "onChange",
	});

	const { fields, append, remove } = useFieldArray<{
		[FIELD_NAME]: BudgetElement[];
	}>({
		control,
		name: FIELD_NAME,
	});

	const expenses = watch(FIELD_NAME);
	const [options, setOptions] = useState(expenseOptions);
	const [selectedOptions, setSelectedOptions] = useState<SubCategories[]>([]);

	// saves all selected expense types in local state
	watch((data) => {
		const expenses = data[FIELD_NAME];
		if (selectedOptions.length !== expenses?.length) {
			const expenseTypes = expenses?.map(
				(expense) => expense?.subCategory
			) as SubCategories[];
			setSelectedOptions(expenseTypes);
		}
	});

	// uses selected expense types to filter the dropdown options
	useEffect(() => {
		const filteredOptions = expenseOptions.filter(
			(opt) => !selectedOptions.includes(opt.value)
		);
		setOptions(filteredOptions);
	}, [selectedOptions]);

	const { postIntent, postStatus } = usePostIntent();
	const { navigateNextPrompt } = useNavigateHelpers();
	const { getName } = useBudgetHelpers();

	const submitForm = (data: { [FIELD_NAME]: BudgetElement[] }) => {
		const formData = data[FIELD_NAME];
		if (isDirty) {
			const oldData = applicant_expenses;

			// any items that were deleted without being re-inserted need to be updated with amounts of 0
			oldData?.forEach((expense) => {
				if (expense.category === "Personal") {
					if (
						!formData.find((el) => {
							el.subCategory === expense.subCategory;
						})
					) {
						expense.amount = 0;
					}
				}
			});

			// any new items should match their names with any items with the same subcategory
			formData.forEach((expense) => {
				expense.name = getName(expense.subCategory) || uuidv4();
				expense.frequency = expense.frequency
					? (+expense.frequency as Frequencies)
					: undefined;
				expense.amount = expense.amount ? +expense.amount : undefined;
			});

			const map = new Map(
				[...(oldData || []), ...formData].map((pos) => [pos.name, pos])
			);

			const mappedElements = Array.from(map.values());

			postIntent({
				parameters: {
					applicant_expenses: mappedElements,
				},
				text: EXPENSES_PROVIDED_INTENT,
			}).then(() => {
				postStatus();
				navigateNextPrompt();
			});
		} else {
			postStatus();
			navigateNextPrompt();
		}
	};

	const reValidate = (field: any) => {
		if (isDirty) {
			trigger(field);
		}
	};

	return (
		<>
			<FormTitle className="text-center">
				{t("otherExpenses.formTitle")}
			</FormTitle>
			<Form onSubmit={handleSubmit(submitForm)} noValidate>
				<>
					{fields.map((field, index) => (
						<OtherExpenseForm
							control={control}
							name={`${FIELD_NAME}.${index}`}
							key={field.id}
							index={index}
							options={options}
							deleteExpense={remove}
							selectedExpenseType={
								expenseOptions.find(
									(opt) => opt.value === selectedOptions[index]
								)?.label
							}
							expenses={expenses}
							reValidate={reValidate}
						/>
					))}
					{fields.length && fields.length < expenseOptions.length ? (
						<Button
							variant="link"
							className="all-caps-link d-block ms-auto"
							onClick={() => append(initialValues)}
						>
							<FontAwesomeIcon icon="plus" className="d-inline-block me-2" />
							{t("otherExpenses.addAnotherExpense")}
						</Button>
					) : (
						""
					)}
				</>

				{!fields.length && (
					<div className="info-block text-center container-max-sm mx-auto">
						{t("otherExpenses.description")}
						<div className="mt-4">
							<Button
								variant="link"
								className="text-primary uppercase-smaller"
								onClick={() => append(initialValues)}
							>
								<FontAwesomeIcon icon="plus" className="d-inline-block me-2" />
								{t("otherExpenses.addAdditionalExpenses")}
							</Button>
						</div>
					</div>
				)}
				<FormNavButtons />
			</Form>
		</>
	);
}
