import { Form } from "react-bootstrap";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";

import InvalidFeedback from "../InvalidFeedback/InvalidFeedback";

interface GroupedSelectProps {
	label?: string;
	control: any;
	name: string;
	rules?: any;
	required?: boolean;
	disabled?: boolean;
	firstOption?: string;
	groupedOptions: {
		name: string;
		options: {
			value?: string | number;
			label?: string;
		}[];
	}[];
}

export default function GroupedSelect({
	label,
	control,
	name,
	rules,
	required,
	disabled,
	firstOption,
	groupedOptions,
}: GroupedSelectProps) {
	const { t } = useTranslation("global");
	const {
		field,
		fieldState: { error },
	} = useController({ name, control, rules });

	return (
		<Form.Group className="w-100">
			{label ? (
				<Form.Label htmlFor={name}>
					{label} {required && <span className="text-danger">*</span>}
				</Form.Label>
			) : (
				""
			)}

			<Form.Select
				{...field}
				id={name}
				isInvalid={!!error}
				data-testid="select"
				disabled={disabled}
			>
				<option value="">{firstOption || t("select")}</option>
				{groupedOptions.map(
					(group) =>
						group.options.length && (
							<optgroup label={group.name} key={group.name}>
								{group.options.map((option) => (
									<option
										value={option.value}
										key={option.value}
										data-testid="select-option"
									>
										{option.label}
									</option>
								))}
							</optgroup>
						)
				)}
			</Form.Select>
			<InvalidFeedback>{error?.message}</InvalidFeedback>
		</Form.Group>
	);
}
