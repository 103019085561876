import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import FormNavButtons from "../../../Common/FormNavButtons/FormNavButtons";
import FormTitle from "../../../Common/FormTitle/FormTitle";
import RadioButtons from "../../../Common/RadioButtons/RadioButtons";
import type {
	PaymentStatusOptions,
	PaymentStatusParams,
} from "../../../../utils/Types";
import useIntentHelpers from "../../../../utils/usePostIntent/usePostIntent";
import useNavigateHelpers from "../../../../utils/useNavigateHelpers/useNavigateHelpers";
import { PAYMENT_STATUS_PROVIDED_INTENT } from "../../../../utils/intents";
import useSessionData from "../../../../utils/useSessionData/useSessionData";

export function options(): { value: PaymentStatusOptions; label: string }[] {
	const { t } = useTranslation("overview");

	return [
		{
			value: "Current",
			label: t("paymentStatus.options.current"),
		},
		{
			value: "Behind",
			label: t("paymentStatus.options.fallingBehind"),
		},
		{
			value: "Collections",
			label: t("paymentStatus.options.veryBehind"),
		},
	];
}

export default function PaymentStatus() {
	const { t } = useTranslation("overview");
	const { payment_status } = useSessionData();

	const { handleSubmit, control, setValue, watch } =
		useForm<PaymentStatusParams>({
			defaultValues: {
				payment_status,
			},
		});

	const { postIntent, postStatus } = useIntentHelpers();
	const { navigateNextPrompt } = useNavigateHelpers();

	const submitForm = (data: PaymentStatusParams) => {
		const isDirty = payment_status !== data.payment_status;

		if (isDirty) {
			postIntent({
				parameters: data,
				text: PAYMENT_STATUS_PROVIDED_INTENT,
			}).then(() => navigateNextPrompt());
		} else {
			postStatus();
			navigateNextPrompt();
		}
	};

	const checked = watch("payment_status");
	const isComplete = !!checked;

	return (
		<>
			<FormTitle
				className="text-center"
				instructions={t("paymentStatus.formTitle.instructions")}
			>
				{t("paymentStatus.formTitle.text")}
			</FormTitle>
			<Form onSubmit={handleSubmit(submitForm)}>
				<Form.Group>
					<RadioButtons
						options={options()}
						name="payment_status"
						control={control}
						rules={{
							required: t("paymentStatus.rules.required"),
						}}
						setValue={setValue}
						checked={checked}
					/>
				</Form.Group>
				<FormNavButtons nextLinkDisabled={!isComplete} />
			</Form>
		</>
	);
}
