import {
	useEffect,
	useState,
	createContext,
	ReactNode,
	useContext,
	useRef,
} from "react";
import usePostIntent from "../../utils/usePostIntent/usePostIntent";
import { useStatus } from "./StatusProvider";
import { useSession } from "./SessionProvider";
import useSessionData from "../../utils/useSessionData/useSessionData";

interface Values {
	resetTimer: () => void;
	isFormComplete: boolean;
	setIsFormComplete: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SessionTimeoutContext = createContext<Values>({
	resetTimer: () => undefined,
	isFormComplete: false,
	setIsFormComplete: () => undefined,
});

export default function SessionTimeoutProvider({
	children,
}: {
	children: ReactNode;
}) {
	const { postStatus } = usePostIntent();
	const [isFormComplete, setIsFormComplete] = useState(false);
	const timerRef = useRef<NodeJS.Timeout>();
	const { isAbandoned, setIsAbandoned } = useStatus();
	const { initializing, session } = useSession();
	const formStatus = session.applicant_form_status;
	const { assistance_reason } = useSessionData();

	// determine if status is Pending
	// using ref to prevent data from getting lost during unload window event
	const isFormStatusActiveRef = useRef(false);

	useEffect(() => {
		if (!initializing && formStatus) {
			if (
				formStatus === "Pending" ||
				formStatus === "Abandoned" ||
				formStatus === "Locked" ||
				isAbandoned ||
				!assistance_reason
			) {
				isFormStatusActiveRef.current = false;
			} else {
				isFormStatusActiveRef.current = true;
			}
		}
	}, [formStatus, initializing, isAbandoned, assistance_reason]);

	// session timer
	const timeToWait =
		+(process.env.REACT_APP_IDLE_TIMEOUT_MINUTES || 60) * 60 * 1000;

	const resetTimer = () => {
		clearTimeout(timerRef.current);
		timerRef.current = setTimeout(() => {
			if (isFormStatusActiveRef.current) {
				setIsAbandoned(true);
				postStatus({ setToAbandoned: true }); // send "Abandoned" form status
			}
			clearTimeout(timerRef.current);
		}, timeToWait);
	};

	useEffect(() => {
		if (!initializing) resetTimer();
	}, [isFormStatusActiveRef.current, initializing]);

	const handleUnload = () => {
		if (!isFormComplete && isFormStatusActiveRef.current) {
			// Check if form is not completed
			postStatus({ setToAbandoned: true, beacon: true });
		}
	};

	useEffect(() => {
		if (!initializing) {
			window.addEventListener("unload", handleUnload);
			return () => {
				window.removeEventListener("unload", handleUnload);
			};
		}
	}, [isFormComplete, isFormStatusActiveRef.current, initializing]);

	const values = {
		isAbandoned,
		setIsAbandoned,
		resetTimer,
		isFormComplete,
		setIsFormComplete,
	};

	return (
		<SessionTimeoutContext.Provider value={values}>
			{children}
		</SessionTimeoutContext.Provider>
	);
}

export const useSessionTimeout = () => useContext(SessionTimeoutContext);
