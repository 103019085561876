import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import useIntentHelpers from "../../../../utils/usePostIntent/usePostIntent";
import useNavigateHelpers from "../../../../utils/useNavigateHelpers/useNavigateHelpers";
import TextArea from "../../../Common/TextArea/TextArea";
import FormTitle from "../../../Common/FormTitle/FormTitle";
import FormNavButtons from "../../../Common/FormNavButtons/FormNavButtons";
import type { AssistanceReasonParams } from "../../../../utils/Types";
import { useTranslation } from "react-i18next";
import { ASSISTANCE_REASON_PROVIDED_INTENT } from "../../../../utils/intents";
import useSessionData from "../../../../utils/useSessionData/useSessionData";
import Select from "../../../Common/Select/Select";

export default function AssistanceReason() {
	const { t } = useTranslation("overview", { keyPrefix: "hardship" });
	const { assistance_reason, assistance_reason_options } = useSessionData();

	const {
		handleSubmit,
		control,
		watch,
		formState: { isDirty },
	} = useForm<AssistanceReasonParams>({
		defaultValues: {
			assistance_reason,
			assistance_reason_options,
		},
	});

	const [hasReason, hasOptions] = watch([
		"assistance_reason",
		"assistance_reason_options",
	]);
	const isComplete = hasReason && hasOptions;

	const options: { label: string; value: string }[] = t(
		"assistanceReasonOptions.options",
		{ returnObjects: true }
	);

	function capitalizeFirstWord(sentence: string) {
		return sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();
	}

	for (let i = 0; i < options.length; i++) {
		options[i].label = capitalizeFirstWord(options[i].label);
	}

	const { postIntent, postStatus } = useIntentHelpers();
	const { navigateNextPrompt } = useNavigateHelpers();

	const submitForm = (data: AssistanceReasonParams) => {
		if (isDirty) {
			postIntent({
				parameters: data,
				text: ASSISTANCE_REASON_PROVIDED_INTENT,
			}).then(() => navigateNextPrompt());
		} else {
			postStatus();
			navigateNextPrompt();
		}
	};

	return (
		<>
			<FormTitle className="text-center">{t("formTitle")}</FormTitle>
			<Form onSubmit={handleSubmit(submitForm)}>
				<div className="container-max-sm mx-auto">
					<Form.Group className="mb-4 field-md">
						<Select
							label={t("assistanceReasonOptions.label")}
							options={options}
							control={control}
							name={"assistance_reason_options"}
							required
							rules={{
								required: t("assistanceReasonOptions.rules.required"),
							}}
						/>
					</Form.Group>

					<Form.Group>
						<TextArea
							name="assistance_reason"
							label={t("assistanceReason.label")}
							required
							control={control}
							rules={{
								maxLength: {
									value: 500,
									message: t("assistanceReason.rules.maxLength"),
								},
								required: t("assistanceReason.rules.required"),
							}}
						/>
					</Form.Group>
				</div>
				<FormNavButtons nextLinkDisabled={!isComplete} />
			</Form>
		</>
	);
}
