import FormTitle from "../../../Common/FormTitle/FormTitle";
import { Row, Col } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { BudgetSummarySidebar } from "../../../Layout";
import CallbackForm from "../Callback/CallbackForm";

export default function BudgetSummary() {
	const { t } = useTranslation("budgetSummary");

	return (
		<Row>
			<Col xs={12} lg={7}>
				<FormTitle className="mt-0">{t("budgetSummary.formTitle")}</FormTitle>

				<div className="mb-5">
					<Trans t={t} i81nKey="budgetSummary.description">
						budgetSummary.description
					</Trans>

					<div className="d-block d-lg-none">
						<BudgetSummarySidebar />
					</div>
				</div>

				<p className="text-navy-blue italic text-small">
					{t("callback.title")}
				</p>
				<p>{t("callback.description")}</p>
				<div className="mx-auto mt-4">
					<CallbackForm />
				</div>
			</Col>
			<Col xs={12} lg={5} className="d-none d-lg-block">
				<BudgetSummarySidebar />
			</Col>
		</Row>
	);
}
