import { Row, Col, Button, Spinner, ButtonProps } from "react-bootstrap";
import useNavigateHelpers from "../../../utils/useNavigateHelpers/useNavigateHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useSession } from "../../Context/SessionProvider";

interface FormNavButtonsProps {
	hasBackLink?: boolean;
	hasNextLink?: boolean;
	nextLabel?: string;
	label?: string;
	nextLinkDisabled?: boolean;
	disabled?: boolean;
	onClick?: () => void;
	className?: string;
	type?: ButtonProps["type"];
}

export function SubmitButton({
	nextLinkDisabled = false,
	label = "",
	disabled = false,
	type = "submit",
	...props
}: FormNavButtonsProps) {
	const { loading } = useSession();
	const { t } = useTranslation("global");

	if (label === "") {
		label = t("continue");
	}

	return (
		<Button
			variant="primary"
			type={type}
			disabled={nextLinkDisabled || loading || disabled}
			{...props}
		>
			{loading ? (
				<>
					<Spinner
						as="span"
						animation="border"
						size="sm"
						role="status"
						aria-hidden="true"
					/>{" "}
					{t("loading")}
				</>
			) : (
				<>
					{label}{" "}
					<FontAwesomeIcon icon="arrow-right" className="d-inline-block ms-2" />
				</>
			)}
		</Button>
	);
}

export function BackButton({ disabled = false, onClick }: FormNavButtonsProps) {
	const { loading } = useSession();
	const { t } = useTranslation("global");
	const { navigatePrevPrompt } = useNavigateHelpers();

	return (
		<Button
			variant="light"
			type="button"
			size="sm"
			className="back-btn"
			onClick={onClick || navigatePrevPrompt}
			disabled={loading || disabled}
		>
			<FontAwesomeIcon icon="arrow-left" className="d-inline-block me-2" />
			{t("back")}
		</Button>
	);
}

export default function FormNavButtons({
	hasBackLink = true,
	hasNextLink = true,
	nextLabel = "",
	nextLinkDisabled = false,
	className = "",
}: FormNavButtonsProps) {
	return (
		<Row className={`form-nav-buttons align-items-center ${className}`}>
			<Col className="text-start">{hasBackLink && <BackButton />}</Col>
			<Col className="text-end">
				{hasNextLink && (
					<SubmitButton label={nextLabel} nextLinkDisabled={nextLinkDisabled} />
				)}
			</Col>
		</Row>
	);
}
