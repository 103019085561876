import { getCountry } from "../../../utils/getCountry";
import usLogo from "../../../assets/virtual-counselor.svg";
import caLogo from "../../../assets/virtual-counselor-ca.svg";

export const Logo = () => {
	const country = getCountry();

	return (
		<img
			src={country === "CA" ? caLogo : usLogo}
			alt="logo"
			className="img-fluid logo p-1 text-center"
		/>
	);
};
