import { GlobalProvider } from "./components/Context/GlobalProvider";
import Content from "./components/Layout/Content/Content";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLibrary } from "./faLibrary";

import "./global.scss";
import "./i18n/config";
library.add(...faLibrary);

function App() {
	return (
		<GlobalProvider>
			<Content />
		</GlobalProvider>
	);
}

export default App;
