import { BrowserRouter as Router } from "react-router-dom";
import { ReactNode } from "react";
import { AxiosInterceptor } from "../../utils/axiosConfig";
import ChatbotProvider from "./ChatbotProvider";
import SessionProvider from "./SessionProvider";
import SessionTimeoutProvider from "./SessionTimeoutContext";
import StatusProvider from "./StatusProvider";
import { UrlParamsProvider } from "./UrlParamProvider";

export const GlobalProvider = ({ children }: { children: ReactNode }) => {
	return (
		<Router>
			<UrlParamsProvider>
				<SessionProvider>
					<StatusProvider>
						<SessionTimeoutProvider>
							<AxiosInterceptor>
								<ChatbotProvider>{children}</ChatbotProvider>
							</AxiosInterceptor>
						</SessionTimeoutProvider>
					</StatusProvider>
				</SessionProvider>
			</UrlParamsProvider>
		</Router>
	);
};
