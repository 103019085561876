import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { EXPENSES_PROVIDED_INTENT } from "../../../../utils/intents";
import type {
	FoodParams,
	Frequencies,
	FrequencyOptions,
} from "../../../../utils/Types";
import useBudgetHelpers from "../../../../utils/useBudgetHelpers/useBudgetHelpers";
import useNavigateHelpers from "../../../../utils/useNavigateHelpers/useNavigateHelpers";
import usePostIntent from "../../../../utils/usePostIntent/usePostIntent";
import ButtonGroup from "../../../Common/ButtonGroup/ButtonGroup";
import FormNavButtons from "../../../Common/FormNavButtons/FormNavButtons";
import FormTitle from "../../../Common/FormTitle/FormTitle";
import TextFieldGroup from "../../../Common/TextFieldGroup/TextFieldGroup";

import {
	MaxAmount,
	maxAmountMonthly,
	maxAmountWeekly,
} from "../../../../utils/maxAmounts";
import { CURRENCY_REGEX } from "../../../../utils/currencyUtils";

interface FoodFrequencies {
	GroceryFrequency: Frequencies;
	RestaurantsFrequency: Frequencies;
}

export function getFrequencyOptions(): FrequencyOptions[] {
	const { t: global } = useTranslation("global");

	return [
		{
			label: global("frequencyOptions.weekly"),
			value: 52,
		},
		{
			label: global("frequencyOptions.monthly"),
			value: 12,
		},
	];
}

export function Food() {
	const { t: global } = useTranslation("global");
	const { t } = useTranslation("expenses");
	const { postIntent, postStatus } = usePostIntent();
	const { getBudgetElement, formatBudgetElement, addBudgetElements } =
		useBudgetHelpers();

	const [groceryMaxAmount, setGroceryMaxAmount] =
		useState<MaxAmount>(maxAmountMonthly);
	const [restaurantsMaxAmount, setRestaurantsMaxAmount] =
		useState<MaxAmount>(maxAmountMonthly);

	const grocery = getBudgetElement("Grocery");
	const restaurants = getBudgetElement("Lunches/Eating Out");

	const {
		handleSubmit,
		control,
		formState,
		formState: { isDirty },
		trigger,
		watch,
	} = useForm<FoodParams & FoodFrequencies>({
		defaultValues: {
			Grocery: grocery?.amount ? `${grocery?.amount}` : "",
			GroceryFrequency: grocery?.frequency || 52,
			["Lunches/Eating Out"]: restaurants?.amount
				? `${restaurants?.amount}`
				: "",
			RestaurantsFrequency: restaurants?.frequency || 52,
		},
	});

	const formDataGroceryFreq = watch("GroceryFrequency");
	const formDataRestaurantsFreq = watch("RestaurantsFrequency");

	useEffect(() => {
		if (formDataGroceryFreq === 52 && groceryMaxAmount.amount !== 2300) {
			setGroceryMaxAmount(maxAmountWeekly);
		}

		if (formDataGroceryFreq === 12 && groceryMaxAmount.amount !== 10000) {
			setGroceryMaxAmount(maxAmountMonthly);
		}

		if (
			formDataRestaurantsFreq === 52 &&
			restaurantsMaxAmount.amount !== 2300
		) {
			setRestaurantsMaxAmount(maxAmountWeekly);
		}

		if (
			formDataRestaurantsFreq === 12 &&
			restaurantsMaxAmount.amount !== 10000
		) {
			setRestaurantsMaxAmount(maxAmountMonthly);
		}
	}, [
		formState,
		formDataGroceryFreq,
		formDataRestaurantsFreq,
		groceryMaxAmount,
		restaurantsMaxAmount,
	]);

	useEffect(() => {
		trigger();
	}, [groceryMaxAmount, restaurantsMaxAmount]);

	const { navigateNextPrompt } = useNavigateHelpers();

	const submitForm = (data: FoodParams & FoodFrequencies) => {
		if (isDirty) {
			const CATEGORY = "Food";
			const grocery = formatBudgetElement({
				subCategory: "Grocery",
				category: CATEGORY,
				frequency: data.GroceryFrequency,
				amount: data.Grocery,
			});

			const restaurants = formatBudgetElement({
				subCategory: "Lunches/Eating Out",
				category: CATEGORY,
				frequency: data.RestaurantsFrequency,
				amount: data["Lunches/Eating Out"],
			});

			const foodExpenses = [grocery, restaurants];

			postIntent({
				parameters: {
					applicant_expenses: addBudgetElements(foodExpenses),
				},
				text: EXPENSES_PROVIDED_INTENT,
			}).then(() => {
				postStatus();
				navigateNextPrompt();
			});
		} else {
			postStatus();
			navigateNextPrompt();
		}
	};

	return (
		<>
			<FormTitle faIcon={["fas", "burger-fries"]}>
				{t("food.formTitle")}
			</FormTitle>
			<Form onSubmit={handleSubmit(submitForm)} noValidate>
				<Row className="mt-3 d-flex flex-row">
					<Col xs={12} md={5} lg={3}>
						<TextFieldGroup
							label={t("food.grocery.label")}
							rules={{
								pattern: {
									value: CURRENCY_REGEX,
									message: global("rules.dollarAmount"),
								},
								max: {
									value: groceryMaxAmount.amount,
									message: global(groceryMaxAmount.message, {
										amount: groceryMaxAmount.amount,
									}),
								},
								min: {
									value: 0,
									message: global("rules.min"),
								},
							}}
							name="Grocery"
							prefix="$"
							className="field-xs"
							type="number"
							control={control}
						/>
					</Col>
					<Col xs={12} md={7} lg={9}>
						<ButtonGroup
							label={t("food.frequency.label")}
							options={getFrequencyOptions()}
							control={control}
							name={"GroceryFrequency"}
							className="field-md"
						/>
					</Col>
				</Row>
				<Row className="mt-3">
					<Col xs={12} md={5} lg={3}>
						<TextFieldGroup
							label={t("food.restaurants.label")}
							rules={{
								pattern: {
									value: CURRENCY_REGEX,
									message: global("rules.dollarAmount"),
								},
								max: {
									value: restaurantsMaxAmount.amount,
									message: global(restaurantsMaxAmount.message, {
										amount: restaurantsMaxAmount.amount,
									}),
								},
								min: {
									value: 0,
									message: global("rules.min"),
								},
							}}
							name="Lunches/Eating Out"
							prefix="$"
							className="field-xs"
							type="number"
							control={control}
						/>
					</Col>
					<Col xs={12} md={7} lg={9}>
						<ButtonGroup
							label={t("food.frequency.label")}
							options={getFrequencyOptions()}
							control={control}
							name={"RestaurantsFrequency"}
							className="field-md"
						/>
					</Col>
				</Row>
				<FormNavButtons />
			</Form>
		</>
	);
}
