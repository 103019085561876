import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
	HAS_INCOME_PROVIDED_INTENT,
	INCOME_PROVIDED_INTENT,
} from "../../../../utils/intents";
import type { BudgetElement, HasIncomeParams } from "../../../../utils/Types";
import useNavigateHelpers from "../../../../utils/useNavigateHelpers/useNavigateHelpers";
import useIntentHelpers from "../../../../utils/usePostIntent/usePostIntent";
import FormNavButtons from "../../../Common/FormNavButtons/FormNavButtons";
import FormTitle from "../../../Common/FormTitle/FormTitle";
import RadioButtons from "../../../Common/RadioButtons/RadioButtons";
import useSessionData from "../../../../utils/useSessionData/useSessionData";

interface HasIncomeForm {
	has_income: "yes" | "no"; // have to use string as value for radio buttons to work properly
}

export function options(): {
	value: HasIncomeForm["has_income"];
	label: string;
}[] {
	const { t } = useTranslation("overview");
	return [
		{
			value: "yes",
			label: t("hasIncome.options.yes"),
		},
		{
			value: "no",
			label: t("hasIncome.options.no"),
		},
	];
}

export default function HasIncome() {
	const { t } = useTranslation("overview");
	const { has_income, applicant_income } = useSessionData();

	const { handleSubmit, watch, control, setValue } = useForm<HasIncomeForm>({
		defaultValues: {
			has_income: has_income === false ? "no" : "yes",
		},
	});

	const { postIntent, postStatus } = useIntentHelpers();
	const { navigateNextPrompt } = useNavigateHelpers();

	const submitForm = async (data: {
		has_income: HasIncomeParams["has_income"] | HasIncomeForm["has_income"];
		applicant_income?: BudgetElement[];
	}) => {
		if (data.has_income === "yes") {
			data.has_income = true;
		} else {
			data.has_income = false;
		}
		const isDirty = has_income !== data.has_income;

		if (isDirty) {
			const promises = [
				postIntent({ parameters: data, text: HAS_INCOME_PROVIDED_INTENT }),
			];

			if (!data.has_income) {
				if (applicant_income?.length) {
					// if applicant_income data already exists, set amount to 0 to "delete" it
					data.applicant_income = applicant_income.map((income) => {
						return {
							...income,
							amount: 0,
						};
					});
				} else {
					// otherwise, set to empty array
					data.applicant_income = [];
				}

				promises.push(
					postIntent({ parameters: data, text: INCOME_PROVIDED_INTENT })
				);
			}

			await Promise.all(promises);
			navigateNextPrompt();
		} else {
			postStatus();
			navigateNextPrompt();
		}
	};

	const checked = watch("has_income");

	return (
		<>
			<FormTitle
				className="text-center"
				instructions={t("hasIncome.formTitle.instructions")}
			>
				{t("hasIncome.formTitle.text")}
			</FormTitle>
			<Form onSubmit={handleSubmit(submitForm)}>
				<div className="d-flex justify-content-center">
					<RadioButtons
						options={options()}
						name="has_income"
						control={control}
						checked={checked}
						setValue={setValue}
						rules={{
							required: t("hasIncome.rules.required"),
						}}
					/>
				</div>
				<FormNavButtons />
			</Form>
		</>
	);
}
