import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface FormTitleProps {
	className?: string;
	faIcon?: IconProp;
	children: JSX.Element | string;
	label?: string;
	instructions?: string;
}
export default function FormTitle({
	className,
	faIcon,
	children,
	label,
	instructions,
}: FormTitleProps) {
	return (
		<div className={`my-4 ${className}`}>
			<h1 className="h1 page-title">
				{faIcon && (
					<FontAwesomeIcon
						icon={faIcon}
						size="lg"
						className="icon d-inline-block me-2"
					/>
				)}{" "}
				{label ? <label htmlFor={label}>{children}</label> : children}{" "}
			</h1>
			{instructions && (
				<div className="text-muted small">
					<span className="text-danger">*</span> {instructions}
				</div>
			)}
		</div>
	);
}
