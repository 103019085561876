import { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import ButtonGroup from "../../../Common/ButtonGroup/ButtonGroup";
import Select from "../../../Common/Select/Select";
import TextFieldGroup from "../../../Common/TextFieldGroup/TextFieldGroup";
import { getFrequencyOptions } from "./IncomeSources";
import { MaxAmount } from "../../../../utils/maxAmounts";
import { CURRENCY_REGEX } from "../../../../utils/currencyUtils";

const maxAmountMonthly: MaxAmount = {
	amount: 100000,
	message: "rules.maxMonthly",
};

const maxAmountTwiceAMonth: MaxAmount = {
	amount: 50000,
	message: "rules.maxTwiceAMonth",
};

const maxAmountBiweekly: MaxAmount = {
	amount: 46150,
	message: "rules.maxBiweekly",
};

const maxAmountWeekly: MaxAmount = {
	amount: 23070,
	message: "rules.maxWeekly",
};

function IncomeForm({
	control,
	name,
	index,
	options,
	deleteItem,
	selectedSourceType,
	income,
	reValidate,
}: any) {
	const { t: global } = useTranslation("global");
	const { t } = useTranslation("income");
	const [maxAmount, setMaxAmount] = useState<MaxAmount>(maxAmountMonthly);

	const frequency = income[index].frequency;
	const amountEntered = income[index].amount;

	useEffect(() => {
		if (+frequency === 52 && maxAmount.amount !== 23070) {
			setMaxAmount(maxAmountWeekly);
		}

		if (+frequency === 26 && maxAmount.amount !== 46150) {
			setMaxAmount(maxAmountBiweekly);
		}

		if (+frequency === 24 && maxAmount.amount !== 50000) {
			setMaxAmount(maxAmountTwiceAMonth);
		}

		if (+frequency === 12 && maxAmount.amount !== 100000) {
			setMaxAmount(maxAmountMonthly);
		}
	}, [frequency, maxAmount]);

	useEffect(() => {
		if (amountEntered) {
			reValidate(`${name}.amount`);
		}
	}, [maxAmount]);

	if (control) {
		return (
			<Card className="p-3 mb-4 form-group">
				<Row>
					<div className="form-group-label">
						{t("incomeForm.incomeSource")} #{index + 1}
					</div>
					<Col>
						{index > 0 && (
							<Row>
								<Col className="text-end">
									<Button
										variant="link"
										className="all-caps-link ms-auto"
										onClick={() => deleteItem(index)}
									>
										<FontAwesomeIcon
											icon="xmark"
											className="d-inline-block me-2"
										/>
										{global("remove")}
									</Button>
								</Col>
							</Row>
						)}
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={6} xl={3}>
						<Form.Group className="h-100 d-flex align-items-end align-items-lg-start mb-3">
							<Select
								firstOption={selectedSourceType || global("select")}
								label={t("incomeForm.sourceType.label")}
								options={options}
								control={control}
								name={`${name}.subCategory`}
								rules={{
									required: t("incomeForm.sourceType.rules.required"),
								}}
								required
							/>
						</Form.Group>
					</Col>
					<Col xs={12} lg={6} xl={3}>
						<TextFieldGroup
							rules={{
								pattern: {
									value: CURRENCY_REGEX,
									message: global("rules.dollarAmount"),
								},
								max: {
									value: maxAmount.amount,
									message: global(maxAmount.message, {
										amount: maxAmount.amount,
									}),
								},
								min: {
									value: 0,
									message: global("rules.min"),
								},
								required: t("incomeForm.amount.rules.required"),
							}}
							label={t("incomeForm.amount.label")}
							control={control}
							prefix="$"
							name={`${name}.amount`}
							type="number"
							step="any"
							required
						/>
					</Col>
					<Col>
						<ButtonGroup
							label={t("incomeForm.frequency.label")}
							options={getFrequencyOptions()}
							control={control}
							name={`${name}.frequency`}
							rules={{
								required: t("incomeForm.frequency.rules.required"),
							}}
							required
						/>
					</Col>
				</Row>
			</Card>
		);
	} else {
		return null;
	}
}

export default IncomeForm;
